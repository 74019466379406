<template>
  <div>
    <b-card
      no-body
      class="card-statistics"
    >
      <b-card-body class="statistics-body">
        <payment-remittance-view-header :payment-remittance="paymentRemittance" />

        <b-table
          ref="refUserListTable"
          class="payment-remittance-view mt-1"
          :items="fetchPaymentRemittance"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Nenhum registro correspondente encontrado"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(actions)="{item}">
            <feather-icon
              icon="EditIcon"
              size="16"
              class="align-middle text-body"
              @click="editItem(item)"
            />
          </template>
          <template
            #cell(signed_at)="{ item }"
            class="w-100"
          >
            {{ formatDateTimeDDMMYYHHMM(item.signed_at) }}
          </template>
          <template
            #cell(contract_code)="{ item }"
            class="w-100"
          >
            {{ item.contract_code }}
            <b-badge
              v-if="item.tag_test !== ''"
              pill
              class="text-capitalize"
              variant="warning"
            >
              {{ item.tag_test }}
            </b-badge>
            <b-badge
              pill
              :variant="item.contract_person_type === 'PF' ? 'light-success' : 'light-danger'"
            >
              {{ item.contract_person_type }}
            </b-badge>
          </template>
          <template
            #cell(company)="{ item }"
            class="w-100"
          >
            <div
              v-if="item.company_name"
              class="account-info"
            >
              <div :title="item.company_name">
                {{ truncateString(item.company_name, 35) }}
              </div>
              <div><strong>CNPJ:</strong> {{ formatCnpj(item.company_cnpj) }}</div>
            </div>
          </template>
          <template
            #cell(cpf)="{ item }"
            class="w-100"
          >
            {{ maskHiddenCpf(item.cpf) }}
          </template>
          <template
            #cell(status_title)="{ item }"
            class="w-100"
          >
            <b-badge
              pill
              :variant="`light-${resolveStatusVariant(item.status)}`"
              class="text-capitalize"
            >
              {{ item.status_title }}
            </b-badge>
          </template>
          <template
            #cell(account_number_and_code)="{ item }"
            class="w-100"
          >
            {{ item.account_number }}-{{ item.account_code }}
          </template>
          <template
            #cell(account_type)="{ item }"
            class="w-100"
          >
            {{ resolveAccountType(item.account_type) }}
          </template>
          <template
            #cell(deadline)="{ item }"
            class="w-100"
          >
            <b-badge
              pill
              class="text-capitalize"
              :variant="`${resolveDeadlineVariant(item.deadline_level)}`"
            >
              {{ item.deadline }}
            </b-badge>
          </template>
          <template
            #cell(start_date)="{ item }"
            class="w-100"
          >
            {{ formatDateTimeDDMMYYHHMM(item.start_date) }} -
            {{ formatDateTimeDDMMYYHHMM(item.end_date) }}
          </template>
          <template
            #cell(amount_physician_duty)="{ item }"
            class="w-100"
          >
            {{ formatCurrency(item.amount_physician_duty) }}
          </template>
          <template
            #cell(rate_value)="{ item }"
            class="w-100"
          >
            {{ formatCurrency(item.rate_value) }}
          </template>
          <template
            #cell(amount_with_discount)="{ item }"
            class="w-100"
          >
            {{ formatCurrency(item.amount_with_discount) }}
          </template>
        </b-table>
      </b-card-body>
    </b-card>

    <!--    -->
    <b-modal
      id="modal-paymentRemittance"
      ref="paymentRemittanceModal"
      title="Autorização de Pagamento"
      size="lg"
      hide-footer
    >
      <div
        v-if="paymentRemittanceItem"
      >
        <div class="d-flex flex-wrap justify-content-start general">
          <div>
            <div class="mb-0">
              Data e Hora da Solicitação
            </div>
            <h6 class="mt-1">
              {{ formatDateTimeDDMMYYHHMM(paymentRemittanceItem.signed_at) }}
            </h6>
          </div>
          <div>
            <div class="text-capitalize mb-0">
              Contrato
            </div>
            <h6 class="mt-1">
              {{ paymentRemittanceItem.contract_code }}
              <b-badge
                v-if="paymentRemittanceItem.tag_test !== ''"
                pill
                class="text-capitalize"
                variant="warning"
              >
                {{ paymentRemittanceItem.tag_test }}
              </b-badge>
            </h6>
          </div>
          <div>
            <div class="text-capitalize mb-0">
              Nome
            </div>
            <h6 class="mt-1">
              {{ paymentRemittanceItem.name }}
            </h6>
          </div>
          <div>
            <div class="text-capitalize mb-0">
              CPF
            </div>
            <div class="mt-1">
              {{ maskHiddenCpf(paymentRemittanceItem.cpf) }}
            </div>
          </div>
          <div>
            <h6 class="text-capitalize mb-0">
              STATUS
            </h6>
            <div class="mt-1">
              <b-badge
                pill
                :variant="`light-${resolveStatusVariant(paymentRemittanceItem.status)}`"
                class="text-capitalize"
              >
                {{ paymentRemittanceItem.status_title }}
              </b-badge>
            </div>
          </div>
          <div>
            <h6 class="text-capitalize mb-0">
              PRAZO PARA PAGAMENTO
            </h6>
            <div class="mt-1">
              <b-badge
                pill
                class="text-capitalize"
                :variant="`${resolveDeadlineVariant(paymentRemittanceItem.deadline_level)}`"
              >
                {{ paymentRemittanceItem.deadline }}
              </b-badge>
            </div>
          </div>

          <div>
            <div class="text-capitalize mb-0">
              Serviço
            </div>
            <div class="mt-1">
              {{ paymentRemittanceItem.workplace_duty_type }}
            </div>
          </div>
          <div>
            <div class="text-capitalize mb-0">
              Data Plantão
            </div>
            <h6 class="mt-1">
              {{ formatDateTimeDDMMYYHHMM(paymentRemittanceItem.start_date) }} -
              {{ formatDateTimeDDMMYYHHMM(paymentRemittanceItem.end_date) }}
            </h6>
          </div>
          <div>
            <div class="text-capitalize mb-0">
              Instituição
            </div>
            <div class="mt-1">
              {{ paymentRemittanceItem.institution }}
            </div>
          </div>
          <div>
            <div class="text-capitalize mb-0">
              Local
            </div>
            <div class="mt-1">
              {{ paymentRemittanceItem.workplace }}
            </div>
          </div>
          <div>
            <div class="text-capitalize mb-0">
              Valor do serviço
            </div>
            <div class="mt-1">
              {{ formatCurrency(paymentRemittanceItem.amount_physician_duty) }}
            </div>
          </div>
          <div>
            <div class="text-capitalize mb-0">
              Taxa
            </div>
            <div class="mt-1">
              {{ formatCurrency(paymentRemittanceItem.rate_value) }}
            </div>
          </div>
        </div>

        <h4 class="text-capitalize mt-3 mb-0 font-weight-normal font-medium-2">
          Autorização de Pagamento
        </h4>

        <div>
          <div class="mt-1">
            Valor do pagamento
          </div>
          <h6 class="mt-0">
            {{ formatCurrency(paymentRemittanceItem.amount_with_discount) }}
          </h6>
          <div class="d-flex flex-wrap justify-content-start authorization-form">
            <div>
              <b-form-group
                label="Banco"
                label-for="bank_name"
              >
                <b-form-input
                  id="bank_name"
                  v-model="paymentRemittanceItemData.bank_name"
                  name="bank_name"
                  placeholder="Banco"
                  disabled
                />
              </b-form-group>
            </div>
            <div>
              <b-form-group
                label="Tipo Conta"
                label-for="account_type"
              >
                <v-select
                  v-model="paymentRemittanceItemData.account_type"
                  :options="accountTypeOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="account-type"
                  disabled
                />
              </b-form-group>
            </div>
            <div>
              <b-form-group
                label="Agência"
                label-for="agency"
              >
                <b-form-input
                  id="agency"
                  v-model="paymentRemittanceItemData.branch_number"
                  name="agency"
                  placeholder="Agencia"
                  disabled
                />
              </b-form-group>
            </div>
            <div>
              <b-form-group
                label="Conta"
                label-for="agency"
              >
                <b-form-input
                  id="account"
                  v-model="paymentRemittanceItemData.account_number_and_code"
                  name="account"
                  placeholder="Conta"
                  disabled
                />
              </b-form-group>
            </div>
          </div>
        </div>
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label-for="operation_code"
            >
              <label>
                Insira o <strong>código da operação</strong>
              </label>
              <b-form-input
                id="operation_code"
                v-model="paymentRemittanceItemData.operation_code"
                name="operation_code"
                placeholder="000000"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              class="-mr-1"
              label="Data de pagamento"
              label-for="payment-date"
            >
              <flat-pickr
                v-model="paymentRemittanceItemData.payment_date"
                class="form-control"
                :config="configDate"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-button
              class="ml-1 mt-1"
              type="submit"
              variant="primary"
              @click="saveItem()"
            >
              Atualizar
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store'
import {
  ref, onUnmounted,
} from '@vue/composition-api'
import {
  BCard, BCardBody, BButton, BTable, BBadge, BModal, BFormGroup, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import {
  formatCnpj, formatCurrency, formatDateTimeDDMMYYHHMM, maskHiddenCpf, truncateString,
} from '@core/utils/utils'
import {
  resolveDeadlineVariant,
  resolveStatusVariant,
} from '@/views/financial/payment-remittance-list/usePaymentRemittanceList'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { format } from 'date-fns'
import contractStoreModule from '../contractStoreModule'
import PaymentRemittanceViewHeader from './PaymentRemittanceViewHeader.vue'
import usePaymentRemittanceView from './usePaymentRemittanceView'

export default {
  components: {
    BCard,
    BFormInput,
    BFormGroup,
    BCardBody,
    BButton,
    BRow,
    BCol,
    BTable,
    BBadge,
    BModal,
    vSelect,
    flatPickr,
    // Local Components
    PaymentRemittanceViewHeader,
  },
  data() {
    return {
      configDate: {
        enableTime: true,
        dateFormat: 'd/m/Y H:i',
        locale: Portuguese,
        time_24hr: true,
      },
    }
  },
  methods: { truncateString, formatCnpj },
  setup() {
    const data = ref({})

    const CONTRACT_APP_STORE_MODULE_NAME = 'app-contract'

    // Register module
    if (!store.hasModule(CONTRACT_APP_STORE_MODULE_NAME)) store.registerModule(CONTRACT_APP_STORE_MODULE_NAME, contractStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CONTRACT_APP_STORE_MODULE_NAME)) store.unregisterModule(CONTRACT_APP_STORE_MODULE_NAME)
    })

    const paymentRemittanceModal = ref(null)

    const {
      fetchPaymentRemittance,
      tableColumns,
      perPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      showImediato,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      paymentRemittance,
      paymentRemittanceItem,

      // Extra Filters
      roleFilter,
      planFilter,
      situationFilter,
      statusFilter,
      createPaymentRemittance,
      paymentRemittanceItemData,
      removeItem,
      saveItem: savePaymentRemittanceItem,
    } = usePaymentRemittanceView()

    const accountTypeOptions = [
      { label: 'CC', value: 'checking_account' },
      { label: 'CP', value: 'savings_account' },
    ]

    function resolveAccountType(type) {
      const map = {
        checking_account: 'CC',
        savings_account: 'CP',
      }
      return map[type]
    }

    async function saveItem() {
      paymentRemittanceModal.value.hide()
      await savePaymentRemittanceItem()
    }
    function editItem(item) {
      paymentRemittanceItem.value = item
      let date = ''
      if (item.payment_date) {
        date = format(new Date(item.payment_date), 'dd/MM/yyyy HH:mm')
      }
      paymentRemittanceItemData.value = {
        item_id: item.id || '',
        account_number_and_code: `${item.account_number}-${item.account_code}`,
        operation_code: item.operation || '',
        payment_date: date,
        amount_with_discount: item.amount_with_discount,
        account_type: item.account_type,
        branch_number: item.branch_number,
        bank_name: item.bank_name,
      }
      paymentRemittanceModal.value.show()
    }

    return {
      editItem,
      resolveDeadlineVariant,
      paymentRemittanceModal,
      data,
      maskHiddenCpf,
      formatCurrency,
      formatDateTimeDDMMYYHHMM,
      paymentRemittance,
      paymentRemittanceItem,
      accountTypeOptions,
      removeItem,
      paymentRemittanceItemData,
      saveItem,
      resolveStatusVariant,

      /// /
      fetchPaymentRemittance,
      tableColumns,
      perPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      showImediato,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,

      // Extra Filters
      roleFilter,
      planFilter,
      situationFilter,
      statusFilter,
      createPaymentRemittance,
      resolveAccountType,
    }
  },
}
</script>

<style >
  .card-dark {
    background-color: rgba(186, 191, 199, 0.12);
  }
  .general > div {
    padding-top: 1.5rem;
    margin-right: 50px;
  }
  .card-general .card-body {
    padding-top: 0;
  }
  .general > div > :first-child, .authorization-form > div {
    font-size: 13px;
  }

  .authorization-form > div {
    margin-top: 0.5rem;
    margin-right: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .authorization-form > div > div {
    margin-bottom: 0;
  }
  .form-control {
    display: inline-block;
  }
  .form-control button {
    display: inline-block;
  }

  .code-input input  {
    max-width: 300px
  }
  .flatpickr-input {
    background-color: #fff!important;
  }
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.payment-remittance-view thead [role="columnheader"] div {
  white-space: nowrap;
}
.payment-remittance-view tbody [role="cell"] {
  white-space: nowrap;
}
button[type="submit"] {
  margin-top: 22px!important;
}
</style>
