<template>
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />
    <b-card
      no-body
      class="mb-0"
    >
      <div class="d-flex justify-content-end align-content-end px-2 mt-2">
        <div class="d-flex">
          <div class="mr-2">
            <label for="begin-date">Início em</label>
            <month-picker-input
              lang="pt"
              :default-month="defaultBegin.month"
              :default-year="defaultBegin.year"
              @change="changeBeginDate"
            />
          </div>
          <div class="mx-2">
            <label for="end-date">Fim em</label>
            <month-picker-input
              lang="pt"
              :default-month="defaultEnd.month"
              :default-year="defaultEnd.year"
              @change="changeEndDate"
            />
          </div>
        </div>
        <div class="pt-2 ml-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon mx-1"
            :disabled="loading"
            @click="loadReport"
          >
            Buscar
            <feather-icon
              icon="SearchIcon"
              size="16"
            />
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
            :disabled="reports.length === 0"
            @click="downloadReport"
          >
            Exportar
            <feather-icon
              icon="DownloadIcon"
              size="16"
            />
          </b-button>
        </div>
      </div>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <b-table
              :items="reports"
              responsive
              :fields="tableColumns"
              primary-key="id"
              show-empty
              empty-text="Nenhum registro correspondente encontrado"
              class="position-relative"
            >
              <template #cell(month)="{ item }">
                {{ item.month }}
              </template>
              <template #cell(total_amount_paid)="{ item }">
                {{ currency(item.total_amount_paid) }}
              </template>
              <template #cell(gross_profit)="{ item }">
                {{ currency(item.gross_profit) }}
              </template>
              <template
                #cell(revenue_growth_rate)="{ item }"
              >
                <div class="d-flex">
                  <div
                    v-if="item.revenue_growth_rate > 0"
                    class="trending trending-up"
                  >
                    <feather-icon
                      icon="ArrowUpIcon"
                      stroke-width="3"
                      size="16"
                    />
                  </div>
                  <div
                    v-if="item.revenue_growth_rate < 0"
                    class="trending trending-down"
                  >
                    <feather-icon
                      icon="ArrowDownIcon"
                      stroke-width="3"
                      size="16"
                    />
                  </div>
                  <div :class="{'zero':item.revenue_growth_rate === 0}">
                    {{ percentage(item.revenue_growth_rate) }}
                  </div>
                </div>
              </template>
              <template
                v-if="reports.length"
                slot="bottom-row"
              >
                <th class="text-right">
                  TOTAL
                </th>
                <th>{{ currency(footerData.total_advanced) }}</th>
                <th>{{ currency(footerData.total_gross_amount) }}</th>
                <th />
              </template>
            </b-table>
          </b-col>
        </b-row>
      </div>

    </b-card>
  </div>
</template>

<script>
import {
  format, addMonths,
} from 'date-fns'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { MonthPickerInput } from 'vue-month-picker'

import {
  ref, onUnmounted, computed,
} from '@vue/composition-api'
import {
  BCard, BRow, BCol, BButton, BTable,
} from 'bootstrap-vue'

import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import {
  formatDateTime, maskHiddenCpf, useRouter,
} from '@core/utils/utils'
import reportStoreModule from '../reportStoreModule'

export default {
  directives: {
    Ripple,
  },
  components: {
    BTable,
    BCard,
    BRow,
    BCol,
    MonthPickerInput,
    BButton,
  },
  methods: {
    maskHiddenCpf,
  },
  setup() {
    const { route } = useRouter()
    const REPORTS_STORE_MODULE_NAME = 'app-reports'
    // Register module
    if (!store.hasModule(REPORTS_STORE_MODULE_NAME)) store.registerModule(REPORTS_STORE_MODULE_NAME, reportStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORTS_STORE_MODULE_NAME)) store.unregisterModule(REPORTS_STORE_MODULE_NAME)
    })

    const formatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
    const currency = value => formatter.format(value / 100)
    const percentage = value => `${value}%`
    const formatDate = value => formatDateTime(value)
    const reports = ref([])
    const beginDate = ref(format(new Date(), 'yyyy-MM'))
    const endDate = ref(format(addMonths(new Date(), 1), 'yyyy-MM'))
    const loading = ref(true)
    const footerData = ref({
      total_advanced: 0,
      total_gross_amount: 0,
      total_net_amount: 0,
    })
    const defaultBegin = ref({
      year: parseInt(format(new Date(), 'yyyy'), 10),
      month: parseInt(format(new Date(), 'M'), 10),
    })
    const defaultEnd = ref({
      year: parseInt(format(addMonths(new Date(), 1), 'yyyy'), 10),
      month: parseInt(format(addMonths(new Date(), 1), 'M'), 10),
    })
    const currentRouteName = computed(() => route.value.name)
    const tableColumns = [
      { key: 'month', label: '', isRowHeader: true },
      { key: 'total_amount_paid', label: 'Valor antecipação' },
      { key: 'gross_profit', label: 'Lucro' },
      { key: 'revenue_growth_rate', label: '% Antecipação' },
    ]
    const getReportParams = () => ({
      start_month: beginDate.value,
      end_month: endDate.value,
    })

    const loadReport = () => {
      loading.value = true
      store.dispatch('app-reports/fetchProfitReport', getReportParams())
        .then(response => {
          loading.value = false
          if (response.data.data.rows) {
            reports.value = response.data.data.rows.map(e => {
              e.revenue_growth_rate = e.revenue_growth_rate || 0
              return e
            })
            footerData.value = {
              total_advanced: response.data.data.total_advanced,
              total_gross_amount: response.data.data.total_gross_amount,
            }
          }
        })
        .catch(() => {
          loading.value = false
        })
    }
    loadReport()

    const downloadReport = () => {
      const params = getReportParams()
      store.dispatch('app-reports/exportProfitReport', params)
        .then(response => {
          const blob = new Blob([response.data], { type: response.headers['content-type'] })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `Lucro-x-adiantamentos_${params.begin}_${params.end}.xlsx`
          link.click()
          URL.revokeObjectURL(link.href)
        })
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    function changeBeginDate(date) {
      beginDate.value = format(date.from, 'yyyy-MM')
    }
    function changeEndDate(date) {
      endDate.value = format(date.from, 'yyyy-MM')
    }

    return {
      defaultBegin,
      defaultEnd,
      changeBeginDate,
      changeEndDate,
      loading,
      currency,
      percentage,
      formatDate,
      reports,

      perfectScrollbarSettings,
      loadReport,
      // Left Sidebar Responsive
      mqShallShowLeftSidebar,
      beginDate,
      footerData,
      endDate,
      tableColumns,
      downloadReport,
      currentRouteName,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.month-picker__container {
  z-index: 1;
}

[role="rowheader"] {
  background-color: #f3f2f7;
}

.month-picker-input {
  color: #5e5873;
  padding: 0.8em 1.2em!important;
  font-size: 0.9em!important;
}
[dir] .month-picker__month.selected, [dir] .month-picker__month.selected-range-first, [dir] .month-picker__month.selected-range-second {
  background-color: #e0470e;
  box-shadow: inset 0 0 3px #e0470e, 0px 2px 5px rgb(91, 44, 27)
}

.trending {
  margin-right: 2px;
  &.trending-up {
    color: #28a745;
  }
  &.trending-down {
    color: #dc3545;
  }
}
.zero {
  margin-left: 16px;
}
</style>
