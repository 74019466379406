<template>
  <e-charts
    ref="line"
    autoresize
    :option="option"
    theme="theme-color"
    auto-resize
    :update-options="{notMerge:true}"
  />
</template>

<script>
import ECharts from 'vue-echarts'
import { ref, toRef, watch } from '@vue/composition-api'
import { registerTheme, use } from 'echarts/core'
import {
  BarChart, LineChart, LinesChart, PieChart,
} from 'echarts/charts'
import {
  DatasetComponent,
  DataZoomComponent,
  GridComponent,
  LegendComponent,
  LegendScrollComponent, MarkLineComponent, MarkPointComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent,
} from 'echarts/components'
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers'
import { format, parse } from 'date-fns'
import ptBrLocale from 'date-fns/locale/pt-BR'
import { roundTripArray } from '@core/utils/utils'
import theme from '../theme.json'

use([
  BarChart,
  LineChart,
  PieChart,
  LinesChart,
  LegendScrollComponent,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  CanvasRenderer,
  SVGRenderer,
  ToolboxComponent,
  DataZoomComponent,
  MarkLineComponent,
  MarkPointComponent,
])
registerTheme('theme-color', theme)

export default {
  components: {
    ECharts,
  },
  props: {
    mobile: {
      type: Object,
      default: () => ({ mobile: false }),
    },
    chartData: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const chartData = toRef(props, 'chartData')
    const mobile = toRef(props, 'mobile')

    const option = ref({
      title: {
        text: 'Evolução das vendas',
        subtext: 'Apresenta a evolução das vendas mensais de forma linear',
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
        },
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: [],
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: '{value}mil',
        },
        axisPointer: {
          snap: true,
        },
      },
      visualMap: null,
      series: [
        {
          name: 'Vendas',
          type: 'line',
          smooth: true,
          data: null,
        },
        {
          name: 'Tendência',
          type: 'line',
          showSymbol: false,
          lineStyle: {
            color: '#8e8e9a',
            type: 'dashed',
            width: 1,
          },
          data: [],
        },
      ],
    })

    watch([chartData, mobile], ([data]) => {
      if (mobile.value.mobile) {
        option.value.title.left = 'left'
      } else {
        option.value.title.left = 'center'
      }

      if (!data.value_month) return

      const colors = theme.color
      const seriesData = []
      const xAxisData = []
      const piecesData = {}
      const years = Object.keys(data.value_month).sort()
      let monthIndex = 0
      years.forEach((year, index) => {
        piecesData[year] = {
          gt: monthIndex,
          lte: 0,
          color: roundTripArray(colors, index),
        }
        Object.keys(data.value_month[year]).forEach(month => {
          monthIndex += 1
          piecesData[year].lte = monthIndex
          const monthYear = `${year}-${month}`
          const date = parse(monthYear, 'yyyy-MM', new Date())
          xAxisData.push(format(date, 'MMM/yy', { locale: ptBrLocale }))
          const price = data.value_month[year][month] / 100
          seriesData.push(price)
        })
      })

      option.value.xAxis.data = xAxisData
      option.value.series[0].data = seriesData
      option.value.series[1].data = Object.values(chartData.value.trendline).map(n => Math.round(n / 100))
      option.value.visualMap = {
        show: false,
        dimension: 0,
        pieces: Object.values(piecesData),
      }
    })

    return {
      option,
    }
  },
}
</script>
