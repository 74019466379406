<template>
  <div>
    <b-row class="mb-2">
      <b-col
        cols="12"
        md="6"
        class=""
      >
        <div class="d-flex align-items-center date-interval-picker">
          <b-button
            variant="outline-primary"
            class="action d-none d-md-inline-block mt-2 mr-1"
            :disabled="disabledPrevious || loading"
            title="Voltar 1 ano"
            @click="previous"
          >
            <feather-icon
              size="18"
              icon="ChevronLeftIcon"
            />
          </b-button>
          <div>
            <label for="workplace-id">De</label>
            <v-select
              v-model="lastYearDate"
              :options="yearOptions"
              :clearable="false"
              class="year-picker"
              :disabled="loading"
            />
          </div>
          <div class="ml-1">
            <label for="workplace-id">Até Ano</label>
            <v-select
              v-model="baseYearDate"
              :options="yearOptions"
              :clearable="false"
              class="year-picker"
              :disabled="loading"
            />
          </div>
          <b-button
            variant="outline-primary"
            class="action d-none d-md-inline-block mt-2 ml-1"
            title="Avançar 1 ano"
            :disabled="loading"
            @click="next"
          >
            <feather-icon
              size="18"
              icon="ChevronRightIcon"
            />
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        xs="12"
        xl="10"
        :style="mobile.mobile ? 'min-height: 650px' : 'min-height: 400px'"
      >
        <div
          class="h-100 d-flex flex-column flex-xl-row align-items-center text-left"
        >
          <institutions-pie
            :year="baseYearDate"
            :mobile="mobile"
            :chart-data="itemsInstitution"
          />
        </div>
      </b-col>
      <b-col
        cols="12"
        xs="12"
        xl="10"
        style="min-height: 500px"
      >
        <div
          class="h-100 d-flex flex-column flex-xl-row align-items-center text-left"
        >
          <institutions-bar
            :mobile="mobile"
            :chart-data="itemsInstitution"
          />
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col
        cols="12"
        xs="12"
        xl="12"
        style="min-height: 500px"
      >
        <div class="h-100 w-100 text-left">
          <sale-institution-by-year-bar
            :mobile="mobile"
            :chart-data="chartDataInstitution2"
          />
        </div>
      </b-col>
    </b-row>

    <hr class="mt-2">
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <label for="institution-id">Instituições</label>
        <v-select
          id="institution-id"
          v-model="institutionId"
          name="institution-id"
          :clearable="false"
          :options="institutionOptions"
          label="label"
          item-value="value"
          item-text="label"
        >
          <span slot="no-options">Nenhuma instituição encontrada.</span>
        </v-select>
      </b-col>
    </b-row>

    <b-row class="mt-1">
      <b-col
        cols="12"
        md="12"
        xl="9"
        style="min-height: 500px"
      >
        <div
          class="h-100 d-flex flex-column flex-xl-row align-items-center text-left"
        >
          <evolution-by-institution-bar
            :year="baseYearDate"
            :mobile="mobile"
            :chart-data="chartDataInstitution"
          />
        </div>
      </b-col>
      <b-col
        cols="12"
        md="12"
        xl="3"
        style="min-height: 500px"
      >
        <div
          class="h-100 d-flex flex-column flex-xl-row align-items-center text-left"
        >
          <resume-bar
            :mobile="mobile"
            :chart-data="chartDataInstitution"
          />
        </div>
      </b-col>
      <b-col
        cols="12"
        xs="12"
        xl="12"
        style="min-height: 500px"
      >
        <div
          class="h-100 d-flex flex-column flex-xl-row align-items-center text-left"
        >
          <evolution-by-institution-line
            :year="baseYearDate"
            :mobile="mobile"
            :chart-data="chartDataInstitution"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCol, BRow, BButton,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted, ref, watch } from '@vue/composition-api'
import { addYears } from 'date-fns'
import vSelect from 'vue-select'
import chartStoreModule from '@/views/charts/chartStoreModule'
import { maskCnpj } from '@core/utils/utils'
import InstitutionsBar from '@/views/charts/institution/InstitutionsBar.vue'
import SaleInstitutionByYearBar from '@/views/charts/institution/SaleInstitutionByYearBar.vue'
import InstitutionsPie from '@/views/charts/institution/InstitutionsPie.vue'
import EvolutionByInstitutionBar from './EvolutionByInstitutionBar.vue'
import EvolutionByInstitutionLine from './EvolutionByInstitutionLine.vue'
import ResumeBar from './ResumeBar.vue'

export default {
  components: {
    InstitutionsPie,
    SaleInstitutionByYearBar,
    InstitutionsBar,
    vSelect,
    BRow,
    BCol,
    BButton,
    EvolutionByInstitutionBar,
    EvolutionByInstitutionLine,
    ResumeBar,
  },
  props: {
    mobile: {
      type: Object,
      default: () => ({ mobile: false }),
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-chart'
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, chartStoreModule)

    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const currentYear = new Date().getFullYear()
    const getYear = (sub = 0) => addYears(new Date(), sub).getFullYear()

    const yearOptions = []
    const lastYearDate = ref(getYear(-1))
    const baseYearDate = ref(getYear())
    const loading = ref(true)
    const disabledNext = ref(true)
    const disabledPrevious = ref(false)
    const currentDateFormatted = ref('')

    const chartData = ref(null)
    const chartDataInstitution = ref(null)
    const chartDataInstitution2 = ref(null)
    const itemsInstitution = ref([])

    const institutionId = ref(null)
    const institutionOptions = ref([])

    const countYears = currentYear - 2022
    new Array(countYears + 1).fill(0).forEach((_, i) => {
      yearOptions.push(2022 + i)
    })

    store.dispatch('app-chart/fetchInstitutions', { perPage: 99999999 })
      .then(response => {
        institutionOptions.value = response.data.data.map(inst => ({
          value: inst.id,
          label: `${inst.name} | ${maskCnpj(inst.cnpj)}`,
        }))
        // eslint-disable-next-line prefer-destructuring
        institutionId.value = institutionOptions.value.find(({ value }) => value === 'aa3a3b5d-5f47-46b6-9e60-494eb42f0a98')
      })

    const refresh = () => {
      loading.value = true
      const baseYear = baseYearDate.value
      const lastYear = lastYearDate.value

      store.dispatch('app-chart/fetchTotalAdvancePerInstitutionInYear', { year: baseYear })
        .then(response => {
          loading.value = false
          itemsInstitution.value = response.data.data[Number(baseYear)]
        }).catch(() => {
          loading.value = false
        })
      store.dispatch('app-chart/fetchAverageAnnualAdvanceInstitution', {
        base_year: baseYear,
        last_year: lastYear,
      })
        .then(response => {
          chartDataInstitution2.value = response.data.data || {}
        }).catch(() => {
          loading.value = false
        })

      if (institutionId.value) {
        const institution = institutionId.value.value
        store.dispatch('app-chart/fetchEvolutionAdvanceByInstitution', {
          base_year: baseYear,
          last_year: lastYear,
          institution_id: institution,
        })
          .then(response => {
            chartDataInstitution.value = response.data.data || {}
          }).finally(() => {
            loading.value = false
          })
      }
    }
    refresh()

    watch([baseYearDate, lastYearDate, institutionId], () => {
      refresh()
    })

    const previous = () => {
      const year = lastYearDate.value - 1
      if (year <= 2022) {
        disabledPrevious.value = true
      } else {
        disabledPrevious.value = false
      }
      if (year < 2022) {
        return
      }

      lastYearDate.value = year
      baseYearDate.value -= 1
      refresh()
    }
    const next = () => {
      const year = baseYearDate.value + 1
      if (year <= currentYear) {
        lastYearDate.value += 1
        baseYearDate.value += 1
        disabledPrevious.value = false
        refresh()
      }
    }

    return {
      loading,
      yearOptions,
      itemsInstitution,
      baseYearDate,
      lastYearDate,
      previous,
      next,
      disabledNext,
      disabledPrevious,
      currentDateFormatted,
      chartData,
      chartDataInstitution,
      chartDataInstitution2,
      institutionId,
      institutionOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.v-text-field--box .v-input__slot, .v-text-field--outline .v-input__slot{
  max-height: 56px!important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select";

.card-body{
  position: relative;
  .pie-text{
    width: 105px;
    position:absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 44%;
    bottom: 0;
  }
  }

.card-header .title {
  min-width: 45px;
  text-align: center;
}
.action {
  padding: 2px 3px;
  margin-right: 5px;
  & ~ .action {
    margin-left: 5px;
  }
}
.year-picker {
  min-width: 100px;
}
</style>
