<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="mx-2 mb-2 mt-1">
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="3"
          >
            <label for="begin-date">Ativação de</label>
            <b-form-datepicker
              id="begin-date"
              v-model="beginDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="pt-BR"
              label-help=""
            />
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="3"
          >
            <label for="end-date">Ativação até</label>
            <b-form-datepicker
              id="end-date"
              v-model="endDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="pt-BR"
              label-help=""
            />
          </b-col>
          <b-col
            class="mt-1 mt-lg-0 d-flex align-items-end justify-content-start"
            cols="12"
            md="6"
            lg="3"
          />
          <!-- Search -->
          <b-col
            class="mt-1 mt-lg-0 d-flex align-items-end justify-content-start"
            cols="12"
            md="6"
            lg="3"
          >
            <div class="d-flex align-items-center justify-content-end w-100">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon mx-1"
                :disabled="!isValid"
                @click="refetchData"
              >
                Buscar
                <feather-icon
                  icon="SearchIcon"
                  size="16"
                />
              </b-button>
              <b-button
                variant="primary"
                @click="downloadReport"
              >
                <span class="text-nowrap">
                  Exportar
                  <feather-icon
                    icon="DownloadIcon"
                    size="16"
                  />
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ totalPhysicians }} entradas</span>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refPhysicianListTable"
        class="position-relative"
        :items="fetchPhysicians"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum registro correspondente encontrado"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Institution -->
        <template #cell(name)="data">
          <b-link
            :to="{ name: 'physician-view', params: { id: data.item.user_id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            <div :title="data.item.name">
              {{ truncateString(data.item.name, 15) }}
            </div>
          </b-link>
        </template>
        <template #cell(start_date)="{ item }">
          {{ formatDateTimeDDMMYY(new Date(item.start_date)) }} -
          {{ formatDateTimeDDMMYY(new Date(item.end_date)) }}
        </template>
        <template #cell(institution_name)="{ item }">
          <div v-if="item.institution_name">
            <div>{{ truncateString(item.institution_name, 20) }}</div>
            <div class="font-small-2">
              {{ formatCnpj(item.cnpj) }}
            </div>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BLink, BButton, BFormDatepicker,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { formatCnpj, formatDateTimeDDMMYY, truncateString } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import usePhysicianList from './usePhysicianList'
import physicianStoreModule from '../reportStoreModule'

export default {
  components: {
    BFormDatepicker,
    BCard,
    BRow,
    BCol,
    BTable,
    BLink,
    BButton,
  },
  directives: {
    Ripple,
  },
  methods: { formatCnpj, truncateString },
  setup() {
    const PHYSICIAN_APP_STORE_MODULE_NAME = 'app-physician'

    // Register module
    if (!store.hasModule(PHYSICIAN_APP_STORE_MODULE_NAME)) store.registerModule(PHYSICIAN_APP_STORE_MODULE_NAME, physicianStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PHYSICIAN_APP_STORE_MODULE_NAME)) store.unregisterModule(PHYSICIAN_APP_STORE_MODULE_NAME)
    })

    const paymentStatusOptions = []
    const isValid = ref(true)
    const institutionOptions = ref(null)

    const getClassificationColor = color => {
      const colors = {
        red: '#df7273',
        yellow: '#ffb976',
        green: '#48da89',
      }
      return colors[color]
    }

    const {
      fetchPhysicians,
      tableColumns,
      perPage,
      currentPage,
      totalPhysicians,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPhysicianListTable,
      refetchData,
      downloadReport,
      resolvePhysicianRoleVariant,
      resolvePhysicianRoleIcon,
      resolvePhysicianStatusVariant,
      situationFilter,
      statusFilter,
      classificationFilter,
      scheduleRestrictionFilter,
      beginDate,
      endDate,
    } = usePhysicianList()

    return {
      isValid,
      downloadReport,
      fetchPhysicians,
      tableColumns,
      perPage,
      currentPage,
      totalPhysicians,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPhysicianListTable,
      refetchData,
      beginDate,
      endDate,
      // Filter
      avatarText,
      // UI
      resolvePhysicianRoleVariant,
      resolvePhysicianRoleIcon,
      resolvePhysicianStatusVariant,
      paymentStatusOptions,
      // Extra Filters
      situationFilter,
      statusFilter,
      getClassificationColor,
      classificationFilter,
      scheduleRestrictionFilter,
      institutionOptions,
      formatDateTimeDDMMYY,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.physician-class {
  width: 15px;
  height: 15px;
  border-spacing: 0!important;
  margin-top: 2px;
}
.physician-class {
  width: 15px;
  height: 15px;
  border-spacing: 0!important;
  margin-top: 2px;
  &:before {
    content: '';
    transform: translateY(3px);
    width: 15px;
    height: 15px;
    display: inline-block;
    border-radius: 15px;
    background-position: center center;
    background-repeat: no-repeat;
  }
  &-red {
    &:before {
      background-color: #f08182;
    }
  }
  &-yellow {
    &:before {
      background-color: #ffb976;
    }
  }
  &-green {
    &:before {
      background-color: #48da89;
    }
  }
}
@media (max-width: 1400px) {
  .v-select {
    font-size: 12px;
  }
}
.v-select {
  .physician-class {
    &:before {
      transform: translateY(2px) translateX(-4px);
    }
  }
}
.vs__selected {
  .physician-class {
    &:before {
      transform: translateY(-4px) translateX(-2px);
    }
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
