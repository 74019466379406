<template>
  <div>

    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon
        icon="UserIcon"
        size="19"
      />
      <h4 class="mb-0 ml-50">
        Dados Pessoais
      </h4>
    </div>

    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <b-row>

        <!-- Field: Birth Date -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Data Nascimento"
            label-for="birth-date"
          >
            <flat-pickr
              v-model="physicianData.dob"
              class="form-control"
              :config="{ dateFormat: 'd/m/Y'}"
              placeholder="DD/MM/YYYY"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Mobile -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Fone"
            label-for="mobile"
          >
            <b-form-input
              id="mobile"
              v-model="physicianData.phone"
              v-mask="'(##) #####-####'"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Website -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="CPF"
            label-for="cpf"
          >
            <b-form-input
              id="cpf"
              v-model="physicianData.cpf"
              v-mask="'###.###.###-##'"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Website -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="CRM"
            label-for="crm"
          >
            <b-form-input
              id="crm"
              v-model="physicianData.crm"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Gender -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Gênero"
            label-for="gender"
            label-class="mb-1"
          >
            <b-form-radio-group
              id="gender"
              v-model="physicianData.gender"
              :options="genderOptions"
              value="male"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Contact Options -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Opções de Contato"
            label-for="contact-options"
            label-class="mb-1"
          >
            <b-form-checkbox-group
              id="contact-options"
              v-model="physicianData.contactOptions"
              :options="contactOptionsOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Header: Personal Info -->
      <div class="d-flex mt-2">
        <feather-icon
          icon="MapPinIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Endereço
        </h4>
      </div>

      <!-- Form: Personal Info Form -->
      <b-row class="mt-1">
        <!-- Field: Postcode -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="CEP"
            label-for="postcode"
          >
            <b-form-input
              id="postcode"
              v-model="physicianData.address.postalCode"
              v-mask="'#####-###'"
              placeholder="04849-529"
            />
          </b-form-group>
        </b-col>

        <!-- Field: State -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Estado"
            label-for="state"
          >
            <v-select
              v-model="physicianData.address.stateId"
              autocomplete="state"
              :options="stateOptions"
              :clearable="false"
              label="name"
              :reduce="value => value.id"
              input-id="state"
            />
          </b-form-group>
        </b-col>

        <!-- Field: City -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Cidade"
            label-for="city"
          >
            <v-select
              v-model="physicianData.address.cityId"
              autocomplete="city"
              :options="cityOptions"
              :clearable="false"
              label="name"
              :reduce="value => value.id"
              input-id="city"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Street -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Logradouro"
            label-for="address-line-1"
          >
            <b-form-input
              id="address-line-1"
              v-model="physicianData.address.street"
              autocomplete="street"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Complement -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Complemento"
            label-for="address-line-2"
          >
            <b-form-input
              id="address-line-2"
              v-model="physicianData.address.complement"
              placeholder="Los Santos"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="secondary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            Salvar
          </b-button>
          <b-button
            variant="outline-primary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            Voltar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import physicianStoreModule from '@/views/physician/physicianStoreModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, flatPickr, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton, vSelect,
  },
  props: {
    physicianData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const stateOptions = ref([])
    const cityOptions = ref([])
    const contactOptionsOptions = ['Email', 'SMS', 'Fone']
    const genderOptions = [
      { text: 'Masculino', value: 'male' },
      { text: 'Feminino', value: 'female' },
      { text: 'Outro', value: 'other' },
    ]

    const PHYSICIAN_APP_STORE_MODULE_NAME = 'app-physician'

    // Register module
    if (!store.hasModule(PHYSICIAN_APP_STORE_MODULE_NAME)) store.registerModule(PHYSICIAN_APP_STORE_MODULE_NAME, physicianStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PHYSICIAN_APP_STORE_MODULE_NAME)) store.unregisterModule(PHYSICIAN_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-physician/fetchStates')
      .then(response => {
        stateOptions.value = response.data.data
      })

    store.dispatch('app-physician/fetchCities')
      .then(response => {
        cityOptions.value = response.data.data
      })

    function onSubmit() {
      store.dispatch('app-physician/updatePhysician', this.physicianData)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Salvo',
              icon: 'CheckIcon',
              variant: 'success',
              text: 'Salvo com sucesso!',
            },
          })
        })
    }

    return {
      cityOptions,
      stateOptions,
      genderOptions,
      contactOptionsOptions,
      onSubmit,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
