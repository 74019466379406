<template>
  <div>
    <audit-header :audit="audit" />
    <hr>
    <div>
      <h6 class="text-capitalize mb-0">
        Remessa
      </h6>
      <div>
        {{ value.code }}
      </div>
    </div>
    <div class="mt-1 mb-1">
      <h6 class="text-capitalize mb-0">
        Data de Pagamento
      </h6>
      <div>
        {{ formatDateTime(new Date(value.payment_date)) }}
      </div>
    </div>

    <h4 class="text-capitalize mt-2">
      Itens
    </h4>
    <div class="items">
      <div
        v-for="(item, i) in value.items"
        :key="item.id"
        class="d-flex align-items-center"
      >
        <div class="mt-1">
          <strong>#{{ i + 1 }}</strong>
        </div>
        <div class="mt-1">
          <h6 class="text-capitalize mb-0">
            Médico
          </h6>
          <div>
            {{ item.contract.physician.user.name }}
          </div>
        </div>
        <div class="mt-1">
          <h6 class="text-capitalize mb-0">
            Instituição
          </h6>
          <div>
            {{ item.contract.institution.name }}
          </div>
        </div>
        <div class="mt-1">
          <h6 class="text-capitalize mb-0">
            Status
          </h6>
          <div>
            {{ getStatusTitle(item.status) }}
          </div>
        </div>
        <div class="mt-1">
          <h6 class="text-capitalize mb-0">
            Operação
          </h6>
          <div>
            {{ item.operation ? item.operation : '-' }}
          </div>
        </div>
        <div class="mt-1">
          <h6 class="text-capitalize mb-0">
            Data Pagamento
          </h6>
          <div>
            {{ item.payment_date ? formatDateTime(new Date(item.payment_date)) : '-' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { formatDateTime } from '@core/utils/utils'
import AuditHeader from './Header.vue'

export default {
  components: {
    AuditHeader,
  },
  props: {
    audit: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const formatCurrency = val => Number(val).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    const value = ref(props.audit.current_values)

    function getStatusTitle(status) {
      const titles = {
        paid: 'Pago',
        pending: 'Pendente',
        synchronized: 'Sincronizado',
      }
      return titles[status]
    }

    return {
      value,
      formatDateTime,
      formatCurrency,
      getStatusTitle,
    }
  },
}
</script>

<style lang="scss" scoped>
hr {
  margin-top: 4px;
  margin-bottom: 10px;
}
.items > div > div {
  margin-left: 15px;
}
.items > div > div:first-child {
  margin-left: 0;
}
</style>
