<template>
  <div>
    <b-card-title
      title-tag="h2"
      class="font-weight-bold mb-1"
    >
      Digitar código de verificação
    </b-card-title>
    <b-card-text class="mb-2">
      Um email com um código de verificação foi enviado para <strong>{{ user.email }}</strong>
    </b-card-text>

    <!-- form -->
    <validation-observer ref="codeRules">
      <b-form
        class="auth-forgot-password-form mt-2"
        @submit.prevent="resetPasswordValidate"
      >
        <b-form-group>
          <div class="d-flex justify-content-between">
            <label for="forgot-password-code">Código de verificação</label>
            <b-link
              v-if="resendEnabled"
              @click.prevent="sendForgotPassword()"
            >
              <small>Não recebeu? Reenviar</small>
            </b-link>
          </div>
          <validation-provider
            #default="{ errors }"
            name="Código de verificação"
            vid="token"
            rules="required|digits:6"
          >
            <b-form-input
              id="forgot-password-code"
              v-model="user.code"
              :state="errors.length > 0 ? false:null"
              name="forgot-password-code"
              placeholder="X X X X"
              size="lg"
              maxlength="6"
              :data-autocomplete="'verification-code-' + _uid"
              aria-autocomplete="none"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-button
          v-if="!submitting"
          type="submit"
          variant="primary"
          block
        >
          Continuar
        </b-button>
        <b-button
          v-else
          disabled
          type="submit"
          variant="primary"
          block
        >
          Continuar
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BLink, BCardTitle, BCardText, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import {
  required, email, digits, password, confirmed,
} from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useSanctum from '@/auth/sanctum/useSanctum'

export default {
  components: {
    BLink,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      submitting: false,
      timer: null,
      lastRequestSeconds: 0,
      // validation
      required,
      email,
      digits,
      password,
      confirmed,
    }
  },
  computed: {
    resendEnabled() {
      return this.lastRequestSeconds > 60
    },
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  created() {
    this.startTimer()
  },
  methods: {
    toast(props) {
      this.$toast({
        component: ToastificationContent,
        props: {
          icon: 'AlertCircleIcon',
          variant: 'danger',
          ...props,
        },
      })
    },
    startTimer() {
      clearInterval(this.timer)
      this.lastRequestSeconds = 0
      this.timer = setInterval(() => {
        this.lastRequestSeconds += 1
      }, 1000)
    },
    // ReSend Verification Code
    forgotPassword() {
      return this.sendForgotPassword()
        .then(() => {
          this.startTimer()
        })
        .catch(() => {})
    },
    async sendForgotPassword() {
      this.submitting = true
      return useSanctum.forgotPassword({
        email: this.user.email,
        recaptcha: await this.$recaptcha('login'),
      })
        .then(() => {
          this.submitting = false
          this.lastRequestSeconds = 0
          this.user.code = ''
          this.$refs.codeRules.reset()
          this.toast({
            title: 'Código Reenviado',
            text: 'Um novo código foi enviado para seu e-mail',
            icon: 'MailIcon',
            variant: 'success',
          })
        })
        .catch(error => {
          this.submitting = false

          const errors = (error.response && error.response.status === 422 && error.response.data.errors) || {}
          if (Object.keys(errors).some(key => key === 'recaptcha')) {
            this.$refs.codeRules.setErrors({
              code: 'Dispositivo não passou no teste de segurança',
            })
          } else {
            const title = error.response.status === 429 // throttled
              ? 'Tente novamente em alguns minutos' : 'Email inválido'
            this.toast({ title })
          }
          throw error
        })
    },
    // Validate Verification Code
    resetPasswordValidate() {
      this.submitting = true
      this.$refs.codeRules.validate().then(success => {
        if (!success) return null
        return this.sendResetPasswordValidate()
      })
    },
    async sendResetPasswordValidate() {
      await this.$recaptchaLoaded()
      return useSanctum.resetPasswordValidate({
        email: this.user.email,
        code: this.user.code,
        recaptcha: await this.$recaptcha('login'),
      })
        .then(response => {
          this.submitting = false
          this.user.token = response.data.data
          this.$emit('step', 2)
        })
        .catch(error => {
          this.submitting = false
          if (error.response.status === 429) {
            this.toast({ title: 'Muitas tentativas, tente novamente em alguns minutos' })
          } else {
            this.$refs.codeRules.setErrors({
              token: 'Código inválido ou expirado',
            })
            this.lastRequestSeconds = 60
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
