<template>
  <div class="d-flex align-items-center">
    <div class="event flex-column text-center">
      <h5>
        {{ audit.auditable.type_translated }}
      </h5>
      <b-badge
        pill
        :variant="`light-${eventClass}`"
        class="text-capitalize"
      >
        {{ audit.event_translated }}
      </b-badge>
    </div>
    <div class="ml-1">
      <h6 class="mb-0">
        Data Registro
      </h6>
      <div>
        {{ formatDate(new Date(createdAt), 'dd/MM/yyyy HH:mm:ss') }}
      </div>
    </div>
    <div>
      <h6 class="ml-1 mb-0">
        Usuário
      </h6>
      <div class="ml-1">
        {{ user.name }}
      </div>
    </div>
    <div
      v-if="deletedAt"
      class="ml-1"
    >
      <h6 class="mb-0">
        Data remoção
      </h6>
      <div>
        {{ formatDate(new Date(deletedAt), 'dd/MM/yyyy HH:mm:ss') }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { format } from 'date-fns'
import {
  BBadge,
} from 'bootstrap-vue'
import { resolveEventVariant } from '@/views/audit/audit-list/useAuditList'

export default {
  components: {
    BBadge,
  },
  props: {
    audit: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const user = ref(props.audit.user)
    const createdAt = ref(props.audit.created_at)
    const deletedAt = props.audit.deleted_at ? ref(props.audit.deleted_at) : null
    const eventClass = resolveEventVariant(props.audit.event)
    return {
      user,
      eventClass,
      createdAt,
      deletedAt,
      formatDate: format,
    }
  },
}
</script>

<style lang="scss" scoped>
hr {
  margin-top: 4px;
  margin-bottom: 10px;
}
.event h5 {
 margin-top: 3px;
  margin-bottom: 0;
}
</style>
