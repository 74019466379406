<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ userId ? 'Editar Usuário' : 'Novo Usuário' }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Nome"
            rules="required"
          >
            <b-form-group
              label="Nome"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="userData.name"
                autofocus
                :state="validationContext.touched ? getValidationState(validationContext) : null"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userData.email"
                :state="validationContext.touched ? getValidationState(validationContext) : null"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- CPF -->
          <validation-provider
            #default="validationContext"
            name="CPF"
            rules="required|cpf"
          >
            <b-form-group
              label="CPF"
              label-for="cpf"
            >
              <b-form-input
                id="cpf"
                v-model="userData.cpf"
                v-mask="'###.###.###-##'"
                :state="validationContext.touched ? getValidationState(validationContext) : null"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- access_type -->
          <validation-provider
            #default="{ errors }"
            name="tipo"
            vid="accessTypes"
            rules="required|min:1"
          >
            <b-form-group
              id="input-group-11"
              label="Permissão"
              label-for="accessTypes"
            >
              <v-select
                id="accessTypes"
                v-model="userData.access_type"
                name="accessTypes"
                :options="accessTypes"
                :clearable="false"
                :reduce="v => v.value"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <div class="d-flex mt-2 align-items-center">
            <b-form-checkbox
              v-model="userData.show_cashback"
              class="custom-control-success"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
            <div>
              Permissão para visualizar cashback
            </div>
          </div>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              class="mr-2"
              type="submit"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-primary"
              @click="hide"
            >
              Voltar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, watch } from '@vue/composition-api'
import {
  required, alphaNum, email, cpf,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BFormCheckbox,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    userId: {
      type: String,
      default: undefined,
    },
    institutionId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      cpf,
      countries: [],
    }
  },
  setup(props, { emit }) {
    const accessTypes = ref([])
    const blankUserData = {
      name: '',
      email: '',
      cpf: '',
      institution_id: '',
      show_cashback: false,
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetUserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    function getUser(id) {
      store
        .dispatch('app-institution-user/fetchUser', { id, institutionId: props.institutionId })
        .then(response => {
          userData.value = response.data.data
          userData.value.show_cashback = userData.value.institution.show_cashback
        })
    }

    const onSubmit = () => {
      const user = { ...userData.value, institution_id: props.institutionId }
      store.dispatch(user.id ? 'app-institution-user/updateUser' : 'app-institution-user/addUser', user)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }

    function getAccessTypes() {
      store
        .dispatch('app-institution-user/fetchAccessTypes', { type: 'client' })
        .then(response => {
          accessTypes.value = response.data.data.map(t => ({
            value: t.slugname,
            label: t.name,
          }))
        })
    }
    getAccessTypes()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetUserData)

    watch(() => props.isAddNewUserSidebarActive, show => {
      resetForm()
      resetUserData()
      if (show && props.userId) {
        getUser(props.userId)
      }
    })

    return {
      userData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      accessTypes,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
