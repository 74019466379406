<template>
  <div>
    <b-row>
      <b-col
        cols="12"
      >
        <!-- Table Container Card -->
        <b-card
          no-body
          class="mb-0"
        >
          <div class="mx-2 mb-2 mt-0">
            <b-row class="justify-content-end">
              <b-col
                cols="12"
                md="4"
                lg="2"
              >
                <label for="begin-date">Início em</label>
                <b-form-datepicker
                  id="begin-date"
                  v-model="beginDate"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  locale="pt-BR"
                  label-help=""
                />
              </b-col>
              <b-col
                cols="12"
                md="4"
                lg="2"
              >
                <label for="end-date">Fim em</label>
                <b-form-datepicker
                  id="end-date"
                  v-model="endDate"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  locale="pt-BR"
                  label-help=""
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                lg="8"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <label for="institution-id">Instituição</label>
                    <v-select
                      id="institution-id"
                      v-model="institutionId"
                      name="institution-id"
                      :options="institutionOptions"
                      label="label"
                      item-value="value"
                      item-text="label"
                    >
                      <span slot="no-options">Nenhuma instituição encontrada.</span>
                    </v-select>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <b-table
            ref="refUserListTable"
            class="position-relative table-physician"
            :items="fetchPhysicians"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="Nenhum registro correspondente encontrado"
            :sort-desc.sync="isSortDirDesc"
          >
            <template #cell(status)="{ item }">
              <div class="d-flex align-content-start">
                <b-badge
                  v-if="item.status === 'processed' && item.metrics.errors == 0"
                  pill
                  variant="light-success"
                >
                  Importado
                </b-badge>
                <div
                  v-else
                  class="d-flex flex-column align-items-center justify-content-center"
                >
                  <div>
                    <b-badge
                      v-if="item.status === 'processing'"
                      class="progress-bar"
                      pill
                      variant="light-secondary"
                    >
                      Processando
                    </b-badge>
                  </div>
                  <div>
                    <b-badge
                      v-if="item.status === 'processed'"
                      pill
                      variant="light-warning"
                    >
                      Importação Parcial
                    </b-badge>
                  </div>
                  <div class="d-inline-block text-muted font-weight-bold">
                    <span>{{ item.metrics.imports }}</span>/<span class="font-weight-bold">{{ item.metrics.errors + item.metrics.imports }}</span> importados
                  </div>
                </div>
              </div>
            </template>
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item
                  :to="{
                    name: 'import-physician-view',
                    params: { id: data.item.id },
                  }"
                >
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">Detalhes</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="
                  d-flex
                  align-items-center
                  justify-content-center justify-content-sm-start
                "
              >
                <span class="text-muted">
                  Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas
                </span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="
                  d-flex
                  align-items-center
                  justify-content-center justify-content-sm-end
                "
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalUsers"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      id="modal-import"
      ref="importModal"
      title="Importação de Médicos"
      hide-footer
    >
      <b-form-file
        v-model="file"
        placeholder="Escolha um arquivo..."
        drop-placeholder="Solte o arquivo aqui..."
        browse-text="Selecionar"
        accept=".csv"
      />

      <div class="mt-1">
        <div>
          <h6>Formatos aceitos</h6>
          <div class="flex-column">
            <div><strong>cpf</strong>: 123.456.789-10, 12345678910</div>
            <div><strong>telefone</strong>: (82) 91234-1234, 82912341234</div>
            <div><strong>estado_crm</strong>: SP, sp</div>
            <div><strong>nascimento</strong>: 29-12-1990, 29/12/1990</div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-end">
        <div class="mb-1 mb-1 w-100">
          <b-link
            class="b-link"
            @click="downloadModel"
          >
            Baixar modelo
            <feather-icon
              icon="DownloadIcon"
              class="d-inline"
            />
          </b-link>
        </div>
        <div class="text-right mt-2">
          <b-button
            :disabled="loading"
            class="ml-1"
            variant="primary"
            @click="sendFile"
          >
            <span class="text-nowrap">
              Importar
            </span>
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BLink,
  BCol,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BButton,
  BModal,
  VBModal,
  BFormFile, BBadge, BFormDatepicker,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted, ref, watch } from '@vue/composition-api'
import { downloadFiles, maskCnpj } from '@core/utils/utils'
import vSelect from 'vue-select'
import usePhysicianList from './usePhysicianList'
import physicianStoreModule from '../physicianStoreModule'

export default {
  components: {
    BFormDatepicker,
    vSelect,
    BBadge,
    BCard,
    BLink,
    BRow,
    BCol,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BModal,
    BFormFile,
  },
  directives: {
    'b-modal': VBModal,
  },
  setup() {
    const STORE_MODULE_NAME = 'app-physician'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) { store.registerModule(STORE_MODULE_NAME, physicianStoreModule) }

    const importModal = ref(null)
    const file = ref(undefined)
    const loading = ref(false)

    const openImportModal = () => {
      importModal.value.show()
    }

    watch(file, () => {
      // validateFile(file.value)
    }, { deep: true })

    const downloadModel = () => {
      downloadFiles('importacao-medicos.csv', `nome;email;cpf;crm;estado_crm;nascimento;telefone;cnpj;razao_social
Joao Silva;joao.silva@sample.com;39086699014;1234;SP;1999-01-01;82912341234;49069663000151;Nome empresa`)
    }

    const {
      fetchPhysicians,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      uploadFile,
      beginDate,
      endDate,
      institutionId,
      institutionOptions,
      onUnmountedList,
    } = usePhysicianList()

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) { store.unregisterModule(STORE_MODULE_NAME) }
      onUnmountedList()
    })

    store.dispatch('app-physician/fetchInstitutions', { perPage: 99999999 })
      .then(response => {
        institutionOptions.value = response.data.data.map(inst => ({
          value: inst.id,
          label: `${inst.name} | ${maskCnpj(inst.cnpj)}`,
        }))
      })

    const sendFile = () => {
      loading.value = true
      uploadFile(file.value)
        .then(() => {
          importModal.value.hide()
          file.value = undefined
          refetchData()
          loading.value = false
        })
        .catch(() => {
          loading.value = false
        })
    }

    return {
      downloadModel,
      fetchPhysicians,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      // UI
      openImportModal,
      importModal,
      file,
      sendFile,
      loading,
      beginDate,
      endDate,
      institutionId,
      institutionOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.table-physician {
  min-height: 200px;
}

.progress-bar{
  max-width: 100px;
  background: rgb(178, 175, 165);
  -webkit-animation-name: pulse; /* Chrome, Safari, Opera */
  -webkit-animation-duration: 2s; /* Chrome, Safari, Opera */
  -webkit-animation-iteration-count: infinite;
  animation-name: pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@-webkit-keyframes pulse {
  0%   {background: rgb(205, 205, 204);}
  25%   {background: rgb(205, 205, 204);}
  50%   {background: rgb(205, 205, 204);}
  75%  {background: rgb(233, 233, 232);}
  85%{background: rgb(205, 205, 204);}
  100% {background: rgb(205, 205, 204);}
}

@keyframes pulse {
  0%   {background: rgb(205, 205, 204);}
  25%   {background: rgb(205, 205, 204);}
  50%   {background: rgb(205, 205, 204);}
  75%  {background: rgb(233, 233, 232);}
  85%{background: rgb(205, 205, 204);}
  100% {background: rgb(205, 205, 204);}
}

.badge.badge-light-secondary {
  color: #82868b !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
