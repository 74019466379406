<template>
  <b-sidebar
    id="add-new-physician-sidebar"
    :visible="isAddNewPhysicianSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-physician-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Editar Médico
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Nome"
            rules="required"
          >
            <b-form-group
              label="Nome"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="physicianData.name"
                autofocus
                :state="validationContext.touched ? getValidationState(validationContext) : null"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="physicianData.email"
                :state="validationContext.touched ? getValidationState(validationContext) : null"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- CPF -->
          <validation-provider
            #default="validationContext"
            name="CPF"
            rules="required|cpf"
          >
            <b-form-group
              label="CPF"
              label-for="cpf"
            >
              <b-form-input
                id="cpf"
                v-model="physicianData.cpf"
                v-mask="'###.###.###-##'"
                :state="validationContext.touched ? getValidationState(validationContext) : null"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              class="mr-2"
              type="submit"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-primary"
              @click="hide"
            >
              Voltar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, watch } from '@vue/composition-api'
import {
  required, alphaNum, email, cpf,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import formError from '@core/comp-functions/forms/form-error'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewPhysicianSidebarActive',
    event: 'update:is-add-new-physician-sidebar-active',
  },
  props: {
    isAddNewPhysicianSidebarActive: {
      type: Boolean,
      required: true,
    },
    physicianId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      cpf,
      countries: [],
    }
  },
  setup(props, { emit }) {
    const blankPhysicianData = {
      id: '',
      name: '',
      email: '',
      cpf: '',
    }

    const physicianData = ref(JSON.parse(JSON.stringify(blankPhysicianData)))
    const resetPhysicianData = () => {
      physicianData.value = JSON.parse(JSON.stringify(blankPhysicianData))
    }

    function getPhysician(id) {
      store
        .dispatch('app-physician/fetchPhysician', { id })
        .then(response => {
          const { data } = response.data
          physicianData.value = {
            user_id: data.user_id,
            name: data.name,
            email: data.email,
            cpf: data.cpf,
          }
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetPhysicianData)

    function onSubmit() {
      const physician = { ...physicianData.value }
      store.dispatch('app-physician/updateUserPhysician', physician)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-physician-sidebar-active', false)
        })
        .catch(error => {
          const { setErrors, getErrorMessage } = formError(refFormObserver.value, error)
          setErrors()
          if (getErrorMessage()) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Erro',
                icon: 'XOctagonIcon',
                variant: 'danger',
                text: getErrorMessage(),
              },
            })
          }
        })
    }

    watch(() => props.isAddNewPhysicianSidebarActive, show => {
      resetForm()
      resetPhysicianData()
      if (show && props.physicianId) {
        getPhysician(props.physicianId)
      }
    })

    return {
      physicianData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-physician-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
