<template>
  <div class="container-iframe">
    <iframe
      v-if="url"
      class="responsive-iframe"
      :src="url"
    />
  </div>
</template>

<script>
import store from '@/store'
import businessProcessStoreModule from '@/views/business-process/businessProcessStoreModule'
import { onUnmounted, ref } from '@vue/composition-api'

export default {
  components: {},
  setup() {
    const APP_STORE_MODULE_NAME = 'app-business-process'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, businessProcessStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
    const url = ref(null)
    store.dispatch('app-business-process/fetchBusinessProcessesToken')
      .then(response => {
        const { user, password } = response.data
        window.open(`https://${user}:${password}@processo.imediato.online`)
      })

    return {
      url,
    }
  },
}
</script>

<style lang="scss">
.container-iframe {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>
