<template>
  <div>
    <audit-header :audit="audit" />
    <hr>
    <div>
      <h6 class="text-capitalize mb-0">
        Motivo
      </h6>
      <div>
        {{ value.canceled_contract_history ? value.canceled_contract_history[value.canceled_contract_history.length - 1].motive : '-' }}
      </div>
    </div>
    <hr>
    <div>
      <h6 class="text-capitalize mb-0">
        Médico
      </h6>
      <div>
        {{ value.physician ? value.physician.alias : '-' }}
      </div>
    </div>
    <div class="mt-1">
      <h6 class="text-capitalize mb-0">
        Plantão
      </h6>
      <div>
        {{ value.workplace_duty.duty_type.type }} <b>|</b>
        {{ value.workplace_duty.duration }}h <b>|</b>
        {{ formatCurrency(value.workplace_duty.remuneration / 100) }}
      </div>
    </div>
    <div class="mt-1">
      <h6 class="text-capitalize mb-0">
        Data de início
      </h6>
      <div>
        {{ formatDateTime(new Date(value.start_date)) }}
      </div>
    </div>
    <div class="mt-1">
      <h6 class="text-capitalize mb-0">
        Data de fim
      </h6>
      <div>
        {{ formatDateTime(new Date(value.end_date)) }}
      </div>
    </div>
    <div class="mt-1">
      <h6 class="text-capitalize mb-0">
        Valor
      </h6>
      <div>
        {{ formatCurrency(value.amount_paid / 100) }}
      </div>
    </div>
    <div class="mt-1">
      <h6 class="text-capitalize mb-0">
        Instituição
      </h6>
      <div>
        {{ value.institution.fancy_name }}
      </div>
    </div>
    <div class="mt-1">
      <h6 class="text-capitalize mb-0">
        Confirmado
      </h6>
      <div>
        <div v-if="!value.confirmed_at">
          -
        </div>
        <div v-if="value.confirmed_at">
          {{ value.confirmed_by.name }}
          <span class="text-dark">
            em {{ formatDateTime(new Date(value.confirmed_at)) }}
          </span>
        </div>
      </div>
    </div>
    <div class="mt-1">
      <h6 class="text-capitalize mb-0">
        Autorizado
      </h6>
      <div>
        <div v-if="!value.authorized_at">
          -
        </div>
        <div v-if="value.authorized_at">
          {{ value.authorized_by.name }}
          <span class="text-dark">
            em {{ formatDateTime(new Date(value.authorized_at)) }}
          </span>
        </div>
      </div>
    </div>
    <div class="mt-1">
      <h6 class="text-capitalize mb-0">
        Liberado
      </h6>
      <div>
        <div v-if="!value.released_at">
          -
        </div>
        <div v-if="value.released_at">
          {{ value.released_by.name }}
          <span class="text-dark">
            em {{ formatDateTime(new Date(value.released_at)) }}
          </span>
        </div>
      </div>
    </div>
    <div class="mt-1">
      <h6 class="text-capitalize mb-0">
        Adiantamento
      </h6>
      <div>
        {{ value.request_advance ? 'Sim' : 'Não' }}
      </div>
    </div>
    <div class="mt-1">
      <h6 class="text-capitalize mb-0">
        Unidade
      </h6>
      <div>
        {{ value.workplace.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { formatDateTime } from '@core/utils/utils'
import AuditHeader from './Header.vue'

export default {
  components: {
    AuditHeader,
  },
  props: {
    audit: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const formatCurrency = val => Number(val).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    const value = ref(props.audit.current_values)
    return {
      value,
      formatDateTime,
      formatCurrency,
    }
  },
}
</script>

<style lang="scss" scoped>
hr {
  margin-top: 4px;
  margin-bottom: 10px;
}
</style>
