import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTotalAdvancePerInstitutionInYear(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/advance-indicator/institution/total-advance-in-year', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGeneralAdvancePerYear(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/advance-indicator/general-advance-per-year', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAverageAnnualAdvanceInstitution(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/advance-indicator/institution/average-annual-advance-with-previous-year', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchEvolutionAdvanceByInstitution(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/advance-indicator/institution/evolution-advance-by-intitution', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAverageAndTotalByWorkplace(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/advance-indicator/workplace/average-and-total-annual-advance-with-previous-year', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchEvolutionAdvanceByWorkplace(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/advance-indicator/workplace/evolution-advance-by-workplace', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchEvolutionAdvanceByPhysician(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/advance-indicator/physician/evolution-advance-by-physician', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAverageAndTotalByPhysician(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/advance-indicator/physician/average-and-total-annual-advance-with-previous-year', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDutyTypePerYear(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/advance-indicator/duty-type-per-year', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAgeRangePhysician(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/advance-indicator/physician/age-range-physician', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPhysicians(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/user/user-physician-active', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInstitutions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/institution', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWorkplaces(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/workplace/list-all-for-admin', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
