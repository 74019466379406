import axios from '@axios'
import Axios from 'axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getAddressByZipCode(ctx, { zipCode }) {
      return new Promise((resolve, reject) => {
        const code = zipCode.replace(/\D/g, '')
        Axios.get(`https://viacep.com.br/ws/${code}/json`)
          .then(({ data }) => {
            resolve({
              street: data.logradouro,
              neighborhood: data.bairro,
              complement: data.complemento,
              city: data.localidade,
              state: data.uf,
            })
          })
          .catch(reject)
      })
    },
    fetchWorkplaceByInstitution(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/workplace/list-by-institution', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInstitutions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/institution', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInstitution(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/institution/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createInstitution(ctx, institutionData) {
      return new Promise((resolve, reject) => {
        const {
          cnpj,
          phone,
          address,
        } = institutionData

        const postalCode = address.postal_code.replace(/\D/g, '')
        const complement = address.complement || ''
        const cashback = institutionData.cashback.replace(',', '.')
        const serviceFee = institutionData.service_fee?.replace(',', '.')
        const interest = institutionData.interest?.replace(',', '.')
        const limit = Math.floor(parseFloat(institutionData.limit.replace(',', '.')) * 100)

        const data = {
          ...institutionData,
          limit,
          cashback,
          service_fee: serviceFee,
          interest,
          cnpj: cnpj.replace(/\D/g, ''),
          phone: phone.replace(/\D/g, ''),
          address: { ...address, postal_code: postalCode, complement },
        }

        axios
          .post('/institution', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateInstitution(ctx, institutionData) {
      return new Promise((resolve, reject) => {
        const {
          id,
          cnpj,
          phone,
          address,
        } = institutionData

        const postalCode = address.postal_code.replace(/\D/g, '')
        const limit = Math.floor(parseFloat(institutionData.limit.replace(',', '.')) * 100)
        const cashback = institutionData.cashback.replace(',', '.')
        const serviceFee = institutionData.service_fee?.replace(',', '.')
        const interest = institutionData.interest?.replace(',', '.')

        const data = {
          ...institutionData,
          limit,
          cashback,
          service_fee: serviceFee,
          interest,
          cnpj: cnpj.replace(/\D/g, ''),
          phone: phone.replace(/\D/g, ''),
          address: { ...address, postal_code: postalCode },
        }

        axios
          .put(`/institution/${id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    enableForRequest(ctx, data) {
      return new Promise((resolve, reject) => {
        const { workplace_id, ...payload } = data
        axios
          // eslint-disable-next-line camelcase
          .put(`workplace/${workplace_id}/enable-for-request-and-person-type`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editContractEffectiveDate(ctx, data) {
      return new Promise((resolve, reject) => {
        const { workplace_id, contract_effective_date } = data
        axios
          // eslint-disable-next-line camelcase
          .put(`workplace/${workplace_id}/contract-effective-date`, { contract_effective_date })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editCanAdvance(ctx, data) {
      return new Promise((resolve, reject) => {
        const { workplace_id, can_advance } = data
        axios
          // eslint-disable-next-line camelcase
          .put(`workplace/${workplace_id}/can-advance`, { can_advance })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateLaunchMode(ctx, data) {
      return new Promise((resolve, reject) => {
        const { workplace_id, physician_duty_launch_mode } = data
        axios
          // eslint-disable-next-line camelcase
          .put(`workplace/${workplace_id}/physician-duty-launch-mode`, { physician_duty_launch_mode })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePayrollValue(ctx, data) {
      return new Promise((resolve, reject) => {
        const { workplace_id, payroll_value } = data
        axios
          // eslint-disable-next-line camelcase
          .put(`workplace/${workplace_id}/payroll-value`, { payroll_value })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStates(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/address/states', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCities(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/address/cities', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
