<template>
  <b-card>
    <h2>
      Processo
    </h2>

    <div>
      <h6 class="mt-2">
        Título
      </h6>
      <h5>{{ process.title }}</h5>
    </div>

    <div>
      <h6 class="mt-2">
        Descrição
      </h6>
      <h5>{{ process.description }}</h5>
    </div>

    <div>
      <h6 class="mt-2">
        Categoria
      </h6>
      <h5>{{ process.category }}</h5>
    </div>

    <div>
      <h6 class="mt-2">
        Tags
      </h6>
      <h5>{{ process.tags.join(',') }}</h5>
    </div>

    <div v-if="process.html_content">
      <h6 class="mt-2">
        HTML
      </h6>
      <h5>{{ process.html_content }}</h5>
    </div>

    <div>
      <h6 class="mt-2">
        Arquivo
      </h6>
      <a :href="process.file.download_link">
        {{ process.file.filename }}
        <feather-icon
          size="18"
          icon="DownloadIcon"
        />
      </a>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import businessProcessStoreModule from '@/views/business-process/businessProcessStoreModule'
import router from '@/router'

export default {
  components: {
    BCard,
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-business-process'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, businessProcessStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const process = ref({
      title: '',
      description: '',
      category: '',
      html_content: '',
      tags: [],
    })

    const { id } = router.currentRoute.params
    store.dispatch('app-business-process/fetchContract', { id })
      .then(response => {
        const p = response.data.data
        process.value = {
          ...p,
          tags: p.tags.split(','),
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          process.value = undefined
        }
      })

    return {
      process,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
