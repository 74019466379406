<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <b-table
        ref="refSyncErrorsTable"
        class="position-relative"
        :items="fetchSyncErrors"
        no-provider-sorting
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="Nenhum registro correspondente encontrado"
      >
        <template
          #cell(status_code)="{ item }"
        >
          <b-badge
            pill
            :variant="item.status_code === 200 ? 'light-success' : 'light-danger'"
          >
            {{ item.status_code }}
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              @click="openHistory(data.item)"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">Detalhes</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibir</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entradas</label>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-modal
      id="modal-history"
      ref="historyModal"
      :title="historyItem ? `Detalhes - ${historyItem.id}`: ''"
      hide-footer
      size="lg"
    >
      <template v-if="historyItem">
        <div class="mb-1">
          <h6 class="mb-0">
            Integration Code
          </h6>
          <div>{{ historyItem.integration_code }}</div>
        </div>
        <div class="mb-1">
          <h6 class="mb-0">
            API Key
          </h6>
          <div>{{ historyItem.api_key_id }}</div>
        </div>
        <div class="mb-1">
          <h6 class="mb-0">
            Endereço IP
          </h6>
          <div>{{ historyItem.ip_address }}</div>
        </div>
        <div class="mb-1">
          <h6 class="mb-0">
            URL
          </h6>
          <div>{{ historyItem.action }}</div>
        </div>
        <div class="mb-1">
          <h6 class="mb-0">
            Método HTTP
          </h6>
          <div>{{ historyItem.method }}</div>
        </div>
        <div class="mb-1">
          <h6 class="mb-0">
            Request
          </h6>
          <div>
            <!-- eslint-disable -->
            <prism language="json">{{ JSON.parse(historyItem.request_data) }}</prism>
            <!-- eslint-enable -->
          </div>
        </div>
        <div class="mb-1">
          <h6 class="mb-0">
            Response
          </h6>
          <div>
            Status Code:
            <b-badge
              pill
              :variant="historyItem.status_code === '200' ? 'light-success' : 'light-danger'"
            >
              {{ historyItem.status_code }}
            </b-badge>
            <!-- eslint-disable -->
            <prism language="json">{{ JSON.parse(historyItem.response_data) }}</prism>
            <!-- eslint-enable -->
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import {
  BCard, BTable, BDropdown, BDropdownItem, BModal, BBadge, BCol, BRow, BPagination,
} from 'bootstrap-vue'
import 'prismjs'
import 'prismjs/components/prism-json'
import 'prismjs/themes/prism-solarizedlight.css'
import Prism from 'vue-prism-component'
import { formatDateTimeDDMMYYHHMMSS } from '@core/utils/utils'
import vSelect from 'vue-select'
import useFailedPhysicianDutiesErrors from './failedPhysicianDutiesErrors'
import failedPhysicianDutiesErrorsModule from './failedPhysicianDutiesStoreModule'

export default {
  components: {
    vSelect,
    BPagination,
    BRow,
    BCol,
    BBadge,
    BCard,
    BTable,
    BDropdown,
    BDropdownItem,
    BModal,
    Prism,
  },
  directives: {
    Ripple,
  },
  setup() {
    const SYNC_ERRORS_APP_STORE_MODULE_NAME = 'app-failed-physician-duties-errors'
    // Register module
    if (!store.hasModule(SYNC_ERRORS_APP_STORE_MODULE_NAME)) store.registerModule(SYNC_ERRORS_APP_STORE_MODULE_NAME, failedPhysicianDutiesErrorsModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SYNC_ERRORS_APP_STORE_MODULE_NAME)) store.unregisterModule(SYNC_ERRORS_APP_STORE_MODULE_NAME)
    })

    const historyModal = ref(null)
    const historyItem = ref(null)

    const {
      perPage,
      currentPage,
      totalItems,
      perPageOptions,
      fetchSyncErrors,
      tableColumns,
      showImediato,
      refSyncErrorsTable,
      refetchData,
    } = useFailedPhysicianDutiesErrors()

    const contractHistory = () => store
      .dispatch(`${SYNC_ERRORS_APP_STORE_MODULE_NAME}/failedPhysicianDutiesErrors`)

    function openHistory(item) {
      historyItem.value = item
      historyModal.value.show()
    }

    return {
      perPage,
      currentPage,
      totalItems,
      perPageOptions,
      fetchSyncErrors,
      tableColumns,
      showImediato,
      refSyncErrorsTable,
      refetchData,
      openHistory,
      historyModal,
      historyItem,
      contractHistory,
      formatDateTimeDDMMYYHHMMSS,
    }
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';

    .contract_code  {
      white-space: nowrap;
      width: 100px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      background-color: #fff0;
      z-index: 1;
    }
    .contract_code:hover  {
      overflow: visible;
      white-space: inherit;
    }
</style>
