<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Adiantados x Limite</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0 d-flex">
        <b-button
          variant="outline-primary"
          class="action"
          @click="previous"
        >
          <feather-icon
            size="18"
            icon="ChevronLeftIcon"
          />
        </b-button>
        <div class="title">
          {{ currentDateFormatted }}
        </div>
        <b-button
          variant="outline-primary"
          class="action"
          :disabled="disabledNext"
          @click="next"
        >
          <feather-icon
            size="18"
            icon="ChevronRightIcon"
          />
        </b-button>
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body pt-0">
      <b-row>
        <b-table
          :items="duties"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="Nenhum registro"
          class="position-relative"
          :small="true"
        >
          <template
            #cell(institution_name)="{item}"
          >
            {{ item.institution_name }}
            <div class="font-small-2">
              {{ formatCnpj(item.cnpj) }}
            </div>
          </template>
        </b-table>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BButton, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BTable,
} from 'bootstrap-vue'
import {
  addMonths, format, isFuture, subMonths,
} from 'date-fns'
import ptBrLocale from 'date-fns/locale/pt-BR'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import homeStoreModule from '@/views/home/homeStoreModule'
import { formatCnpj, formatCurrencyInt, formatPercent } from '@core/utils/utils'

export default {
  components: {
    BTable,
    BRow,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BButton,
  },
  props: {

  },
  methods: { formatCnpj },
  setup() {
    const APP_MODULE_NAME = 'app-home'
    if (!store.hasModule(APP_MODULE_NAME)) store.registerModule(APP_MODULE_NAME, homeStoreModule)
    onUnmounted(() => {
      if (store.hasModule(APP_MODULE_NAME)) store.unregisterModule(APP_MODULE_NAME)
    })

    const date = ref(new Date())
    const disabledNext = ref(true)
    const currentDateFormatted = ref('')
    const duties = ref([])
    const tableColumns = [
      {
        key: 'institution_name',
        label: 'Instituição',
      },
      {
        key: 'total_amount',
        label: 'Lançado',
        formatter: value => formatCurrencyInt(value),
      },
      {
        key: 'total_amount_released',
        label: 'Adiantado',
        formatter: value => formatCurrencyInt(value),
      },
      {
        key: 'limit',
        label: 'Limite',
        formatter: value => formatCurrencyInt(value),
      },
      {
        key: 'percentage_limit_used',
        label: '%',
        formatter: value => formatPercent(value),
      },
    ]

    const refresh = () => {
      const dateMonth = format(date.value, 'yyyy-MM', { locale: ptBrLocale }).toUpperCase()
      store.dispatch('app-home/fetchPhysicianDuties', { date: dateMonth })
        .then(response => {
          duties.value = response.data.data.data
        })
    }
    refresh()

    const updateDate = () => {
      currentDateFormatted.value = format(date.value, 'MMMM yyyy', { locale: ptBrLocale }).toUpperCase()
    }
    updateDate()

    const previous = () => {
      date.value = subMonths(date.value, 1)
      disabledNext.value = false
      updateDate()
      refresh()
    }
    const next = () => {
      const currentDate = addMonths(date.value, 1)
      const futureDate = isFuture(currentDate)
      if (!futureDate) {
        date.value = currentDate
        refresh()
      } else {
        disabledNext.value = true
      }
      updateDate()
    }

    return {
      date,
      previous,
      next,
      disabledNext,
      currentDateFormatted,
      tableColumns,
      duties,
    }
  },
}
</script>

<style lang="scss">
  .card-header .title {
    min-width: 100px;
    text-align: center;
  }
  .action {
    padding: 2px 3px;
    margin-right: 5px;
    & ~ .action {
      margin-left: 5px;
    }
  }
  .card-statistics .card-body.statistics-body {
    padding-top: 0!important;
  }
</style>
