<template>
  <b-sidebar
    id="add-new-partner-sidebar"
    :visible="isAddNewPartnerSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-partner-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ partnerId ? 'Editar Parceiro' : 'Novo Parceiro' }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <validation-provider
            #default="validationContext"
            name="parceiro"
            rules="required"
          >
            <b-form-group
              label="Parceiro"
              label-for="title"
            >
              <b-form-input
                id="title"
                v-model="partnerData.title"
                placeholder="Parceiro..."
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="descrição"
            rules="required"
          >
            <b-form-group
              label="Descrição"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model="partnerData.description"
                rows="4"
                placeholder="Descrição..."
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="URL"
            rules="required"
          >
            <b-form-group
              label="URL"
              label-for="url"
            >
              <b-form-input
                id="url"
                v-model="partnerData.url"
                placeholder="https://..."
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!--          <validation-provider-->
          <!--            #default="validationContext"-->
          <!--            name="Imagem"-->
          <!--            :rules="isEdit ? required : null"-->
          <!--          >-->
          <!--            <b-form-group-->
          <!--              label="Imagem"-->
          <!--              label-for="image"-->
          <!--            >-->
          <!--              <b-form-file-->
          <!--                id="image"-->
          <!--                v-model="file"-->
          <!--                accept=".png"-->
          <!--                placeholder="Formato suportado: .png"-->
          <!--                drop-placeholder="Solte o arquivo aqui..."-->
          <!--                browse-text="Escolher imagem"-->
          <!--                :state="validationContext.touched ? getValidationState(validationContext) : null"-->
          <!--              />-->
          <!--              <b-form-invalid-feedback>-->
          <!--                {{ validationContext.errors[0] }}-->
          <!--              </b-form-invalid-feedback>-->
          <!--            </b-form-group>-->
          <!--          </validation-provider>-->
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                id="input-category"
                label="Categoria"
                label-for="category"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Categoria"
                  vid="category"
                  rules="required"
                >
                  <v-select
                    id="category"
                    v-model="partnerData.category"
                    name="category"
                    :options="categoryOptions"
                    :clearable="false"
                    :reduce="v => v.value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                id="input-status"
                label="Status"
                label-for="status"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Status"
                  vid="status"
                  rules="required"
                >
                  <v-select
                    id="status"
                    v-model="partnerData.active"
                    name="status"
                    :options="statusOptions"
                    :clearable="false"
                    :reduce="v => v.value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              class="mr-2"
              type="submit"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-primary"
              @click="hide"
            >
              Voltar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BCol, BRow, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, watch } from '@vue/composition-api'
import {
  required, alphaNum, email, cpf,
} from '@validations'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import formError from '@core/comp-functions/forms/form-error'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormTextarea,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BCol,
    BRow,

    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewPartnerSidebarActive',
    event: 'update:is-add-new-partner-sidebar-active',
  },
  props: {
    isAddNewPartnerSidebarActive: {
      type: Boolean,
      required: true,
    },
    partnerId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      cpf,
      countries: [],
    }
  },
  setup(props, { emit }) {
    const file = ref(null)
    const isEdit = ref(false)
    const blankPartnerData = {
      title: '',
      category: '',
      url: '',
      description: '',
      inactivate: false,
    }
    const statusOptions = ref([
      {
        value: true,
        label: 'Ativo',
      },
      {
        value: false,
        label: 'Inativo',
      },
    ])
    const categoryOptions = ref([])

    const partnerData = ref(JSON.parse(JSON.stringify(blankPartnerData)))
    const resetPartnerData = () => {
      partnerData.value = JSON.parse(JSON.stringify(blankPartnerData))
      file.value = null
    }

    function getPartner(id) {
      store
        .dispatch('app-partner/fetchPartner', { id })
        .then(response => {
          isEdit.value = true
          const { data } = response.data
          partnerData.value = {
            ...data,
            category: data.partnership_category.id,
            active: !data.inactivated_at,
          }
        })
    }

    function getCategories(id) {
      store
        .dispatch('app-partner/fetchPartnerCategories', { id })
        .then(response => {
          const { data } = response.data
          categoryOptions.value = data.map(c => ({
            value: c.id,
            label: c.title,
          }))
        })
    }
    getCategories()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetPartnerData)

    function onSubmit() {
      const partner = {
        id: partnerData.value.id,
        title: partnerData.value.title,
        url: partnerData.value.url,
        description: partnerData.value.description,
        active: partnerData.value.active,
        category: partnerData.value.category,
        // image: file.value,
      }
      store.dispatch(partner.id ? 'app-partner/updatePartner' : 'app-partner/addPartner', partner)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-partner-sidebar-active', false)
        })
        .catch(error => {
          const { setErrors, getErrorMessage } = formError(refFormObserver.value, error)
          setErrors()
          if (getErrorMessage()) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Erro',
                icon: 'XOctagonIcon',
                variant: 'danger',
                text: getErrorMessage(),
              },
            })
          }
        })
    }

    watch(() => props.isAddNewPartnerSidebarActive, show => {
      isEdit.value = false
      resetForm()
      resetPartnerData()
      if (show && props.partnerId) {
        getPartner(props.partnerId)
      }
    })

    return {
      partnerData,
      isEdit,
      file,
      onSubmit,
      categoryOptions,
      refFormObserver,
      getValidationState,
      resetForm,
      statusOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-partner-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
