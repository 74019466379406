<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="12"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="physicianData.avatar"
            :text="avatarText(physicianData.name)"
            :variant="`light-${resolvePhysicianRoleVariant(physicianData.role)}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1 justify-content-center">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ physicianData.name }}
              </h4>
              <span class="card-text">{{ physicianData.email }}</span>
            </div>
          </div>
        </div>

      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="12"
      >
        <table class="mt-2 mt-xl-1">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="HomeIcon"
                class="mr-75"
              />
              <span class="font-weight-bold pr-1">Instituição</span>
            </th>
            <td class="pb-50">
              {{ physicianData.institution.name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="Edit2Icon"
                class="mr-75"
              />
              <span class="font-weight-bold">Nome</span>
            </th>
            <td class="pb-50">
              {{ physicianData.name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Fone</span>
            </th>
            <td class="pb-50">
              <span v-if="physicianData.phone.length === 10">{{ physicianData.phone | VMask('(##) ####-####') }}</span>
              <span v-else>{{ physicianData.phone | VMask('(##) # ####-####') }}</span>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="MailIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">E-mail</span>
            </th>
            <td class="pb-50">
              {{ physicianData.email }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">CPF</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ physicianData.cpf | VMask('###.###.###-##') }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CalendarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Nascimento</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ physicianData.birthday ? formatDateTimeDDMMYYYY(physicianData.birthday) : '-' }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="PlusIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">CRM</span>
            </th>
            <td class="pb-50">
              {{ physicianData.crm }} - {{ physicianData.state.abbreviation }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CalendarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Criado em</span>
            </th>
            <td class="pb-50">
              {{ formatDateTimeDDMMYYHHMM(new Date(physicianData.created_at)) }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Criado por</span>
            </th>
            <td class="pb-50">
              {{ physicianData.created_by ? physicianData.created_by : '-' }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { formatDateTimeDDMMYYHHMM, formatDateTimeDDMMYYYY } from '@core/utils/utils'
import usePhysicianList from '../physician-list/usePhysicianList'

export default {
  components: {
    BCard, BRow, BCol, BAvatar,
  },
  props: {
    physicianData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { resolvePhysicianRoleVariant } = usePhysicianList()
    return {
      avatarText,
      resolvePhysicianRoleVariant,
      formatDateTimeDDMMYYHHMM,
      formatDateTimeDDMMYYYY,
    }
  },
}
</script>

<style>

</style>
