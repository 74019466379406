<template>
  <div>
    <h6>{{ getRateType(rate.type) }}</h6>
    <!-- Juros -->
    <validation-provider
      #default="validationContext"
      name="Juros"
      rules="required|between:0,100"
    >
      <b-form-group
        label="Juros"
        label-for="interest"
      >
        <b-input-group append="% a.m.">
          <cleave
            :id="'interest'+rate.id"
            v-model="rate.interest"
            autofocus
            class="form-control"
            :options="options.number"
            :raw="false"
            placeholder="0.05"
            maxlength="5"
          />
        </b-input-group>
        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>

    <!-- Multa -->
    <validation-provider
      #default="validationContext"
      name="Multa"
      rules="required|between:0,100"
    >
      <b-form-group
        label="Multa"
        label-for="fine"
      >
        <b-input-group append="%">
          <cleave
            :id="'fine'+rate.id"
            v-model="rate.fine"
            autofocus
            class="form-control"
            :options="options.number"
            placeholder="0.05"
            maxlength="5"
          />
        </b-input-group>
        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>

    <!-- Tx. Serviço -->
    <validation-provider
      #default="validationContext"
      name="Taxa De Serviço"
      rules="required|between:0,100"
    >
      <b-form-group
        label="Taxa de Serviço"
        label-for="service_fee"
      >
        <b-input-group append="%">
          <cleave
            :id="'service_fee'+rate.id"
            v-model="rate.service_fee"
            autofocus
            class="form-control"
            :options="options.number"
            :raw="false"
            placeholder="0.05"
            maxlength="5"
          />
        </b-input-group>
        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>
  </div>
</template>

<script>
import {
  BFormGroup, BFormInvalidFeedback, BInputGroup,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import {
  required,
  between,
} from '@validations'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BFormGroup,
    BFormInvalidFeedback,
    BInputGroup,
    Cleave,

    // Form Validation
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    rate: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      between,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'none',
          numeralDecimalMark: ',',
          numeralDecimalScale: 2,
          numeralPositiveOnly: true,
        },
      },
    }
  },
  setup() {
    function getRateType(type) {
      return type === 'loan' ? 'Empréstimo' : 'Adiantamento'
    }

    return {
      getRateType,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-rate-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
