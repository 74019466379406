<template>
  <b-card
    no-body
    class="border-primary"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-1">
        Endereço
      </h5>
    </b-card-header>

    <b-card-body>
      <table class="mt-2 mt-xl-0">
        <tr>
          <th class="pr-3">
            <span class="font-weight-bold">Logradouro</span>
          </th>
          <td>
            {{ address.street }}
          </td>
        </tr>
        <tr>
          <th>
            <span class="font-weight-bold">Número</span>
          </th>
          <td>
            {{ address.number }}
          </td>
        </tr>
        <tr>
          <th>
            <span class="font-weight-bold">CEP</span>
          </th>
          <td>
            {{ address.postal_code }}
          </td>
        </tr>
        <tr>
          <th>
            <span class="font-weight-bold">Bairro</span>
          </th>
          <td>
            {{ address.neighborhood }}
          </td>
        </tr>
        <tr>
          <th>
            <span class="font-weight-bold">Complemento</span>
          </th>
          <td>
            {{ address.complement }}
          </td>
        </tr>
        <tr>
          <th>
            <span class="font-weight-bold">Cidade</span>
          </th>
          <td>
            {{ address.city.name }}
          </td>
        </tr>
        <tr>
          <th>
            <span class="font-weight-bold">Estado</span>
          </th>
          <td>
            {{ address.city.state.name }}
          </td>
        </tr>
      </table>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
  },
  props: {
    institutionData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    address() {
      return this.institutionData && this.institutionData.address && this.institutionData.address[0]
    },
  },
}
</script>

<style>

</style>
