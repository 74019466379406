<template>
  <div>
    <audit-header :audit="audit" />
    <hr>
    <h4 class="text-capitalize mt-2">
      Pessoal
    </h4>

    <div>
      <h6 class="text-capitalize mb-0">
        Nome completo
      </h6>
      <div>{{ value.alias }}</div>
      <div class="text-muted font-small-2">
        {{ value.user.name }}
      </div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        E-mail
      </h6>
      <div>{{ value.user.email }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        CPF
      </h6>
      <div>{{ formatCnpj(value.user.cpf) }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        Nascimento
      </h6>
      <div>{{ value.birthday ? formatDateTimeDDMMYYYY(value.birthday) : '-' }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        CRM
      </h6>
      <div>{{ value.crm }} {{ value.state.abbreviation }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        Telefone
      </h6>
      <div>{{ formatPhone(value.phone) }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        Com restrição? <span class="font-weight-normal">{{ value.schedule_restriction ? 'Sim' : 'Não' }}</span>
      </h6>
      <div>{{ value.schedule_restriction_reason }}</div>
    </div>

    <h4 class="text-capitalize mt-2">
      Dados Bancários
    </h4>
    <div
      v-for="account in accounts"
      :key="account.id"
    >
      <div>
        <h6 class="text-capitalize mb-0">
          Banco
        </h6>
        <div>{{ account.financial_institution.bank_code }} - {{ value.account[0].financial_institution.name }}</div>
      </div>
      <div>
        <h6 class="text-capitalize mb-0">
          Tipo
        </h6>
        <div>{{ account.account_type === "savings_account" ? 'Poupança' : 'Corrente' }}</div>
      </div>
      <div>
        <h6 class="text-capitalize mb-0">
          Nº Agência
        </h6>
        <div>{{ account.branch_number }}</div>
      </div>
      <div>
        <h6 class="text-capitalize mb-0">
          N° da Conta
        </h6>
        <div>{{ account.account_number }}</div>
      </div>
      <div>
        <h6 class="text-capitalize mb-0">
          Dígito da Conta
        </h6>
        <div>{{ account.account_code }}</div>
      </div>
    </div>

    <h4 class="text-capitalize mt-2">
      Endereço
    </h4>
    <div>
      <h6 class="text-capitalize mb-0">
        CEP
      </h6>
      <div>{{ address ? formatCep(address.postal_code) : '-' }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        Cidade
      </h6>
      <div v-if="address">
        {{ address.city.name }} - {{ address.city.state.abbreviation }}
      </div>
      <div v-else>
        -
      </div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        Bairro
      </h6>
      <div>{{ address ? address.neighborhood : '-' }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        Rua
      </h6>
      <div>{{ address ? address.street : '-' }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        N°
      </h6>
      <div>{{ address ? address.number: '-' }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        Complemento
      </h6>
      <div>{{ address ? (address.complement || '-') : '-' }}</div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { format } from 'date-fns'
import {
  formatCep, formatCnpj, formatCurrency, formatPhone, formatDateTimeDDMMYYYY,
} from '@core/utils/utils'
import AuditHeader from './Header.vue'

export default {
  components: {
    AuditHeader,
  },
  props: {
    audit: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const value = ref(props.audit.current_values)
    const user = ref(props.audit.user)
    const address = ref(null)
    const createdAt = ref(props.audit.created_at)
    const accounts = []
    if (Array.isArray(value.value.account)) {
      accounts.push(...value.value.account)
    }
    if (value.value.address) {
      address.value = value.value.address
    }

    return {
      address,
      accounts,
      formatCep,
      formatCnpj,
      formatPhone,
      formatCurrency,
      value,
      user,
      createdAt,
      formatDateTimeDDMMYYYY,
      formatDate: format,
    }
  },
}
</script>

<style lang="scss" scoped>
hr {
  margin-top: 4px;
  margin-bottom: 10px;
}
h6 {
  margin-top: 3px;
}
</style>
