var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v("Exibir")]),_c('v-select',{staticClass:"per-page-selector d-inline-block mx-50",attrs:{"options":_vm.perPageOptions,"clearable":false},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}}),_c('label',[_vm._v("entradas")])],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"placeholder":"Buscar...","debounce":"500"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('b-button',{attrs:{"variant":"primary","to":{ name: 'business-process-create' }}},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Novo Processo ")])])],1)])],1)],1),_c('b-table',{ref:"refInstitutionListTable",staticClass:"position-relative",attrs:{"items":_vm.fetchBusinessProcesses,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","sort-by":_vm.sortBy,"show-empty":"","empty-text":"Nenhum registro correspondente encontrado","sort-desc":_vm.isSortDirDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"cell(view)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate overflow-hidden",staticStyle:{"max-width":"150px"},attrs:{"title":item.category}},[_c('a',{attrs:{"href":item.file.download_link,"target":"_blank"}},[_c('feather-icon',{attrs:{"size":"18","icon":"DownloadIcon"}}),_vm._v(" "+_vm._s(item.file.filename)+" ")],1)])]}},{key:"cell(category)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate overflow-hidden",staticStyle:{"max-width":"250px"},attrs:{"title":item.category}},[_vm._v(" "+_vm._s(item.category)+" ")])]}},{key:"cell(title)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate overflow-hidden",staticStyle:{"max-width":"250px"},attrs:{"title":item.title}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"cell(description)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate overflow-hidden",staticStyle:{"max-width":"200px"},attrs:{"title":item.description}},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"cell(actions)",fn:function(data){return [_c('b-dropdown',{attrs:{"variant":"link","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":{ name: 'business-process-view', params: { id: data.item.id } }}},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Detalhes")])],1),_c('b-dropdown-item',{attrs:{"to":{ name: 'business-process-edit', params: { id: data.item.id } }}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Editar")])],1)],1)]}}])}),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v("Exibindo "+_vm._s(_vm.dataMeta.from)+" a "+_vm._s(_vm.dataMeta.to)+" de "+_vm._s(_vm.dataMeta.of)+" entradas")])]),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalInstitutions,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }