<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="12"
        xl="12"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <div class="d-flex flex-column">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ institutionData.fancy_name }}
              </h4>
              <span class="card-text">{{ institutionData.name }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                v-if="$can('read', 'Institution')"
                :to="{ name: 'institution-edit', params: { id: institutionData.id } }"
                variant="primary"
              >
                <feather-icon
                  icon="EditIcon"
                  size="16"
                />
                Editar
              </b-button>
            </div>
          </div>
        </div>

        <!--        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="DollarSignIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                100k
              </h5>
              <small>L. Crédito</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="TrendingUpIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                90.87k
              </h5>
              <small>Emprestado</small>
            </div>
          </div>
        </div>-->
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        class="mt-1"
        cols="12"
        xl="12"
      >
        <table class="mt-2 mt-xl-0">
          <tr>
            <th class="pr-3">
              <feather-icon
                icon="HomeIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">CNPJ</span>
            </th>
            <td>
              {{ institutionData.cnpj }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="MailIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">E-mail</span>
            </th>
            <td>
              {{ institutionData.email }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Contato</span>
            </th>
            <td>
              {{ institutionData.contact }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Fone</span>
            </th>
            <td>
              {{ institutionData.phone }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Status</span>
            </th>
            <td>
              {{ institutionData.inactivated_at }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BRow, BCol,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard, BButton, BRow, BCol,
  },
  props: {
    institutionData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>

</style>
