import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import { ref, watch } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { formatDateTime, formatDateTimeDDMMYYHHMM } from '@core/utils/utils'

export default function useSyncErrors() {
  const refSyncErrorsTable = ref(null)
  const toast = useToast()

  const tableColumns = [
    {
      key: 'contract_code', sortable: true, sortKey: 'contract_code', label: 'CONTRATO',
    },
    {
      key: 'contract_id', sortable: true, sortKey: 'physician_name', label: 'Contrato ID',
    },
    { key: 'physician_name', sortable: true, label: 'Médico' },
    { key: 'anteciped_at', sortable: true, label: 'Antecipado em' },
    { key: 'institution_name', sortable: true, label: 'Empresa' },
    { key: 'workplace_name', sortable: true, label: 'Unidade' },
    { key: 'shift', label: 'Plantão' },
    { key: 'actions', label: 'Ações' },
  ]

  const showImediato = ref(false)

  const refetchData = () => {
    refSyncErrorsTable.value.refresh()
  }

  watch([showImediato], () => {
    refetchData()
  })

  const fetchSyncErrors = (ctx, callback) => {
    store
      .dispatch('app-sync-errors/getSyncErrors', { show_imediato: showImediato.value ? 1 : 0 })
      .then(response => {
        const { data } = response.data
        const syncErrors = data.map(syncError => {
          const error = { ...syncError }
          const beginDate = formatDateTimeDDMMYYHHMM(syncError.start_date)
          const endDate = formatDateTimeDDMMYYHHMM(syncError.end_date)
          error.shift = `${beginDate} - ${endDate}`
          error.anteciped_at = formatDateTime(syncError.anteciped_at)
          return error
        })
        callback(syncErrors)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao buscar',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const reSync = ({ contractId }) => {
    store
      .dispatch('app-sync-errors/omieReSync', { contractId })
      .then(() => {
        refetchData()
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro na request OMIE',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchSyncErrors,
    reSync,
    refSyncErrorsTable,
    tableColumns,
    showImediato,
    refetchData,
  }
}
