<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-1">
          <b-img
            class="brand-image"
            :src="logoUrl"
            alt="Login"
          />
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <forgot-password-step1
            v-if="step === 0"
            :user="userData"
            @step="nextStep"
          />

          <forgot-password-step2
            v-if="step === 1"
            :user="userData"
            @step="nextStep"
          />

          <forgot-password-step3
            v-if="step === 2"
            :user="userData"
          />

          <p class="text-center mt-2">
            <b-link :to="{name:'login'}">
              <feather-icon icon="ChevronLeftIcon" /> Voltar ao login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow, BCol, BLink, BImg,
} from 'bootstrap-vue'
import {
  required, email, digits, password, confirmed,
} from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store'
import ForgotPasswordStep1 from './ForgotPasswordStep1.vue'
import ForgotPasswordStep2 from './ForgotPasswordStep2.vue'
import ForgotPasswordStep3 from './ForgotPasswordStep3.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    ForgotPasswordStep1,
    ForgotPasswordStep2,
    ForgotPasswordStep3,
  },
  mixins: [togglePasswordVisibility],
  props: {
    seedEmail: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      step: 0,
      userData: {
        email: this.seedEmail,
        code: null,
        password: null,
        password_confirmation: null,
      },
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
      digits,
      password,
      confirmed,
    }
  },
  computed: {
    logoUrl() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties,global-require
      return require('@/assets/images/logo/logo-2.svg')
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    nextStep(step) {
      this.step = step
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.brand-image {
  width: 130px;
}
</style>
