<template>
  <e-charts
    ref="line"
    autoresize
    :option="option"
    theme="theme-color"
    auto-resize
    :update-options="{notMerge:true}"
  />
</template>

<script>
// eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies
import { ref, toRef, watch } from '@vue/composition-api'
import ECharts from 'vue-echarts'
import {
  use,
  registerTheme,
} from 'echarts/core'
import {
  BarChart,
  LineChart,
  PieChart,
  LinesChart,
} from 'echarts/charts'
import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  ToolboxComponent,
  DataZoomComponent,
} from 'echarts/components'
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers'
import {
  formatCurrency, formatPercent, truncateString,
} from '@core/utils/utils'
import theme from '../theme.json'

use([
  BarChart,
  LineChart,
  PieChart,
  LinesChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  CanvasRenderer,
  SVGRenderer,
  ToolboxComponent,
  DataZoomComponent,
])
registerTheme('theme-color', theme)

export default {
  components: {
    ECharts,
  },
  props: {
    mobile: {
      type: Object,
      default: () => ({ mobile: false }),
    },
    year: {
      type: Number,
      default: 0,
    },
    chartData: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const chartData = toRef(props, 'chartData')
    // const mobile = toRef(props, 'mobile')

    const year = toRef(props, 'year')
    const option = ref({
      title: {
        text: '',
        subtext: '',
      },
      tooltip: {
        trigger: 'item',
        formatter: data => `<b style="color:${data.color}">${data.data.name}</b><br/>${formatCurrency(data.value)}<br/><b>${formatPercent(data.percent)}</b>`,
      },
      grid: {
        right: 0,
      },
      legend: {
        bottom: 0,
        left: 'center',
        data: [],
        formatter: name => truncateString(name, 20),
        tooltip: {
          show: true,
        },
      },
      series: [
        {
          type: 'pie',
          radius: '65%',
          center: ['50%', '50%'],
          selectedMode: 'single',
          data: [],
          label: {
            formatter: ({ percent }) => formatPercent(percent),
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    })

    const getName = (i, ranges, range) => {
      if (i === ranges.length - 1) { return `${range.min}-Acima` }
      return (range.min === 0 ? 'Não informado' : `${range.min}-${range.max}`)
    }

    watch(chartData, data => {
      if (!data || Object.keys(data).length === 0) return

      const years = Object.keys(data)
      const ranges = years.length > 0 ? data[years[0]].range_age : []

      option.value.title.text = `Vendas anuais em ${year.value}`
      option.value.title.subtext = 'por faixa etária'
      option.value.series[0].data = ranges.map((range, i) => ({
        value: range.value / 100,
        name: getName(i, ranges, range),
        colorBy: 'data',
      }))
      option.value.legend.data = ranges.map((range, i) => getName(i, ranges, range))
    }, { deep: true })

    return {
      option,
    }
  },
}
</script>
