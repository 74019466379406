<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <div class="d-flex align-items-center date-interval-picker">
          <b-button
            variant="outline-primary"
            class="action d-none d-md-inline-block mt-2 mr-1"
            :disabled="disabledPrevious || loading"
            title="Voltar 1 ano"
            @click="previous"
          >
            <feather-icon
              size="18"
              icon="ChevronLeftIcon"
            />
          </b-button>
          <div>
            <label for="workplace-id">De</label>
            <v-select
              v-model="lastYearDate"
              :options="yearOptions"
              :clearable="false"
              class="year-picker"
              :disabled="loading"
            />
          </div>
          <div class="ml-1">
            <label for="workplace-id">Até Ano</label>
            <v-select
              v-model="baseYearDate"
              :options="yearOptions"
              :clearable="false"
              class="year-picker"
              :disabled="loading"
            />
          </div>
          <b-button
            variant="outline-primary"
            class="action d-none d-md-inline-block mt-2 ml-1"
            title="Avançar 1 ano"
            :disabled="loading"
            @click="next"
          >
            <feather-icon
              size="18"
              icon="ChevronRightIcon"
            />
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        xs="12"
        xl="6"
        style="min-height: 500px"
      >
        <total-year-bar
          :mobile="mobile"
          :chart-data="chartData"
        />
      </b-col>
      <b-col
        cols="12"
        xs="12"
        xl="6"
        style="min-height: 500px"
      >
        <average-year-bar
          :mobile="mobile"
          :chart-data="chartData"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        xs="12"
        xl="12"
        style="min-height: 500px"
      >
        <sale-in-month-bar
          :mobile="mobile"
          :chart-data="chartData"
        />
      </b-col>
      <b-col
        cols="12"
        xs="12"
        xl="12"
        style="min-height: 500px"
      >
        <sales-evolution-line
          :mobile="mobile"
          :chart-data="chartData"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCol, BRow, BButton,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, watch } from '@vue/composition-api'
import { addYears } from 'date-fns'
import AverageYearBar from '@/views/charts/general/AverageYearBar.vue'
import TotalYearBar from '@/views/charts/general/TotalYearBar.vue'
import SaleInMonthBar from '@/views/charts/general/SaleInMonthBar.vue'
import SalesEvolutionLine from '@/views/charts/general/SalesEvolutionLine.vue'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    SalesEvolutionLine,
    SaleInMonthBar,
    TotalYearBar,
    AverageYearBar,
    BRow,
    BCol,
    BButton,
  },
  props: {
    mobile: {
      type: Object,
      default: () => ({ mobile: false }),
    },
  },
  setup() {
    const currentYear = new Date().getFullYear()
    const getYear = (sub = 0) => addYears(new Date(), sub).getFullYear()

    const yearOptions = []
    const lastYearDate = ref(getYear(-1))
    const baseYearDate = ref(getYear())
    const loading = ref(true)
    const disabledNext = ref(true)
    const disabledPrevious = ref(false)
    const currentDateFormatted = ref('')
    const items = ref([])
    const chartData = ref(null)

    const countYears = currentYear - 2022
    new Array(countYears + 1).fill(0).forEach((_, i) => {
      yearOptions.push(2022 + i)
    })

    const refresh = () => {
      loading.value = true
      const baseYear = baseYearDate.value
      const lastYear = lastYearDate.value

      store.dispatch('app-chart/fetchGeneralAdvancePerYear', {
        base_year: baseYear,
        last_year: lastYear,
      })
        .then(response => {
          chartData.value = response.data.data || {}
        }).finally(() => {
          loading.value = false
        })
    }
    refresh()

    watch([baseYearDate, lastYearDate], () => {
      refresh()
    })

    const previous = () => {
      const year = lastYearDate.value - 1
      if (year <= 2022) {
        disabledPrevious.value = true
      } else {
        disabledPrevious.value = false
      }
      if (year < 2022) {
        return
      }

      lastYearDate.value = year
      baseYearDate.value -= 1
      refresh()
    }
    const next = () => {
      const year = baseYearDate.value + 1
      if (year <= currentYear) {
        lastYearDate.value += 1
        baseYearDate.value += 1
        disabledPrevious.value = false
        refresh()
      }
    }

    return {
      loading,
      yearOptions,
      items,
      baseYearDate,
      lastYearDate,
      previous,
      next,
      disabledNext,
      disabledPrevious,
      currentDateFormatted,
      chartData,
    }
  },
}
</script>

<style lang="scss" scoped>
.v-text-field--box .v-input__slot, .v-text-field--outline .v-input__slot{
  max-height: 56px!important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select";
@import '~@core/scss/base/bootstrap-extended/include';

.date-interval-picker label {
  margin-bottom: 0 !important;
  margin-top: 0.2857rem !important;
}

@media (min-width: (map-get($grid-breakpoints, md))) {
  .date-interval-picker label {
    margin-bottom: 0.2857rem !important;
    margin-top: 0 !important;
  }
}

.card-body{
  position: relative;
  .pie-text{
    width: 105px;
    position:absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 44%;
    bottom: 0;
  }
  }

.card-header .title {
  min-width: 45px;
  text-align: center;
}
.action {
  padding: 2px 3px;
  margin-right: 5px;
  & ~ .action {
    margin-left: 5px;
  }
}
.year-picker {
  min-width: 100px;
}
</style>
