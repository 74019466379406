import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { parseJSON, format } from 'date-fns'

export default function useContractList() {
  // Use toast
  const toast = useToast()

  const refContractListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'type', label: 'Tipo', sortKey: 'type',
    },
    { key: 'description', label: 'Descrição' },
    { key: 'user_name', sortable: true, label: 'Criado' },
    {
      key: 'created_at',
      sortable: true,
      label: 'Criação',
      formatter: createdAt => format(parseJSON(createdAt), 'dd/MM/yy HH:mm'),
    },
    { key: 'active', label: 'Status' },
    { key: 'actions', label: 'Ações' },
  ]
  const perPage = ref(10)
  const totalContracts = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('created_at')
  const isSortDirDesc = ref(true)
  const situationFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refContractListTable.value ? refContractListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalContracts.value,
    }
  })

  const refetchData = () => {
    refContractListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, situationFilter, statusFilter], () => {
    refetchData()
  })

  const fetchContracts = (ctx, callback) => {
    store
      .dispatch('app-contract/fetchContracts', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDirection: isSortDirDesc.value ? 'desc' : 'asc',
      })
      .then(response => {
        callback(response.data.data)
        totalContracts.value = response.data.meta.pagination.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao buscart',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveContractRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveContractRoleIcon = role => {
    if (role === 'subscriber') return 'ContractIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'ContractIcon'
  }

  const resolveContractStatusVariant = active => {
    if (active) return 'success'
    return 'primary'
  }

  const resolveContractType = type => {
    if (type === 'loan') return 'Empréstimo'
    if (type === 'general_term') return 'Termo Geral'
    if (type === 'advance_payment') return 'Adiantamento'
    if (type === 'advance_payment_imediato') return 'Adiantamento (Imediato)'
    if (type === 'advance_payment_imediato_pj') return 'Adiantamento PJ (Imediato)'
    return type
  }

  return {
    fetchContracts,
    tableColumns,
    perPage,
    currentPage,
    totalContracts,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refContractListTable,

    resolveContractRoleVariant,
    resolveContractRoleIcon,
    resolveContractStatusVariant,
    resolveContractType,
    refetchData,

    // Extra Filters
    situationFilter,
    statusFilter,
  }
}
