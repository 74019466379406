<template>
  <b-button
    class="mb-2 bg-white"
    pill
    :variant="variant"
    @click="back"
  >
    <feather-icon
      icon="ArrowLeftIcon"
      size="15"
    />
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
  props: {
    variant: {
      type: String,
      default: 'outline-primary',
    },
  },
  methods: {
    back() {
      this.$router.back()
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
