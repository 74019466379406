<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibir</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entradas</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
                debounce="500"
              />
              <b-button
                variant="primary"
                :to="{ name: 'business-process-create' }"
              >
                <span class="text-nowrap">
                  Novo Processo
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refInstitutionListTable"
        class="position-relative"
        :items="fetchBusinessProcesses"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum registro correspondente encontrado"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(view)="{ item }">
          <div
            class="text-truncate overflow-hidden"
            style="max-width: 150px"
            :title="item.category"
          >
            <a
              :href="item.file.download_link"
              target="_blank"
            >

              <feather-icon
                size="18"
                icon="DownloadIcon"
              />
              {{ item.file.filename }}
            </a>
          </div>

        </template>

        <template #cell(category)="{ item }">
          <div
            class="text-truncate overflow-hidden"
            style="max-width: 250px"
            :title="item.category"
          >
            {{ item.category }}
          </div>
        </template>

        <template #cell(title)="{ item }">
          <div
            class="text-truncate overflow-hidden"
            style="max-width: 250px"
            :title="item.title"
          >
            {{ item.title }}
          </div>
        </template>

        <template #cell(description)="{ item }">
          <div
            class="text-truncate overflow-hidden"
            style="max-width: 200px"
            :title="item.description"
          >
            {{ item.description }}
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{ name: 'business-process-view', params: { id: data.item.id } }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">Detalhes</span>
            </b-dropdown-item>
            <b-dropdown-item
              :to="{ name: 'business-process-edit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalInstitutions"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination, BButton, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useBusinessProcessList from './useBusinessProcessList'
import businessProcessStoreModule from '../businessProcessStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BButton,
    BDropdown,
    BDropdownItem,

    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-business-process'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, businessProcessStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      fetchBusinessProcesses,
      tableColumns,
      perPage,
      currentPage,
      totalInstitutions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInstitutionListTable,
      refetchData,

      // UI
      resolveInstitutionRoleVariant,
      resolveInstitutionRoleIcon,
      resolveInstitutionStatusVariant,
      resolveInstitutionStatusTitle,

      // Extra Filters
      situationFilter,
      planFilter,
      statusFilter,
    } = useBusinessProcessList()

    return {

      fetchBusinessProcesses,
      tableColumns,
      perPage,
      currentPage,
      totalInstitutions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInstitutionListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveInstitutionRoleVariant,
      resolveInstitutionRoleIcon,
      resolveInstitutionStatusVariant,
      resolveInstitutionStatusTitle,

      // Extra Filters
      situationFilter,
      planFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
