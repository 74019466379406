<template>
  <div>
    <physician-list-add-new
      :is-add-new-physician-sidebar-active.sync="isAddNewPhysicianSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <physician-list-filters
          :per-page.sync="perPage"
          :per-page-options="perPageOptions"
          :classification-filter.sync="classificationFilter"
          :classification-options="classificationOptions"
          :situation-filter.sync="situationFilter"
          :status-filter.sync="statusFilter"
          :status-options="statusOptions"
          :download-report="downloadReport"
          :search-query.sync="searchQuery"
          :is-add-new-physician-sidebar-active.sync="isAddNewPhysicianSidebarActive"
        />
      </div>

      <b-table
        ref="refPhysicianListTable"
        class="position-relative"
        :items="fetchPhysicians"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum registro correspondente encontrado"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.name)"
                :variant="`light-${resolvePhysicianRoleVariant(data.item.situation)}`"
                :to="{ name: 'physician-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'physician-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              <div class="truncate">
                {{ data.item.name }}
              </div>
            </b-link>
            <small class="text-muted text-truncate">{{ data.item.id }}</small>
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolvePhysicianStatusVariant(data.item.confirmed_at)}`"
            class="text-capitalize"
          >
            {{ data.item.confirmed_at ? 'Ativo' : 'Inativo' }}
          </b-badge>
          <span
            :title="data.item.classification_label"
          >
            <feather-icon
              icon="SmartphoneIcon"
              size="20"
              :color="getClassificationColor(data.item.classification_color)"
            />
          </span>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="!data.item.confirmed_at"
              @click="openInviteModal(data.item, false)"
            >
              <feather-icon icon="CopyIcon" />
              <span class="align-middle ml-50">Convite (curto)</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="!data.item.confirmed_at"
              @click="openInviteModal(data.item, true)"
            >
              <feather-icon icon="CopyIcon" />
              <span class="align-middle ml-50">Convite (completo)</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalPhysicians"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal
      id="modal-invite"
      ref="inviteModal"
      title="Convite"
      hide-footer
    >
      <b-card-text v-if="invitePhysician">
        Link de convite para <h5>{{ invitePhysician.name }}</h5>
      </b-card-text>
      <b-form-group>
        <b-input-group>
          <b-form-input
            id="invite-link"
            v-model="inviteLink"
            placeholder="Gerando..."
            :disabled="!inviteLink"
          />
          <b-input-group-append>
            <b-tooltip
              ref="tooltip-hide"
              target="tooltip-button-hide-event"
              triggers="click"
            >
              Copiado!
            </b-tooltip>
            <b-button
              id="tooltip-button-hide-event"
              variant="outline-primary"
              :disabled="!inviteLink"
              @click="copyInvite"
            >
              <feather-icon
                icon="CopyIcon"
              />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BModal,
  VBModal,
  BFormGroup,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BTooltip,
  BDropdown,
  BDropdownItem,
  BPagination,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import PhysicianListFilters from './PhysicianListFilters.vue'
import usePhysicianList from './usePhysicianList'
import physicianStoreModule from '../../physicianStoreModule'
import PhysicianListAddNew from './PhysicianListAddNew.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BModal,
    BFormGroup,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BTooltip,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,

    PhysicianListAddNew,
    PhysicianListFilters,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  props: {
    institutionId: {
      type: String,
      required: true,
    },
  },
  setup({ institutionId }) {
    const PHYSICIAN_APP_STORE_MODULE_NAME = 'app-physician'

    // Register module
    if (!store.hasModule(PHYSICIAN_APP_STORE_MODULE_NAME)) store.registerModule(PHYSICIAN_APP_STORE_MODULE_NAME, physicianStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PHYSICIAN_APP_STORE_MODULE_NAME)) store.unregisterModule(PHYSICIAN_APP_STORE_MODULE_NAME)
    })

    const isAddNewPhysicianSidebarActive = ref(false)
    const inviteModal = ref(null)
    const invitePhysician = ref(null)
    const inviteLink = ref('')
    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const paymentStatusOptions = [
      { label: 'Nunca Usado', value: 'nunca' },
      { label: 'Regular', value: 'regular' },
      { label: 'Devedor', value: 'devedor' },
      { label: 'Inadimplente', value: 'inadimplente' },
    ]

    const statusOptions = [
      { label: 'Ativo', value: '1' },
      { label: 'Inativo', value: '0' },
    ]

    const generateConfirmationUrl = ({ id }, isFull) => {
      const url = isFull ? 'generateConfirmationUrlFull' : 'generateConfirmationUrl'
      return store.dispatch(`${PHYSICIAN_APP_STORE_MODULE_NAME}/${url}`, {
        id, institutionId,
      }).then(resp => {
        inviteLink.value = resp.data.data.url
      }).catch(() => {
        inviteLink.value = 'Erro!'
      })
    }

    const openInviteModal = (physician, isFull = false) => {
      invitePhysician.value = physician
      inviteLink.value = ''
      generateConfirmationUrl(physician, isFull)
      inviteModal.value.show()
    }

    const copyInvite = () => {
      const copyText = document.getElementById('invite-link')
      copyText.select()
      copyText.setSelectionRange(0, 99999)
      navigator.clipboard.writeText(copyText.value)
    }

    const getClassificationColor = color => {
      const colors = {
        red: '#df7273',
        yellow: '#ffb976',
        green: '#48da89',
      }
      return colors[color]
    }

    const {
      fetchPhysicians,
      tableColumns,
      perPage,
      currentPage,
      totalPhysicians,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPhysicianListTable,
      refetchData,

      // UI
      resolvePhysicianRoleVariant,
      resolvePhysicianRoleIcon,
      resolvePhysicianStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      institutionFilter,

      situationFilter,
      statusFilter,
      classificationFilter,
      classificationOptions,
      downloadReport,
    } = usePhysicianList()

    institutionFilter.value = institutionId

    return {

      isAddNewPhysicianSidebarActive,

      fetchPhysicians,
      tableColumns,
      perPage,
      currentPage,
      totalPhysicians,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPhysicianListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolvePhysicianRoleVariant,
      resolvePhysicianRoleIcon,
      resolvePhysicianStatusVariant,

      roleOptions,
      planOptions,
      paymentStatusOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      institutionFilter,
      situationFilter,
      statusFilter,
      classificationFilter,
      classificationOptions,
      downloadReport,

      generateConfirmationUrl,
      openInviteModal,
      inviteModal,
      copyInvite,
      inviteLink,
      invitePhysician,
      getClassificationColor,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.physician-class {
  width: 15px;
  height: 15px;
  border-spacing: 0!important;
  margin-top: 2px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
