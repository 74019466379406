<template>
  <b-card
    no-body
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-1">
        CNPJ Vinculados
      </h5>
    </b-card-header>

    <b-card-body>
      <div v-if="!physicianData.companies.length">
        Nenhum
      </div>
      <div
        v-for="company of physicianData.companies"
        :key="company.id"
      >
        <h6>{{ formatCnpj(company.cnpj) }}</h6>
        <div>{{ company.name }}</div>
        <hr>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { formatCnpj } from '../../../@core/utils/utils'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
  },
  props: {
    physicianData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    address() {
      if (this.physicianData && this.physicianData.address && !Array.isArray(this.physicianData.address)) {
        return this.physicianData.address
      }
      return {
        city: {
          name: '',
          state: { name: '' },
        },
      }
    },
  },
  methods: { formatCnpj },
}
</script>

<style>

</style>
