<template>
  <e-charts
    ref="line"
    autoresize
    :option="option"
    theme="theme-color"
    auto-resize
    :update-options="{notMerge:true}"
  />
</template>

<script>
import ECharts from 'vue-echarts'
import { ref, toRef, watch } from '@vue/composition-api'
import { registerTheme, use } from 'echarts/core'
import {
  BarChart, LineChart, LinesChart, PieChart,
} from 'echarts/charts'
import {
  DatasetComponent,
  DataZoomComponent,
  GridComponent,
  LegendComponent,
  LegendScrollComponent,
  MarkLineComponent,
  MarkPointComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent,
} from 'echarts/components'
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers'
import { formatCurrencyNonDecimal } from '@core/utils/utils'
import theme from '../theme.json'

use([
  BarChart,
  LineChart,
  PieChart,
  LinesChart,
  LegendScrollComponent,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  CanvasRenderer,
  SVGRenderer,
  ToolboxComponent,
  DataZoomComponent,
  MarkLineComponent,
  MarkPointComponent,
])
registerTheme('theme-color', theme)

export default {
  components: {
    ECharts,
  },
  props: {
    mobile: {
      type: Object,
      default: () => ({ mobile: false }),
    },
    chartData: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const chartData = toRef(props, 'chartData')
    const mobile = toRef(props, 'mobile')
    const option = ref({})

    watch([chartData, mobile], ([data]) => {
      if (!data.value_month) return
      const years = Object.keys(data.value_month).sort()
      const dimensions = years.map(year => (`${year}`))
      const series = years.map(() => ({
        type: 'bar',
        markPoint: {
          data: [],
        },
      }))
      const source = [
        { month: 'Jan' },
        { month: 'Fev' },
        { month: 'Mar' },
        { month: 'Abr' },
        { month: 'Mai' },
        { month: 'Jun' },
        { month: 'Jul' },
        { month: 'Ago' },
        { month: 'Set' },
        { month: 'Out' },
        { month: 'Nov' },
        { month: 'Dez' },
      ]
      Object.keys(source).forEach((_, index) => {
        years.forEach((year, yearIndex) => {
          const v = data.value_month[year][index + 1]
          source[index][year] = v ? (v / 100) : 0

          const percent = Math.round((data.value_month_percentage[year][index + 1] || 0) * 100) / 100
          if (percent !== 0) {
            const isPositive = percent >= 0
            const point = {
              name: '%',
              value: `${percent}%`,
              xAxis: index,
              yAxis: source[index][year],
              itemStyle: {
                color: isPositive ? '#28a745' : '#dc3545',
              },
              label: {
                verticalAlign: 'top',
                position: 'top',
                fontSize: '11px',
                // distance: 25,
                distance: 18,
                rotate: 70,
              },
              symbol: 'triangle',
              symbolRotate: isPositive ? '0' : '180',
              symbolSize: '10',
              symbolOffset: [0, '-8px'],
            }
            series[yearIndex].markPoint.data.push(point)
          }
        })
      })

      option.value = {
        title: {
          text: 'Comparativo de vendas mensais',
          subtext: 'Apresenta a evolução das vendas mensais comparadas aos mesmos meses de anos anteriores',
          left: mobile.value.mobile ? 'left' : 'center',
        },
        legend: {
          bottom: '15px',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
          },
        },
        dataset: {
          dimensions: ['month', ...dimensions],
          source,
        },
        xAxis: { type: 'category' },
        yAxis: {
          type: 'value',
          axisLabel: {
            rotate: 55,
            formatter: value => (value ? `${formatCurrencyNonDecimal(value)}` : ''),
          },
        },
        series,
      }
    })

    return {
      option,
    }
  },
}
</script>
