import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  endOfDay, format, startOfDay,
} from 'date-fns'

// 'primary', 'primary', 'success', 'danger', 'warning', 'info', 'dark'
export const resolveTypeVariant = type => {
  if (type === 'Institution') return 'success'
  if (type === 'PhysicianDuty') return 'warning'
  return 'primary'
}

export const resolveEventVariant = event => {
  if (/create/.test(event)) return 'success'
  if (/physician-duty/.test(event)) return 'danger'
  if (/payment-remittance/.test(event)) return 'info'
  return 'primary'
}

export default function useAuditList() {
  // Use toast
  const toast = useToast()

  const refInstitutionListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'type',
      label: 'Entidade',
    },
    { key: 'event', label: 'Evento' },
    {
      key: 'user.name',
      label: 'Usuário',
      formatter: name => (name || 'Sistema'),
    },
    {
      key: 'created_at',
      label: 'Data',
    },
    { key: 'actions', label: 'Ações' },
  ]
  const perPage = ref(50)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100, 200]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const beginDate = ref(format(startOfDay(new Date()), 'yyyy-MM-dd'))
  const endDate = ref(format(endOfDay(new Date()), 'yyyy-MM-dd'))
  const institutionId = ref('')
  const entityId = ref({ label: 'Todas', value: null })
  const institutionOptions = ref([])
  const entitiesOptions = ref([
    { value: null, label: 'Todas' },
    { value: 'App\\Packages\\PartnershipModel\\Partnership', label: 'Parceiro' },
    { value: 'App\\Packages\\Institution\\Model\\Institution', label: 'Instituição' },
    { value: 'App\\Packages\\FinancialInstitution\\Model\\FinancialInstitution', label: 'Banco' },
    { value: 'App\\Packages\\PhysicianDuty\\Model\\PhysicianDuty', label: 'Plantão' },
    { value: 'App\\Models\\User', label: 'Usuário' },
    { value: 'App\\Packages\\Physician\\Model\\Physician', label: 'Médico' },
    { value: 'App\\Packages\\PaymentRemittance\\Model\\PaymentRemittance', label: 'Remessa' },
    { value: 'App\\Packages\\Invoice\\Model\\Invoice', label: 'Fatura' },
    { value: 'App\\Packages\\Workplace\\Model\\Workplace', label: 'Unidade' },
  ])
  const userId = ref('')
  const userOptions = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refInstitutionListTable.value ? refInstitutionListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refetchData = () => {
    refInstitutionListTable.value.refresh()
  }

  watch([
    currentPage,
    perPage,
    searchQuery,
    statusFilter,
    beginDate,
    endDate,
    institutionId,
    entityId,
    userId,
  ], () => {
    refetchData()
  })

  const fetchAudits = (ctx, callback) => {
    store
      .dispatch('app-audits/fetchAudits', {
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDirection: isSortDirDesc.value ? 'desc' : 'asc',
        begin: beginDate.value,
        end: endDate.value,
        institution: institutionId.value ? institutionId.value.value : '',
        auditableType: entityId.value ? entityId.value.value : '',
        user: userId.value ? userId.value.value.id : '',
      })
      .then(response => {
        const { data, meta } = response.data
        callback(data)
        totalItems.value = meta.pagination.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao buscar',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchAudits,
    resolveEventVariant,
    tableColumns,
    resolveTypeVariant,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refInstitutionListTable,

    refetchData,
    beginDate,
    endDate,
    institutionId,
    entityId,
    institutionOptions,
    userId,
    userOptions,
    entitiesOptions,
  }
}
