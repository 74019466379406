<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <b-tabs
        class="g-med-tabs w-100"
        pills
        fill
        justified
      >
        <b-tab active>
          <template #title>
            <div>
              <feather-icon
                icon="CornerRightUpIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">CONSOLIDADO</span>
            </div>
          </template>
          <physician-duty-on-payroll-consolidated />
        </b-tab>
        <b-tab>
          <template #title>
            <div>
              <feather-icon
                icon="CalendarIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">DIÁRIO</span>
            </div>
          </template>
          <physician-duty-on-payroll-daily />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { BCard, BTab, BTabs } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import PhysicianDutyOnPayrollConsolidated from './consolidated/PhysicianDutyOnPayrollConsolidated.vue'
import PhysicianDutyOnPayrollDaily from './daily/PhysicianDutyOnPayrollDaily.vue'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    PhysicianDutyOnPayrollConsolidated,
    PhysicianDutyOnPayrollDaily,
  },
  directives: {
    Ripple,
  },
}
</script>
