import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDutyTypes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/duty-type', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDutyType(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/duty-type/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addDutyType(ctx, dutyTypeData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/duty-type', dutyTypeData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateDutyType(ctx, dutyTypeData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/duty-type/${dutyTypeData.id}`, dutyTypeData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
