import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/home/Home.vue'),
      meta: {
        action: 'read',
        resource: 'Home',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/forgot-password/ForgotPassword.vue'),
      props: true,
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    // *===============================================---*
    // *--------- USER ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/users/list',
      name: 'users-list',
      component: () => import('@/views/user/users-list/UsersList.vue'),
      meta: {
        action: 'read',
        resource: 'User',
      },
    },
    {
      path: '/users/view/:id',
      name: 'users-view',
      component: () => import('@/views/user/users-view/UsersView.vue'),
      meta: {
        action: 'read',
        resource: 'User',
      },
    },
    {
      path: '/users/edit/:id',
      name: 'users-edit',
      component: () => import('@/views/user/users-edit/UsersEdit.vue'),
      meta: {
        action: 'read',
        resource: 'User',
      },
    },
    // *===============================================---*
    // *--------- Partner --------------------------------*
    // *===============================================---*
    {
      path: '/partners/list',
      name: 'partners-list',
      component: () => import('@/views/partners/partners-list/PartnersList.vue'),
      meta: {
        action: 'read',
        resource: 'Partner',
      },
    },
    // *===============================================---*
    // *--------- PHYSICIAN ------------------------------*
    // *===============================================---*
    {
      path: '/physician/list',
      name: 'physician-list',
      component: () => import('@/views/physician/physician-list/PhysicianList.vue'),
      meta: {
        action: 'read',
        resource: 'Physician',
      },
    },
    // *===============================================---*
    // *--------- DUTY TYPE ------------------------------*
    // *===============================================---*
    {
      path: '/duty-type/list',
      name: 'duty-type',
      component: () => import('@/views/duty-type/duty-types-list/DutyTypesList.vue'),
      meta: {
        action: 'read',
        resource: 'DutyType',
      },
    },
    {
      path: '/physician/view/:id',
      name: 'physician-view',
      component: () => import('@/views/physician/physician-view/PhysicianView.vue'),
      meta: {
        action: 'read',
        resource: 'Physician',
      },
    },
    {
      path: '/physician/edit/:id',
      name: 'physician-edit',
      component: () => import('@/views/physician/physician-edit/PhysicianEdit.vue'),
      meta: {
        action: 'read',
        resource: 'Physician',
      },
    },

    // *===============================================---*
    // *--------- FINANCIAL ------------------------------*
    // *===============================================---*
    {
      path: '/financial-institution/list',
      name: 'financial-institution-list',
      component: () => import('@/views/financial-institution/financial-institutions-list/FinancialInstitutionsList.vue'),
      meta: {
        action: 'read',
        resource: 'FinancialInstitutions',
      },
    },
    {
      path: '/financial/not-in-payment-remittance/list',
      name: 'not-in-payment-remittance',
      component: () => import('@/views/financial/not-in-payment-remittance/NotInPaymentRemittanceList.vue'),
      meta: {
        action: 'read',
        resource: 'NotInPaymentRemittances',
      },
    },
    {
      path: '/financial/contract/canceled-advanced-history',
      name: 'canceled-advanced-history',
      component: () => import('@/views/financial/canceled-advanced-history/CanceledAdvancedHistoryList.vue'),
      meta: {
        action: 'read',
        resource: 'CanceledAdvancedHistory',
      },
    },
    {
      path: '/financial/invoice/list',
      name: 'invoice',
      component: () => import('@/views/financial/invoice/InvoiceList.vue'),
      meta: {
        action: 'read',
        resource: 'Invoice',
      },
    },
    {
      path: '/financial/invoice/:id',
      name: 'invoice-view',
      component: () => import('@/views/financial/invoice-view/InvoiceView.vue'),
      meta: {
        action: 'read',
        resource: 'Invoice',
      },
    },
    {
      path: '/financial/invoice/:id/edit',
      name: 'invoice-edit',
      component: () => import('@/views/financial/invoice-edit/InvoiceEdit.vue'),
      meta: {
        action: 'read',
        resource: 'Invoice',
      },
    },
    {
      path: '/financial/advanced-physician-duties-invoice/list',
      name: 'advanced-physician-duties-invoice',
      component: () => import('@/views/financial/advanced-physician-duties-invoice/AdvancedPhysicianDutiesInvoice.vue'),
      meta: {
        action: 'read',
        resource: 'AdvancedPhysicianDutiesInvoice',
      },
    },
    {
      path: '/financial/payment-remittance/list',
      name: 'payment-remittance-list',
      component: () => import('@/views/financial/payment-remittance-list/PaymentRemittanceList.vue'),
      meta: {
        action: 'read',
        resource: 'PaymentRemittance',
      },
    },
    {
      path: '/financial/payment-remittance/delete-history',
      name: 'payment-remittance-delete-history',
      component: () => import('@/views/financial/payment-remittance-delete-history/PaymentRemittanceDeleteHistoryList.vue'),
      meta: {
        action: 'read',
        resource: 'PaymentRemittanceDeleteHistory',
      },
    },
    {
      path: '/financial/payment-remittance/view/:id',
      name: 'payment-remittance-view',
      component: () => import('@/views/financial/payment-remittance-view/PaymentRemittanceView.vue'),
      meta: {
        action: 'read',
        resource: 'PaymentRemittance',
      },
    },
    {
      path: '/financial/payment-remittance/view/:id/table',
      name: 'payment-remittance-view-table',
      component: () => import('@/views/financial/payment-remittance-view-table/PaymentRemittanceView.vue'),
      meta: {
        action: 'read',
        resource: 'PaymentRemittances',
      },
    },
    // {
    //   path: '/financial/authorizations/list',
    //   name: 'authorizations-list',
    //   component: () => import('@/views/financial/authorizations-list/UsersList.vue'),
    //   meta: {
    //     action: 'read',
    //     resource: 'DutyTypes',
    //   },
    // },

    // *===============================================---*
    // *--------- Business Process------------------------*
    // *===============================================---*
    {
      path: '/business-process',
      name: 'business-process-page',
      component: () => import('@/views/business-process/business-process-page/BusinessProcessPage.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'BusinessProcess',
      },
    },
    {
      path: '/business-process/list',
      name: 'business-process-list',
      component: () => import('@/views/business-process/business-process-list/BusinessProcessList.vue'),
      meta: {
        action: 'read',
        resource: 'BusinessProcess',
      },
    },
    {
      path: '/business-process/create',
      name: 'business-process-create',
      component: () => import('@/views/business-process/business-process-edit/BusinessProcessEdit.vue'),
      meta: {
        action: 'read',
        resource: 'BusinessProcess',
      },
    },
    {
      path: '/business-process/edit/:id',
      name: 'business-process-edit',
      component: () => import('@/views/business-process/business-process-edit/BusinessProcessEdit.vue'),
      meta: {
        action: 'read',
        resource: 'BusinessProcess',
      },
    },
    {
      path: '/business-process/:id',
      name: 'business-process-view',
      component: () => import('@/views/business-process/business-process-view/BusinessProcessView.vue'),
      meta: {
        action: 'read',
        resource: 'BusinessProcess',
      },
    },

    // *===============================================---*
    // *--------- INSTITUTION ----------------------------*
    // *===============================================---*
    {
      path: '/institution/list',
      name: 'institution-list',
      component: () => import('@/views/institution/institution-list/InstitutionList.vue'),
      meta: {
        action: 'read',
        resource: 'Institution',
      },
    },
    {
      path: '/institution/create',
      name: 'institution-create',
      component: () => import('@/views/institution/institution-create/InstitutionCreate.vue'),
      meta: {
        action: 'manage',
        resource: 'Institution',
      },
    },
    {
      path: '/institution/view/:id',
      name: 'institution-view',
      component: () => import('@/views/institution/institution-view/InstitutionView.vue'),
      meta: {
        action: 'read',
        resource: 'Institution',
      },
    },
    {
      path: '/institution/edit/:id',
      name: 'institution-edit',
      component: () => import('@/views/institution/institution-edit/InstitutionEdit.vue'),
      meta: {
        action: 'read',
        resource: 'Institution',
      },
    },
    // *===============================================---*
    // *----------- Audit --------------------------------*
    // *===============================================---*
    {
      path: '/audits',
      name: 'audits',
      component: () => import('@/views/audit/audit-list/AuditList.vue'),
      meta: {
        action: 'read',
        resource: 'Audit',
      },
    },
    // *===============================================---*
    // *--------- Relatorio ------------------------------*
    // *===============================================---*
    {
      path: '/account-history',
      name: 'account-history',
      component: () => import('@/views/account-history/audit-history-list/AccountHistoryList.vue'),
      meta: {
        action: 'read',
        resource: 'Audit',
      },
    },
    {
      path: '/reports',
      name: 'reports',
      component: () => import('@/views/report/report/Report.vue'),
      meta: {
        action: 'read',
        resource: 'ReportAdvancePayment',
      },
    },
    {
      path: '/financial/reports',
      name: 'financial-reports',
      component: () => import('@/views/report/report/Report.vue'),
      meta: {
        action: 'read',
        resource: 'FinancialReportAdvancePayment',
      },
    },
    {
      path: '/financial/profits',
      name: 'report-profit',
      component: () => import('@/views/report/report-profit/ReportProfit.vue'),
      meta: {
        action: 'read',
        resource: 'ReportProfit',
      },
    },
    {
      path: '/sync-errors',
      name: 'sync-errors',
      component: () => import('@/views/sync-errors/SyncErrors.vue'),
      meta: {
        action: 'read',
        resource: 'ReportSyncErrors',
      },
    },
    {
      path: '/failed-physician-duties',
      name: 'failed-physician-duties',
      component: () => import('@/views/integration/failed-physician-duties/FailedPhysicianDuties.vue'),
      meta: {
        action: 'read',
        resource: 'ReportFailedPhysicianDuties',
      },
    },
    {
      path: '/reports/physician-duty',
      name: 'reports-physician-duty',
      component: () => import('@/views/report/report-physician-duty/ReportPhysicianDuty.vue'),
      meta: {
        action: 'read',
        resource: 'ReportPhysicianDuty',
      },
    },
    {
      path: '/reports/report-physician-first-advance',
      name: 'report-physician-first-advance',
      component: () => import('@/views/report/report-physician-first-advance/ReportPhysicianFirstAdvance.vue'),
      meta: {
        action: 'read',
        resource: 'ReportPhysicianFirstAdvance',
      },
    },
    {
      path: '/reports/report-physician-activate',
      name: 'report-physician-activate',
      component: () => import('@/views/report/report-physician-activate/ReportPhysicianActivate.vue'),
      meta: {
        action: 'read',
        resource: 'ReportPhysicianActivate',
      },
    },
    {
      path: '/reports/physician-duty-on-payroll',
      name: 'physician-duty-on-payroll',
      component: () => import('@/views/report/physician-duty-on-payroll/PhysicianDutyOnPayroll.vue'),
      meta: {
        action: 'read',
        resource: 'ReportPhysicianDutyOnPayroll',
      },
    },
    {
      path: '/reports/physician-duty-conflict',
      name: 'physician-duty-conflict',
      component: () => import('@/views/report/physician-duty-conflict/PhysicianDutyConflict.vue'),
      meta: {
        action: 'read',
        resource: 'ReportPhysicianDutyConflict',
      },
    },
    {
      path: '/reports/physicians',
      name: 'report-physicians',
      component: () => import('@/views/report/report-physicians/ReportPhysicians.vue'),
      meta: {
        action: 'read',
        resource: 'ReportPhysicians',
      },
    },
    // *===============================================---*
    // *--------- CHARTS ------------------------------------*
    // *===============================================---*
    {
      path: '/charts',
      name: 'charts',
      component: () => import('@/views/charts/Chart.vue'),
      meta: {
        action: 'read',
        resource: 'Charts',
      },
    },
    // *===============================================---*
    // *--------- FAQ ------------------------------------*
    // *===============================================---*
    {
      path: '/faqs',
      name: 'faq',
      component: () => import('@/views/faq/faq-list/FaqSetting.vue'),
      meta: {
        action: 'read',
        resource: 'Faq',
      },
    },
    // *===============================================---*
    // *--------- Contract/Rates -------------------------*
    // *===============================================---*
    {
      path: '/contracts',
      name: 'contract-list',
      component: () => import('@/views/contract/contract-list/ContractList.vue'),
      meta: {
        action: 'read',
        resource: 'Contract',
      },
    },
    {
      path: '/contracts/view/:id',
      name: 'contract-view',
      component: () => import('@/views/contract/contract-view/ContractView.vue'),
      meta: {
        action: 'read',
        resource: 'Contract',
      },
    },
    {
      path: '/contracts/edit/:id',
      name: 'contract-edit',
      component: () => import('@/views/contract/contract-edit/ContractEdit.vue'),
      meta: {
        action: 'read',
        resource: 'Contract',
      },
    },
    // *===============================================---*
    // *----------------- Imports-------------------------*
    // *===============================================---*
    {
      path: '/imports/physician',
      name: 'import-physician',
      component: () => import('@/views/imports/import-list/ImportPhysicianList.vue'),
      meta: {
        action: 'read',
        resource: 'ImportPhysician',
      },
    },
    {
      path: '/imports/physician/:id',
      name: 'import-physician-view',
      component: () => import('@/views/imports/import-view/PhysicianImportView.vue'),
      meta: {
        action: 'read',
        resource: 'ImportPhysician',
      },
    },
    // *===============================================---*
    // *----------------- Errors -------------------------*
    // *===============================================---*
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'misc-not-authorized',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
