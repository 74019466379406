<template>
  <b-card
    class="page-charts flex-nowrap overflow-auto"
    style="min-width: 700px;"
  >
    <b-row>
      <b-col md="12">
        <b-dropdown
          class="d-md-none menu w-50"
          variant="primary"
          :text="tabs[currentTab].title"
        >
          <b-dropdown-item
            v-for="({ title }, index) in tabs"
            :key="index"
            @click="currentTab = index"
          >
            {{ title }}
          </b-dropdown-item>
        </b-dropdown>
        <b-tabs
          v-model="currentTab"
          class="g-med-tabs"
          pills
        >
          <b-tab active>
            <template
              #title
              title-item-class="d-none"
              class="d-none d-md-block"
            >
              <div>
                <span>{{ tabs[0].title }}</span>
              </div>
            </template>
            <main-container :mobile="isMobile" />
          </b-tab>
          <b-tab>
            <template #title>
              <div>
                <span>{{ tabs[1].title }}</span>
              </div>
            </template>
            <main-institution :mobile="isMobile" />
          </b-tab>
          <b-tab>
            <template #title>
              <div>
                <span>{{ tabs[2].title }}</span>
              </div>
            </template>
            <main-workplace :mobile="isMobile" />
          </b-tab>
          <b-tab>
            <template #title>
              <div>
                <span>{{ tabs[3].title }}</span>
              </div>
            </template>
            <main-physician :mobile="isMobile" />
          </b-tab>
          <b-tab>
            <template #title>
              <div>
                <span>{{ tabs[4].title }}</span>
              </div>
            </template>
            <main-others :mobile="isMobile" />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BTab, BTabs, BCard, BDropdown, BDropdownItem,
} from 'bootstrap-vue'

import store from '@/store'
import chartStoreModule from '@/views/charts/chartStoreModule'
import { onUnmounted, ref } from '@vue/composition-api'
import MainContainer from './general/MainContainer.vue'
import MainInstitution from './institution/MainContainer.vue'
import MainWorkplace from './workplace/MainContainer.vue'
import MainPhysician from './physician/MainContainer.vue'
import MainOthers from './others/MainContainer.vue'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BDropdown,
    BDropdownItem,
    MainContainer,
    MainInstitution,
    MainWorkplace,
    MainPhysician,
    MainOthers,
  },
  data: () => ({
    isMobile: { mobile: false, breakpoint: 'md' },
  }),
  created() {
    this.calculateMobile()
    window.addEventListener('resize', this.calculateMobile)
  },
  destroyed() {
    window.removeEventListener('resize', this.calculateMobile)
  },
  methods: {
    calculateMobile() {
      const w = Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0,
      )

      if (w < 576) {
        this.isMobile.breakpoint = 'xs'
      } else if (w >= 576 && w < 768) {
        this.isMobile.breakpoint = 'sm'
      } else if (w >= 768 && w < 992) {
        this.isMobile.breakpoint = 'md'
      } else if (w >= 992 && w < 1200) {
        this.isMobile.breakpoint = 'lg'
      } else if (w >= 1200) {
        this.isMobile.breakpoint = 'xl'
      }
      this.isMobile.mobile = ['xs', 'sm', 'md'].includes(this.isMobile.breakpoint)
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-chart'
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, chartStoreModule)

    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const currentTab = ref(0)
    const tabs = ref([
      {
        title: 'Geral',
      },
      {
        title: 'Instituições',
      },
      {
        title: 'Unidades',
      },
      {
        title: 'Médicos',
      },
      {
        title: 'Outros',
      },
    ])

    return {
      currentTab,
      tabs,
    }
  },
}
</script>

<style lang="scss" >
@import '~@core/scss/base/bootstrap-extended/include';

.page-charts {
  .g-med-tabs [role="tablist"] {
    display: none;
  }

  @media (min-width: (map-get($grid-breakpoints, md))) {
    .g-med-tabs [role="tablist"] {
      display: flex;
    }
  }
}
</style>
