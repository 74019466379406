<template>
  <div>

    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <div>
            <p class="mr-1">
              Exibir empresa Imediato?
            </p>

          </div>
          <b-form-checkbox
            :checked="showImediato"
            class="custom-control-warning"
            name="check-button"
            switch
            @input="showImediato = !showImediato;"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>

        </b-row>
      </div>
      <b-table
        ref="refSyncErrorsTable"
        class="position-relative"
        :items="fetchSyncErrors"
        no-provider-sorting
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="Nenhum registro correspondente encontrado"
      >
        <template
          #cell(contract_code)="{item}"
        >
          <h6>{{ item.contract_code }}</h6>
        </template>

        <template
          #cell(contract_id)="{item}"
        >
          <div class="contract_code">
            {{ item.contract_id }}
          </div>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              @click="openHistory(data.item)"
            >
              <feather-icon icon="ListIcon" />
              <span class="align-middle ml-50">Histórico</span>
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="!$can('manage', 'ReportSyncErrors')"
              @click="retrySync({ contractId: data.item.contract_id })"
            >
              <feather-icon icon="RefreshCcwIcon" />
              <span class="align-middle ml-50">Re-sync</span>
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="!$can('manage', 'ReportSyncErrors')"
              @click="openDelete(data.item)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Remover</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>
    <b-modal
      id="modal-history"
      ref="historyModal"
      :title="currentHistory ? `Histórico Contrato ${currentHistory.contract_code}`: ''"
      hide-footer
      size="lg"
    >
      <div v-if="currentHistory && currentHistory.logs">
        <div
          v-if="currentHistory.logs.length === 0"
          class="text-center"
        >
          Nenhum registro encontrado
        </div>
        <b-card
          v-for="log in currentHistory.logs"
          :key="log.id"
          no-body
          class="d-flex align-items-center"
        >
          <b-card-text class="mb-2 p-2">
            <h6 class="mb-1 text-right">
              {{ formatDateTimeDDMMYYHHMMSS(new Date(log.created_at)) }}
            </h6>
            {{ JSON.stringify(JSON.parse(log.response), null, 2) }}
          </b-card-text>
        </b-card>
      </div>
      <b-card-text v-else>
        <div class="text-center p-4">
          <feather-icon
            icon="RefreshCcwIcon"
            size="20"
            animation="spin"
          />
        </div>
      </b-card-text>
    </b-modal>
    <b-modal
      id="modal-contract"
      ref="contractCancelModal"
      :title="`Deseja remover o contrato ${contractToCancel.contract_code} remessa ${contractToCancel.remittance_code}?`"
      hide-footer
      centered
    >
      <div>
        <b-alert
          show
          variant="danger"
          class="p-1"
        >
          Ao remover da remessa o item voltará para tela de solicitações.
        </b-alert>
        <b-form-group
          class="mt-1"
          label="Motivo"
          label-for="reopen-motive"
        >
          <b-form-textarea
            id="reopen-motive"
            v-model="contractToCancel.motive"
            rows="4"
            placeholder="Descreva o motivo..."
          />
        </b-form-group>
        <div class="text-right">
          <b-button
            variant="primary"
            class="ml-1"
            :disabled="loading"
            @click="deleteItem"
          >
            <span class="d-none d-sm-inline">Remover</span>
            <feather-icon
              icon="RefreshCwIcon"
              class="d-inline d-sm-none"
            />
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import {
  BCard,
  BTable,
  BRow,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BModal,
  BCardText,
  BFormTextarea,
  BFormGroup,
  BButton,
  BAlert,
} from 'bootstrap-vue'
import { formatDateTimeDDMMYYHHMMSS } from '@core/utils/utils'
import Vue from 'vue'
import useSyncErrors from './useSyncErrors'
import syncErrorsStoreModule from './syncErrorsStoreModule'

export default {
  components: {
    BAlert,
    BButton,
    BFormGroup,
    BFormTextarea,
    BCard,
    BTable,
    BRow,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BModal,
    BCardText,
  },
  directives: {
    Ripple,
  },
  setup() {
    const SYNC_ERRORS_APP_STORE_MODULE_NAME = 'app-sync-errors'
    // Register module
    if (!store.hasModule(SYNC_ERRORS_APP_STORE_MODULE_NAME)) store.registerModule(SYNC_ERRORS_APP_STORE_MODULE_NAME, syncErrorsStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SYNC_ERRORS_APP_STORE_MODULE_NAME)) store.unregisterModule(SYNC_ERRORS_APP_STORE_MODULE_NAME)
    })

    const historyModal = ref(null)
    const currentHistory = ref(null)
    const contractCancelModal = ref(null)
    const contractToCancel = ref({
      contract_code: '',
      remittance_code: '',
      payment_remittance_item_id: '',
      motive: '',
    })

    const {
      fetchSyncErrors,
      tableColumns,
      showImediato,
      refSyncErrorsTable,
      refetchData,
      reSync,
    } = useSyncErrors()

    async function retrySync(params) {
      const result = await this.$swal({
        title: 'Você tem certeza?',
        text: 'Reenviar para OMIE',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, enviar!',
        cancelButtonText: 'Não',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      })

      if (result.value) {
        reSync(params)
      }
    }

    const contractHistory = ({ contractId }) => store
      .dispatch('app-sync-errors/contractHistory', { contractId })

    function openHistory(contract) {
      currentHistory.value = {
        contract_code: contract.contract_code,
        logs: null,
      }
      contractHistory({ contractId: contract.contract_id }).then(response => {
        currentHistory.value.logs = response.data.data.map(data => data)
      })
      historyModal.value.show()
    }

    async function openDelete({ payment_remittance_item_id, remittance_code, contract_code }) {
      contractToCancel.value = {
        contract_code,
        remittance_code,
        payment_remittance_item_id,
        motive: '',
      }
      contractCancelModal.value.show()
    }
    async function deleteItem() {
      store.dispatch('app-sync-errors/deletePaymentRemittanceItem', contractToCancel.value)
        .then(async () => {
          contractCancelModal.value.hide()
          await Vue.swal({
            title: 'Removido!',
            text: 'Removido',
            icon: 'success',
            customClass: { confirmButton: 'btn btn-primary' },
            buttonsStyling: false,
          })
          contractToCancel.value = {
            payment_remittance_item_id: '',
            motive: '',
          }
          refetchData()
        })
    }

    return {
      openDelete,
      contractToCancel,
      deleteItem,
      contractCancelModal,
      fetchSyncErrors,
      tableColumns,
      showImediato,
      refSyncErrorsTable,
      refetchData,
      retrySync,
      openHistory,
      historyModal,
      currentHistory,
      contractHistory,
      formatDateTimeDDMMYYHHMMSS,
    }
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';

    .contract_code  {
      white-space: nowrap;
      width: 100px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      background-color: #fff0;
      z-index: 1;
    }
    .contract_code:hover  {
      overflow: visible;
      white-space: inherit;
    }
</style>
