<template>
  <div>
    <b-card-title
      title-tag="h2"
      class="font-weight-bold mb-1"
    >
      Definir uma nova senha
    </b-card-title>
    <b-card-text class="mb-2">
      Informe uma nova senha de acesso
    </b-card-text>

    <!-- form -->
    <validation-observer ref="codeRules">
      <b-form
        class="auth-forgot-password-form mt-2"
        @submit.prevent="resetPassword"
      >
        <b-form-group
          label="Senha"
          label-for="forgot-password-password"
        >
          <validation-provider
            #default="{ errors }"
            name="Senha"
            rules="required|password"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="new-password"
                v-model="user.password"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                :type="passwordFieldType"
                name="new-password"
                placeholder="············"
                :data-autocomplete="'new-password-' + _uid"
                aria-autocomplete="none"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <password-meter
              v-model="user.password"
              :strength-meter-only="true"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Confirmar Senha"
          label-for="forgot-password-password"
        >
          <validation-provider
            #default="{ errors }"
            name="Confirmar Senha"
            rules="required|confirmed:Senha"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="new-password-confirm"
                v-model="user.password_confirmation"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                :type="passwordFieldType"
                name="new-password-confirm"
                placeholder="············"
                :data-autocomplete="'new-password-confirm' + _uid"
                aria-autocomplete="none"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <password-meter
              v-model="user.password_confirmation"
              :strength-meter-only="true"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-button
          v-if="!submitting"
          type="submit"
          variant="primary"
          block
        >
          Recuperar
        </b-button>
        <b-button
          v-else
          disabled
          type="submit"
          variant="primary"
          block
        >
          Recuperar
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
/* eslint-disable global-require */
import PasswordMeter from 'vue-password-strength-meter'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCardTitle, BCardText, BForm, BFormGroup, BFormInput, BButton, BInputGroupAppend, BInputGroup,
} from 'bootstrap-vue'
import {
  required, email, digits, password, confirmed,
} from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useSanctum from '@/auth/sanctum/useSanctum'

export default {
  components: {
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    BInputGroupAppend,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    PasswordMeter,
  },
  mixins: [togglePasswordVisibility],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      submitting: false,
      // validation
      required,
      email,
      digits,
      password,
      confirmed,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    toast(props) {
      this.$toast({
        component: ToastificationContent,
        props: {
          icon: 'AlertCircleIcon',
          variant: 'danger',
          ...props,
        },
      })
    },
    resetPassword() {
      this.submitting = true
      this.$refs.codeRules.validate().then(success => {
        if (!success) {
          this.submitting = false
          return null
        }
        return this.sendResetPassword()
      })
    },
    async sendResetPassword() {
      await this.$recaptchaLoaded()
      return useSanctum.resetPassword({
        email: this.user.email,
        token: this.user.token,
        password: this.user.password,
        password_confirmation: this.user.password_confirmation,
        recaptcha: await this.$recaptcha('login'),
      })
        .then(() => {
          this.submitting = false
          this.toast({
            title: 'Senha recuperada',
            icon: 'CheckIcon',
            variant: 'success',
          })
          this.$router.push({ name: 'login' })
        })
        .catch(error => {
          this.submitting = false
          if (error.response.status === 429) {
            this.toast({ title: 'Muitas tentativas, tente novamente em alguns minutos' })
          } else {
            this.$refs.codeRules.setErrors({
              token: 'Código inválido ou expirado',
            })
            this.lastRequestSeconds = 60
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
