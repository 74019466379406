import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    paymentRemittanceExportDeleteHistory() {
      return new Promise((resolve, reject) => {
        axios
          .get('/payment-remittance/export-delete-history', { responseType: 'blob' })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    canceledAdvancedHistory() {
      return new Promise((resolve, reject) => {
        axios
          .get('/contract/canceled-advanced-history-export', { responseType: 'blob' })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPhysicianFirstAdvance(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin-report/user-physician-first-advance', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDownloadPhysicianFirstAdvance(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin-report/user-physician-first-advance-export', { params: queryParams, responseType: 'blob' })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPhysicianActivate(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin-report/physician-user-activate', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDownloadPhysicianActivate(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin-report/physician-user-activate-export', { params: queryParams, responseType: 'blob' })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchReportPhysicians(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/physician/report-crated-at', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadReportPhysicians(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/physician/report-crated-at-export', { params: queryParams, responseType: 'blob' })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadReport(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin-report/advanced-physician-duties-export', { params: queryParams, responseType: 'blob' })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadContract(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/contract/pdf/${queryParams.id}`, { responseType: 'blob' })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchReport(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin-report/advanced-physician-duties', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    exportPaymentVoucher(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/contract/export-payment-voucher/${queryParams.contractId}`, { responseType: 'blob' })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    physicianDutyOnPayroll(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin-report/physician-duty-on-payroll', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    physicianDutyOnPayrollDaily(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin-report/physician-duty-on-payroll-by-day', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchConflicts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin-report/physician-duty-conflict', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWorkplaceByInstitution(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/workplace/list-by-institution', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchReportPhysicianDuty(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin-report/physician-duty', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchReportPhysicianDutyExport(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin-report/physician-duty-export', { params: queryParams, responseType: 'blob' })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPhysicians(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/physician/report-admin', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInstitutions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/institution', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProfitReport(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin-report/profit-report', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    exportProfitReport(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin-report/profit-report-xls', { responseType: 'blob', params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
