<template>
  <b-card>
    <h4 v-if="mode === 'create'">
      Novo Processo
    </h4>
    <h4 v-else>
      Editar Processo
    </h4>

    <b-form-group
      class="mt-1"
      label="Título"
      label-for="title"
    >
      <b-form-input
        id="title"
        v-model="process.title"
        placeholder="Título..."
      />
    </b-form-group>

    <b-form-group
      class="mt-1"
      label="Descrição"
      label-for="description"
    >
      <b-form-textarea
        id="description"
        v-model="process.description"
        rows="4"
        placeholder="Descrição..."
      />
    </b-form-group>

    <b-form-group
      class="mt-1"
      label="Categoria"
      label-for="category"
    >
      <b-form-input
        id="category"
        v-model="process.category"
        placeholder="Categoria..."
      />
    </b-form-group>

    <b-form-group
      class="mt-1"
      label="HTML"
      label-for="html_content"
    >
      <b-form-textarea
        id="html_content"
        v-model="process.html_content"
        rows="4"
        placeholder="HTML..."
      />
    </b-form-group>

    <b-form-group
      class="mt-1"
      label="Tags"
      label-for="tags"
    >
      <b-form-tags
        v-model="process.tags"
        input-id="tags-basic"
        class="mb-2"
        separator=" ,;"
        placeholder="Insira novas tags separadas por espaço, vírgula ou ponto e vírgula"
      />
    </b-form-group>

    <b-form-group
      class="mt-1"
      :label="process.file ? `Arquivo ${process.file.filename}` : 'Arquivo'"
      label-for="file"
    >
      <b-form-file
        v-model="file"
        placeholder="Escolha um arquivo ou solte-o aqui..."
        drop-placeholder="Solte o arquivo aqui..."
        browse-text="Escolher arquivo"
      />
    </b-form-group>

    <div class="mt-2">
      <b-button
        variant="outline-primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :to="{name: 'business-process-list'}"
      >
        Voltar
      </b-button>
      <b-button
        variant="secondary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        @click="onSubmit"
      >
        Salvar
      </b-button>
    </div>

  </b-card>
</template>

<script>
import {
  BCard, BFormGroup, BFormTextarea, BButton, BFormInput, BFormFile, BFormTags,
} from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import businessProcessStoreModule from '@/views/business-process/businessProcessStoreModule'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BButton,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BFormFile,
    BFormTags,
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-business-process'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, businessProcessStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const mode = ref('create')
    const file = ref(null)
    const process = ref({
      title: '',
      description: '',
      category: '',
      html_content: '',
      tags: [],
    })

    const { id } = router.currentRoute.params

    if (id) {
      mode.value = 'edit'
      store.dispatch('app-business-process/fetchContract', { id })
        .then(response => {
          const p = response.data.data
          process.value = {
            ...p,
            tags: p.tags.split(','),
          }
        })
        .catch(error => {
          if (error.response.status === 404) {
            process.value = undefined
          }
        })
    }

    function onSubmit() {
      const payload = {
        title: process.value.title,
        description: process.value.description,
        category: process.value.category,
        html_content: process.value.html_content,
        tags: process.value.tags.join(','),
        file: file.value,
      }
      if (id) {
        store.dispatch('app-business-process/updateContract', { id, ...payload })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Editado',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Editado com sucesso!',
              },
            })
            router.push({ name: 'business-process-list' })
          })
      } else {
        store.dispatch('app-business-process/createContract', payload)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Criado',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Criado com sucesso!',
              },
            })
            router.push({ name: 'business-process-list' })
          })
      }
    }

    return {
      mode,
      process,
      file,
      onSubmit,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
