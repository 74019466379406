var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"mx-2 mb-2 mt-0"},[_c('b-row',{staticClass:"justify-content-end"},[_c('b-col',{attrs:{"cols":"12","md":"4","lg":"2"}},[_c('label',{attrs:{"for":"begin-date"}},[_vm._v("Início em")]),_c('b-form-datepicker',{attrs:{"id":"begin-date","date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' },"locale":"pt-BR","label-help":""},model:{value:(_vm.beginDate),callback:function ($$v) {_vm.beginDate=$$v},expression:"beginDate"}})],1),_c('b-col',{attrs:{"cols":"12","md":"4","lg":"2"}},[_c('label',{attrs:{"for":"end-date"}},[_vm._v("Fim em")]),_c('b-form-datepicker',{attrs:{"id":"end-date","date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' },"locale":"pt-BR","label-help":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1),_c('b-col',{attrs:{"cols":"12","md":"12","lg":"8"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"d-flex w-100"},[_c('div',{staticClass:"flex-grow-1"},[_c('label',{attrs:{"for":"user-id"}},[_vm._v("Usuário")]),_c('v-select',{attrs:{"id":"user-id","name":"user-id","options":_vm.userOptions,"label":"label","item-value":"value","item-text":"label"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var ref_value = ref.value;
var name = ref_value.name;
var email = ref_value.email;
return [_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(name)+" ")]),_c('div',{staticClass:"user-email"},[_c('span',[_vm._v(_vm._s(email))])]),_c('div',{staticClass:"user-type"},[_c('span',{staticClass:"user-type-label"},[_vm._v("Tipo: ")]),_c('span',{staticClass:"user-type-value"},[_vm._v(_vm._s('Médico'))])])]}}]),model:{value:(_vm.userId),callback:function ($$v) {_vm.userId=$$v},expression:"userId"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhum médico encontrado.")])])],1)])])],1)],1)],1)],1),_c('b-table',{ref:"refInstitutionListTable",staticClass:"position-relative",attrs:{"items":_vm.fetchAudits,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","sort-by":_vm.sortBy,"show-empty":"","empty-text":"Nenhum registro correspondente encontrado","sort-desc":_vm.isSortDirDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"cell(type)",fn:function(data){return [_c('b-badge',{staticClass:"text-capitalize",attrs:{"pill":"","variant":("light-" + (_vm.resolveTypeVariant(data.item.auditable.type)))}},[_vm._v(" "+_vm._s(data.item.auditable.type_translated)+" ")])]}},{key:"cell(event)",fn:function(data){return [_c('b-badge',{staticClass:"text-capitalize",attrs:{"pill":"","variant":("light-" + (_vm.resolveEventVariant(data.item.event)))}},[_vm._v(" "+_vm._s(data.item.event_translated)+" ")])]}},{key:"cell(account)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"account-info"},[_c('div',[_vm._v("Banco")]),_c('div',[_vm._v(_vm._s(item.financial_institution.bank_code)+" - "+_vm._s(item.financial_institution.name))])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"account-info"},[_c('div',[_vm._v("Agência")]),_c('div',[_vm._v(_vm._s(item.branch_number)),(item.branch_code)?_c('span',[_vm._v("-"+_vm._s(item.branch_code))]):_vm._e()])]),_c('div',{staticClass:"account-info"},[_c('div',[_vm._v(_vm._s(_vm.resolveAccountType(item.account_type)))]),_c('div',[_vm._v(_vm._s(item.account_number)+"-"+_vm._s(item.account_code))])])])])]}},{key:"cell(physician_name)",fn:function(ref){
var physician_name = ref.item.physician_name;
return [_c('div',{staticClass:"physician-name"},[_vm._v(" "+_vm._s(physician_name)+" ")])]}},{key:"cell(physician_cpf)",fn:function(ref){
var physician_cpf = ref.item.physician_cpf;
return [_c('div',{staticClass:"physician-cpf"},[_vm._v(" "+_vm._s(_vm.maskHiddenCpf2(physician_cpf))+" ")])]}},{key:"cell(data.company)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{attrs:{"pill":"","variant":item.person_type === 'PJ' ? 'light-danger' : 'light-success'}},[_vm._v(" "+_vm._s(item.person_type || 'PF')+" ")]),_c('div',[_c('strong',[_vm._v(_vm._s(item.person_type === 'PJ' ? item.company_name : item.physician_name))]),_c('div',[_vm._v(_vm._s(item.person_type === 'PJ' ? _vm.formatCnpj(item.company_cnpj) : _vm.maskHiddenCpf2(item.physician_cpf)))])])]}},{key:"cell(created_at)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.formatDate(new Date(data.item.created_at), 'dd/MM/yy HH:mm:ss'))+" ")]}}])}),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v("Exibir")]),_c('v-select',{staticClass:"per-page-selector d-inline-block mx-50",attrs:{"options":_vm.perPageOptions,"clearable":false},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}}),_c('label',[_vm._v("entradas")])],1),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalItems,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }