import store from '@/store'
import { ref, watch } from '@vue/composition-api'
import { addMonths, format, subMonths } from 'date-fns'

export default function usePhysicianDutyConflict() {
  const conflicts = ref([])
  const date = ref(new Date())

  const load = () => {
    const d = format(date.value, 'yyyy-MM')
    store.dispatch('app-reports/fetchConflicts', { date: d })
      .then(response => {
        conflicts.value = response.data.data ?? []
        // const dd = {
        //   message: 'success',
        //   error: false,
        //   data: [
        //     {
        //       id: '69ea7942-dea3-4552-a2ce-09f1dfe144d8',
        //       start_date: '2022-06-03 00:00:00',
        //       end_date: '2022-06-04 09:00:00',
        //       user_id: '4c5dc5e7-63e5-4ba9-994e-8e0bdad87327',
        //       cnpj: '44650069000136',
        //       institution_name: 'Galhardo-Torres 15',
        //       workplace_name: 'Hospital Unimed',
        //       type_duty: 'GERAL',
        //       physician_name: 'teste contract id',
        //       cpf: '79586604055',
        //       amount_paid: 100,
        //       conflicts: [
        //         {
        //           id: 'd3596f37-808b-4d0c-83e7-17e2a19817af',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '4c5dc5e7-63e5-4ba9-994e-8e0bdad87327',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Hospital Unimed',
        //           type_duty: 'GERAL',
        //           physician_name: 'teste contract id',
        //           cpf: '79586604055',
        //           amount_paid: 100,
        //         },
        //       ],
        //     },
        //     {
        //       id: 'd3596f37-808b-4d0c-83e7-17e2a19817af',
        //       start_date: '2022-06-03 00:00:00',
        //       end_date: '2022-06-04 09:00:00',
        //       user_id: '4c5dc5e7-63e5-4ba9-994e-8e0bdad87327',
        //       cnpj: '44650069000136',
        //       institution_name: 'Galhardo-Torres 15',
        //       workplace_name: 'Hospital Unimed',
        //       type_duty: 'GERAL',
        //       physician_name: 'teste contract id',
        //       cpf: '79586604055',
        //       conflicts: [
        //         {
        //           id: '69ea7942-dea3-4552-a2ce-09f1dfe144d8',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '4c5dc5e7-63e5-4ba9-994e-8e0bdad87327',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Hospital Unimed',
        //           type_duty: 'GERAL',
        //           physician_name: 'teste contract id',
        //           cpf: '79586604055',
        //         },
        //       ],
        //     },
        //     {
        //       id: '29012cf9-54ae-4f45-9321-3d81be9ee97f',
        //       start_date: '2022-06-03 00:00:00',
        //       end_date: '2022-06-04 09:00:00',
        //       user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //       cnpj: '44650069000136',
        //       institution_name: 'Galhardo-Torres 15',
        //       workplace_name: 'Hospital Unimed',
        //       type_duty: 'GERAL',
        //       physician_name: 'Dr teste admin4',
        //       cpf: '11548943711',
        //       conflicts: [
        //         {
        //           id: '6298b28f-b3a6-456f-9be4-7d57c672a8b2',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Hospital Unimed',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: '381ace59-0d54-460b-b0ab-e4925e716161',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Hospital Unimed',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: '1911183d-1863-474d-bdfe-b67f7b885d8e',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Upa egtr',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: '9f43560d-6034-4e91-87e3-a9e3517bd0b6',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Hospital Unimed',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: 'e70daa50-f069-4dd7-ad2e-024942ff983f',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Upa egtr',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: '664b334f-1803-44f1-9c12-ddf7f496525b',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Upa egtr',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //       ],
        //     },
        //     {
        //       id: '381ace59-0d54-460b-b0ab-e4925e716161',
        //       start_date: '2022-06-03 00:00:00',
        //       end_date: '2022-06-04 09:00:00',
        //       user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //       cnpj: '44650069000136',
        //       institution_name: 'Galhardo-Torres 15',
        //       workplace_name: 'Hospital Unimed',
        //       type_duty: 'GERAL',
        //       physician_name: 'Dr teste admin4',
        //       cpf: '11548943711',
        //       conflicts: [
        //         {
        //           id: '6298b28f-b3a6-456f-9be4-7d57c672a8b2',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Hospital Unimed',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: '29012cf9-54ae-4f45-9321-3d81be9ee97f',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Hospital Unimed',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: '1911183d-1863-474d-bdfe-b67f7b885d8e',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Upa egtr',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: '9f43560d-6034-4e91-87e3-a9e3517bd0b6',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Hospital Unimed',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: 'e70daa50-f069-4dd7-ad2e-024942ff983f',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Upa egtr',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: '664b334f-1803-44f1-9c12-ddf7f496525b',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Upa egtr',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //       ],
        //     },
        //     {
        //       id: '1911183d-1863-474d-bdfe-b67f7b885d8e',
        //       start_date: '2022-06-03 00:00:00',
        //       end_date: '2022-06-04 09:00:00',
        //       user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //       cnpj: '44650069000136',
        //       institution_name: 'Galhardo-Torres 15',
        //       workplace_name: 'Upa egtr',
        //       type_duty: 'GERAL',
        //       physician_name: 'Dr teste admin4',
        //       cpf: '11548943711',
        //       conflicts: [
        //         {
        //           id: '6298b28f-b3a6-456f-9be4-7d57c672a8b2',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Hospital Unimed',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: '29012cf9-54ae-4f45-9321-3d81be9ee97f',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Hospital Unimed',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: '381ace59-0d54-460b-b0ab-e4925e716161',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Hospital Unimed',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: '9f43560d-6034-4e91-87e3-a9e3517bd0b6',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Hospital Unimed',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: 'e70daa50-f069-4dd7-ad2e-024942ff983f',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Upa egtr',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: '664b334f-1803-44f1-9c12-ddf7f496525b',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Upa egtr',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //       ],
        //     },
        //     {
        //       id: '9f43560d-6034-4e91-87e3-a9e3517bd0b6',
        //       start_date: '2022-06-03 00:00:00',
        //       end_date: '2022-06-04 09:00:00',
        //       user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //       cnpj: '44650069000136',
        //       institution_name: 'Galhardo-Torres 15',
        //       workplace_name: 'Hospital Unimed',
        //       type_duty: 'GERAL',
        //       physician_name: 'Dr teste admin4',
        //       cpf: '11548943711',
        //       conflicts: [
        //         {
        //           id: '6298b28f-b3a6-456f-9be4-7d57c672a8b2',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Hospital Unimed',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: '29012cf9-54ae-4f45-9321-3d81be9ee97f',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Hospital Unimed',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: '381ace59-0d54-460b-b0ab-e4925e716161',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Hospital Unimed',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: '1911183d-1863-474d-bdfe-b67f7b885d8e',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Upa egtr',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: 'e70daa50-f069-4dd7-ad2e-024942ff983f',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Upa egtr',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: '664b334f-1803-44f1-9c12-ddf7f496525b',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Upa egtr',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //       ],
        //     },
        //     {
        //       id: 'e70daa50-f069-4dd7-ad2e-024942ff983f',
        //       start_date: '2022-06-03 00:00:00',
        //       end_date: '2022-06-04 09:00:00',
        //       user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //       cnpj: '44650069000136',
        //       institution_name: 'Galhardo-Torres 15',
        //       workplace_name: 'Upa egtr',
        //       type_duty: 'GERAL',
        //       physician_name: 'Dr teste admin4',
        //       cpf: '11548943711',
        //       conflicts: [
        //         {
        //           id: '6298b28f-b3a6-456f-9be4-7d57c672a8b2',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Hospital Unimed',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: '29012cf9-54ae-4f45-9321-3d81be9ee97f',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Hospital Unimed',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: '381ace59-0d54-460b-b0ab-e4925e716161',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Hospital Unimed',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: '1911183d-1863-474d-bdfe-b67f7b885d8e',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Upa egtr',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: '9f43560d-6034-4e91-87e3-a9e3517bd0b6',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Hospital Unimed',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: '664b334f-1803-44f1-9c12-ddf7f496525b',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Upa egtr',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //       ],
        //     },
        //     {
        //       id: '664b334f-1803-44f1-9c12-ddf7f496525b',
        //       start_date: '2022-06-03 00:00:00',
        //       end_date: '2022-06-04 09:00:00',
        //       user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //       cnpj: '44650069000136',
        //       institution_name: 'Galhardo-Torres 15',
        //       workplace_name: 'Upa egtr',
        //       type_duty: 'GERAL',
        //       physician_name: 'Dr teste admin4',
        //       cpf: '11548943711',
        //       conflicts: [
        //         {
        //           id: '6298b28f-b3a6-456f-9be4-7d57c672a8b2',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Hospital Unimed',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: '29012cf9-54ae-4f45-9321-3d81be9ee97f',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Hospital Unimed',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: '381ace59-0d54-460b-b0ab-e4925e716161',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Hospital Unimed',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: '1911183d-1863-474d-bdfe-b67f7b885d8e',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Upa egtr',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: '9f43560d-6034-4e91-87e3-a9e3517bd0b6',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Hospital Unimed',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: 'e70daa50-f069-4dd7-ad2e-024942ff983f',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Upa egtr',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //       ],
        //     },
        //     {
        //       id: '6298b28f-b3a6-456f-9be4-7d57c672a8b2',
        //       start_date: '2022-06-03 00:00:00',
        //       end_date: '2022-06-04 09:00:00',
        //       user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //       cnpj: '44650069000136',
        //       institution_name: 'Galhardo-Torres 15',
        //       workplace_name: 'Hospital Unimed',
        //       type_duty: 'GERAL',
        //       physician_name: 'Dr teste admin4',
        //       cpf: '11548943711',
        //       conflicts: [
        //         {
        //           id: '29012cf9-54ae-4f45-9321-3d81be9ee97f',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Hospital Unimed',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: '381ace59-0d54-460b-b0ab-e4925e716161',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Hospital Unimed',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: '1911183d-1863-474d-bdfe-b67f7b885d8e',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Upa egtr',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: '9f43560d-6034-4e91-87e3-a9e3517bd0b6',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Hospital Unimed',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: 'e70daa50-f069-4dd7-ad2e-024942ff983f',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Upa egtr',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //         {
        //           id: '664b334f-1803-44f1-9c12-ddf7f496525b',
        //           start_date: '2022-06-03 00:00:00',
        //           end_date: '2022-06-04 09:00:00',
        //           user_id: '6b2315c4-ff19-48ae-8099-9208ebc6f160',
        //           cnpj: '44650069000136',
        //           institution_name: 'Galhardo-Torres 15',
        //           workplace_name: 'Upa egtr',
        //           type_duty: 'GERAL',
        //           physician_name: 'Dr teste admin4',
        //           cpf: '11548943711',
        //         },
        //       ],
        //     },
        //   ],
        // }
        // conflicts.value = dd.data
      })
  }
  load()

  watch([date], () => {
    load()
  })

  const previousDate = () => {
    date.value = subMonths(date.value, 1)
  }

  const nextDate = () => {
    date.value = addMonths(date.value, 1)
  }

  return {
    date,
    conflicts,
    previousDate,
    nextDate,
  }
}
