<template>
  <div>
    <audit-header :audit="audit" />
    <hr>
    <div>
      <h6 class="text-capitalize mb-0">
        Nome
      </h6>
      <div>{{ value.name }}</div>
      <div class="text-muted font-small-2">
        {{ value.fancy_name }}
      </div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        Bloqueio em
      </h6>
      <div>{{ value.blocked_at ? formatDateTimeDDMMYYYY(value.blocked_at) : '-' }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        Plantão Liberado
      </h6>
      <div>{{ value.request_enabled_at ? formatDateTimeDDMMYYYY(value.request_enabled_at) : '-' }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        Vigência
      </h6>
      <div>{{ value.contract_effective_date ? formatDateTimeDDMMYYYY(value.contract_effective_date) : '-' }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        Instituição
      </h6>
      <div>{{ value.institution.name }}</div>
    </div>

    <h4 class="text-capitalize mt-2">
      Pagamento
    </h4>
    <div>
      <h6 class="text-capitalize mb-0">
        Dia de pagamento
      </h6>
      <div>{{ value.payday }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        Mês competência
      </h6>
      <div>{{ value.payment_month }}</div>
    </div>

    <h4 class="text-capitalize mt-2">
      Endereço
    </h4>
    <div>
      <h6 class="text-capitalize mb-0">
        Contato
      </h6>
      <div>{{ value.contact }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        Telefone
      </h6>
      <div>{{ value.phone }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        CEP
      </h6>
      <div>{{ formatCep(value.address.postal_code) }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        Cidade
      </h6>
      <div>{{ value.address.city.name }} - {{ value.address.city.state.abbreviation }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        Bairro
      </h6>
      <div>{{ value.address.neighborhood }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        Rua
      </h6>
      <div>{{ value.address.street }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        N°
      </h6>
      <div>{{ value.address.number }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        Complemento
      </h6>
      <div>{{ value.address.complement || '-' }}</div>
    </div>

    <h4 class="text-capitalize mt-2">
      Tipos
    </h4>
    <div
      v-for="duty in value.duties"
      :key="duty.id"
    >
      <div class="d-flex">
        <div class="mr-1">
          <h6 class="text-capitalize mb-0">
            Tipo
          </h6>
          <div>{{ duty.duty_type.type }}</div>
        </div>
        <div class="mr-1">
          <h6 class="text-capitalize mb-0">
            Complemento
          </h6>
          <div>{{ duty.complement }}</div>
        </div>
        <div class="mr-1">
          <h6 class="text-capitalize mb-0">
            Duração
          </h6>
          <div>{{ duty.duration }}h</div>
        </div>
        <div class="mr-1">
          <h6 class="text-capitalize mb-0">
            Remuneração
          </h6>
          <div>{{ formatCurrency(parseInt(duty.remuneration / 100, 10)) }}</div>
        </div>
      </div>
      <hr>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { format } from 'date-fns'
import {
  formatCep, formatCnpj, formatCurrency, formatPhone, formatDateTimeDDMMYYYY, formatDateTimeDDMMYYHHMM,
} from '@core/utils/utils'
import AuditHeader from './Header.vue'

export default {
  components: {
    AuditHeader,
  },
  props: {
    audit: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const value = ref(props.audit.current_values)
    const user = ref(props.audit.user)
    const createdAt = ref(props.audit.created_at)
    return {
      formatCep,
      formatCnpj,
      formatPhone,
      formatCurrency,
      value,
      user,
      createdAt,
      formatDateTimeDDMMYYYY,
      formatDateTimeDDMMYYHHMM,
      formatDate: format,
    }
  },
}
</script>

<style lang="scss" scoped>
hr {
  margin-top: 4px;
  margin-bottom: 10px;
}
h6 {
  margin-top: 3px;
}
</style>
