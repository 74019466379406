<template>
  <div>
    <audit-header :audit="audit" />
    <hr>
    <div>
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <div>
            <h6 class="text-capitalize mb-0">
              Fatura
            </h6>
            <div>
              Nº {{ pad(value.invoice_number, 6) }}
            </div>
          </div>
          <div class="mt-1">
            <h6 class="text-capitalize mb-0">
              Instituição
            </h6>
            <div>
              {{ value.institution.name }}
            </div>
          </div>
          <div class="mt-1">
            <h6 class="text-capitalize mb-0">
              Unidade
            </h6>
            <div>-</div>
          </div>
          <div class="mt-1">
            <h6 class="text-capitalize mb-0">
              Período
            </h6>
            <div>
              {{ formatDateTimeDDMMYYYY(value.start_date) }} -
              {{ formatDateTimeDDMMYYYY(value.end_date) }}
            </div>
          </div>
          <div class="mt-1">
            <h6 class="text-capitalize mb-0">
              Status
            </h6>
            <div>
              <b-badge
                v-if="value.status === 'paid'"
                pill
                variant="light-success"
              >
                Pago
              </b-badge>
              <b-badge
                v-if="value.status === 'open'"
                pill
                variant="light-primary"
              >
                Aberto
              </b-badge>
              <b-badge
                v-if="value.status === 'expired'"
                pill
                variant="light-danger"
              >
                Vencido
              </b-badge>
            </div>
          </div>
          <div class="mt-1">
            <h6 class="text-capitalize mb-0">
              Vencimento
            </h6>
            <div>
              {{ formatDateTimeDDMMYYYY(value.deadline) }}
            </div>
          </div>
          <div class="mt-1">
            <h6 class="text-capitalize mb-0">
              Pagamento
            </h6>
            <div>
              {{ value.paid_at ? formatDateTimeDDMMYYYY(value.paid_at) : '-' }}
              |
              {{ formatCurrencyInt(value.amount_paid) }}
            </div>
          </div>
          <div class="mt-1">
            <h6 class="text-capitalize mb-0">
              Outros Acrésc.
            </h6>
            <div>
              {{ formatCurrencyInt(value.additions || 0) }}
            </div>
          </div>
          <div class="mt-1">
            <h6 class="text-capitalize mb-0">
              Nota
            </h6>
            <div>
              {{ value.note || '-' }}
            </div>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="8"
        >
          <h6 class="mt-2 mt-md-0">
            Histórico de Reaberturas
          </h6>
          <div>
            <b-table
              ref="refReopenListTable"
              :items="() => value.reopen_history"
              responsive
              :fields="tableReopenColumns"
              primary-key="id"
              show-empty
              empty-text="Nenhum registro"
              class="position-relative"
              :small="true"
            >
              <template
                #cell(reopen_at)="data"
              >
                {{ formatDateTime(new Date(data.item.created_at), 'dd/MM/yy HH:mm') }}
              </template>
              <template
                #cell(reopen_user)="data"
              >
                {{ data.item.user.name }}
              </template>
            </b-table>
          </div>
        </b-col>
      </b-row>
      <h6 class="mt-2">
        Plantões
      </h6>
      <b-table
        ref="refInstitutionListTable"
        :items="() => value.physician_duties"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro"
        class="position-relative"
        small
      >
        <template #cell(physician_name)="data">
          {{ data.item.physician.user.name }}
        </template>
        <template #cell(physician_cpf)="data">
          {{ data.item.physician.user.cpf }}
        </template>
        <template #cell(start_date)="data">
          {{ formatDate(new Date(data.item.start_date), 'dd/MM/yy HH:mm') }} -
          {{ formatDate(new Date(data.item.end_date), 'dd/MM/yy HH:mm') }}
        </template>
        <template #cell(amount_paid)="data">
          {{ formatCurrencyInt(data.item.amount_paid) }}
        </template>

        <template slot="bottom-row">
          <td colspan="2" />
          <th class="text-right">
            TOTAL
          </th>
          <td>{{ formatCurrencyInt(value.total) }}</td>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  formatCurrencyInt, formatDate, formatDateTime, formatDateTimeDDMMYYYY, pad,
} from '@core/utils/utils'
import {
  BBadge, BCol, BRow, BTable,
} from 'bootstrap-vue'
import AuditHeader from './Header.vue'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BBadge,
    AuditHeader,
  },
  props: {
    audit: {
      required: true,
      type: Object,
    },
  },
  methods: {
    formatDate, formatCurrencyInt, formatDateTimeDDMMYYYY, pad,
  },
  setup(props) {
    const formatCurrency = val => Number(val).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    const value = ref(props.audit.current_values)
    value.value.total = props.audit.current_values.physician_duties.reduce((acc, s) => acc + s.amount_paid, 0)
    const tableReopenColumns = [
      {
        key: 'reopen_at',
        label: 'Data',
      },
      {
        key: 'reopen_reason',
        label: 'Motivo',
      },
      {
        key: 'reopen_user',
        label: 'Usuário',
      },
    ]
    const tableColumns = [
      {
        key: 'physician_name',
        label: 'Médico',
      },
      {
        key: 'physician_cpf',
        label: 'CPF',
      },
      {
        key: 'start_date',
        label: 'Data Plantão',
      },
      {
        key: 'amount_paid',
        label: 'Valor Plantão',
      },
    ]
    return {
      value,
      formatDateTime,
      formatCurrency,
      tableReopenColumns,
      tableColumns,
    }
  },
}
</script>

<style lang="scss" scoped>
hr {
  margin-top: 4px;
  margin-bottom: 10px;
}
</style>
