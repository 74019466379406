import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPhysicians(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/physician', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadReport(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/physician/index-export', { params: queryParams, responseType: 'blob' })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPhysician(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/physician/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addPhysician(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/physician', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    generateConfirmationUrl(ctx, { id, institutionId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/physician/${id}/confirmation-url`, { params: { institution_id: institutionId } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    generateConfirmationUrlFull(ctx, { id, institutionId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/physician/${id}/complete-confirmation-url`, { params: { institution_id: institutionId } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
