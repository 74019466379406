import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchFaqs(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/faq', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchFaq(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/faq/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addFaq(ctx, faqData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/faq', faqData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeFaq(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/faq/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateFaq(ctx, faqData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/faq/${faqData.id}`, faqData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateFaqsOrder(ctx, order) {
      return new Promise((resolve, reject) => {
        axios
          .get('/faq', order)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
