<template>
  <div>
    <b-card
      no-body
      class="view-physician-accounts"
    >
      <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
        <h5 class="mb-1">
          Contas Bancárias
        </h5>
      </b-card-header>

      <b-card-body>
        <b-table
          ref="refWorkplaceListTable"
          class="position-relative"
          :items="() => accounts"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="Nenhum registro correspondente encontrado"
        >
          <template #cell(status_checked)="data">
            <b-media vertical-align="center">
              <b-badge
                pill
                :variant="`light-${data.item.status_checked === 'approved' ? 'success' : 'danger'}`"
                class="text-capitalize"
              >
                {{ data.item.status_checked === 'approved' ? 'Aprovado' : 'Recusado' }}
              </b-badge>
            </b-media>
          </template>
          <template #cell(company)="{ item }">
            <div v-if="item.physician_company_cnpj">
              <strong>{{ item.physician_company_name }}</strong>
              <div>{{ formatCnpj(item.physician_company_cnpj) }}</div>
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                :disabled="data.item.status_checked !== 'approved'"
                @click="openRefuseAccount(data.item)"
              >
                <feather-icon icon="XCircleIcon" />
                <span class="align-middle ml-50">Recusar</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-card-body>
    </b-card>
    <b-modal
      id="modal-account"
      ref="accountModal"
      :title="`Recusar Conta ${currentAccount && currentAccount.account_number}`"
      hide-footer
      centered
    >
      <div>
        <b-form-group
          class="mt-1"
          label="Motivo"
          label-for="refuse-reason"
        >
          <b-form-textarea
            id="refuse-reason"
            v-model="currentAccount.refuse_reason"
            rows="4"
            placeholder="Descreva o motivo..."
          />
        </b-form-group>
        <div class="text-right">
          <b-button
            variant="primary"
            class="ml-1"
            :disabled="loading"
            @click="refuseAccount"
          >
            <span class="d-none d-sm-inline">Recusar</span>
            <feather-icon
              icon="RefreshCwIcon"
              class="d-inline d-sm-none"
            />
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BTable,
  BMedia,
  BBadge,
  BDropdown,
  BDropdownItem,
  BFormTextarea,
  BModal,
  BButton,
  BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { ref } from '@vue/composition-api'
import { formatCnpj } from '../../../@core/utils/utils'

export default {
  directives: {
    Ripple,
  },
  components: {
    BFormGroup,
    BButton,
    BModal,
    BFormTextarea,
    BDropdown,
    BDropdownItem,
    BTable,
    BBadge,
    BMedia,
    BCard,
    BCardHeader,
    BCardBody,
  },
  props: {
    physicianData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    accounts() {
      return this.physicianData && this.physicianData.accounts && this.physicianData.accounts.filter(acc => acc.status_checked === 'approved')
    },
  },
  methods: { formatCnpj },
  setup() {
    const tableColumns = [
      { key: 'bank_name', label: 'Banco' },
      {
        key: 'account_type',
        label: 'Tipo',
        formatter: v => (v === 'savings_account' ? 'Poupança' : 'Corrente'),
      },
      { key: 'branch_number', label: 'Nº Agência' },
      { key: 'branch_code', label: 'Dígito da Agência' },
      { key: 'account_number', label: 'Conta' },
      { key: 'account_code', label: 'Dígito' },
      { key: 'status_checked', label: 'Status' },
      { key: 'person_type', label: 'Tipo' },
      { key: 'company', label: 'Empresa' },
      { key: 'favorite', label: 'Favorito', formatter: v => (v ? 'Sim' : 'Não') },
      { key: 'actions', label: 'Ações' },
    ]

    const loading = ref(false)
    const currentAccount = ref({ refuse_reason: '', id: '', account: null })
    const accountModal = ref(null)

    function openRefuseAccount(account) {
      currentAccount.value = {
        id: account.id,
        refuse_reason: '',
        account,
      }
      accountModal.value.show()
    }

    function refuseAccount() {
      loading.value = true
      const data = {
        id: currentAccount.value.id,
        refuse_reason: currentAccount.value.refuse_reason,
      }
      store.dispatch('app-physician/refuseAccount', data)
        .then(() => {
          currentAccount.value.account.status_checked = 'refused'
          accountModal.value.hide()
          loading.value = false
        })
        .catch(() => {
          loading.value = false
        })
    }

    return {
      loading,
      accountModal,
      tableColumns,
      currentAccount,
      openRefuseAccount,
      refuseAccount,
    }
  },
}
</script>

<style >
  .view-physician-accounts .card-body {
    padding: 0;
  }
</style>
