<template>
  <div>
    <faq-setting-form
      :faq-id="faqId"
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      @refetch-data="fetchFaqs"
    />

    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row align-h="end">

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
              <b-button
                v-if="$can('manage', 'Faq')"
                variant="primary"
                @click="addFaq()"
              >
                <span class="text-nowrap">Cadastrar</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <draggable
        v-model="faqsData"
        :disabled="!$can('manage', 'Faq')"
        class="list-group list-group-flush cursor-move"
        tag="ul"
        :animation="200"
        ghost-class="moving-card"
        :move="onMove"
        @end="onEnd"
      >
        <transition-group
          type="transition"
          name="flip-list"
        >
          <b-list-group-item
            v-for="faq in faqsDataFiltered"
            :key="faq.id"
            tag="li"
          >
            <div class="d-flex align-items-center">
              <b-avatar
                :text="faq.order"
                variant="primary"
                class="mx-1"
              />
              <div class="d-flex flex-column move-icons">
                <feather-icon
                  icon="ChevronUpIcon"
                  size="20"
                />
                <feather-icon
                  icon="ChevronDownIcon"
                  size="20"
                />
              </div>
              <div class="ml-25 flex-grow-1">
                <b-card-text class="font-weight-bold mb-0">
                  {{ faq.question }}
                </b-card-text>
                <small>{{ faq.answer }}</small>
              </div>
              <div class="d-flex flex-column mx-1">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :disabled="!$can('manage', 'Faq')"
                  class="btn-icon rounded-circle mb-1"
                  variant="outline-primary"
                  @click="editFaq(faq)"
                >
                  <feather-icon icon="Edit3Icon" />
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :disabled="!$can('manage', 'Faq')"
                  class="btn-icon rounded-circle"
                  variant="outline-danger"
                  @click.prevent.stop="removeFaq(faq)"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </div>
            </div>
          </b-list-group-item>
        </transition-group>
      </draggable>
    </b-card>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import {
  BRow, BCol, BListGroupItem, BAvatar, BCardText, BFormInput, BCard, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { onUnmounted, ref, computed } from '@vue/composition-api'
import store from '@/store'
import faqStoreModule from '@/views/faq/faqStoreModule'
import FaqSettingForm from './FaqSettingForm.vue'

export default {
  components: {
    BRow,
    BCol,
    FaqSettingForm,
    draggable,
    BListGroupItem,
    BAvatar,
    BCardText,
    BFormInput,
    BCard,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      options: {},
    }
  },
  setup() {
    const faqsData = ref([])
    const faqId = ref(null)
    const searchQuery = ref('')
    const saving = ref(false)
    const formFocusEnabled = ref(false)

    const FAQ_APP_STORE_MODULE_NAME = 'app-faq'
    const isAddNewUserSidebarActive = ref(false)

    // Register module
    if (!store.hasModule(FAQ_APP_STORE_MODULE_NAME)) store.registerModule(FAQ_APP_STORE_MODULE_NAME, faqStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FAQ_APP_STORE_MODULE_NAME)) store.unregisterModule(FAQ_APP_STORE_MODULE_NAME)
    })

    function fetchFaqs() {
      store.dispatch('app-faq/fetchFaqs', {})
        .then(response => {
          /* eslint-disable no-param-reassign */
          faqsData.value = response.data.data.map((f, i) => {
            f.order = `${i + 1}`
            return f
          })
        })
    }
    fetchFaqs()

    const faqsDataFiltered = computed(() => faqsData.value.filter(
      f => ((new RegExp(searchQuery.value, 'i')).test(f.question)
        || (new RegExp(searchQuery.value, 'i')).test(f.answer)),
    ))

    function addFaq() {
      faqId.value = undefined
      isAddNewUserSidebarActive.value = true
    }

    function editFaq(f) {
      faqId.value = f.id
      isAddNewUserSidebarActive.value = true
    }

    function onMove() {
      return !saving.value
    }

    function updateFaqOrder() {
      /* eslint-disable no-param-reassign */
      faqsData.value = faqsData.value.map((f, i) => {
        f.order = `${i + 1}`
        return f
      })
    }

    async function onEnd(evt) {
      try {
        const faqBefore = faqsData.value[evt.newIndex - 1]
        const faqCurrent = faqsData.value[evt.newIndex]
        faqCurrent.insert_after_id = faqBefore?.id || null
        saving.value = true
        await store.dispatch('app-faq/updateFaq', faqCurrent)
        saving.value = false
        updateFaqOrder()
      } catch (error) {
        saving.value = false
      }
    }

    async function removeFaq(f) {
      const result = await this.$swal({
        title: 'Você tem certeza?',
        text: 'Você não será capaz de reverter isso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, removê-lo!',
        cancelButtonText: 'Não',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      })

      if (result.value) {
        try {
          saving.value = true
          await store.dispatch('app-faq/removeFaq', f.id)
          saving.value = false
          this.$swal({
            icon: 'success',
            title: 'Removido!',
            text: 'O FAQ foi removido.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          fetchFaqs()
        } catch (error) {
          saving.value = false
        }
      }
    }

    return {
      onMove,
      onEnd,
      faqsData,
      addFaq,
      editFaq,
      faqId,
      searchQuery,
      faqsDataFiltered,
      fetchFaqs,
      removeFaq,
      formFocusEnabled,
      isAddNewUserSidebarActive,
    }
  },
}
</script>

<style scoped>
.list-group-item {
  transition: all 1s;
  padding-right: 10px;
  padding-left: 10px;
}
.moving-card {
  opacity: 0.5;
  background: #F7FAFC;
  border: 1px solid #7367f0;
}
.filters {
  margin-bottom: 8px;
}
.edit-icon, .remove-icon {
  cursor: pointer;
  padding: 10px 0;
  margin: 0!important;
}
.add-box {
  margin-bottom: 0;
  border-radius: 0;
  border: 1px solid rgba(34, 41, 47, 0.125);
}
.move-icons {
  margin: 0 8px 0 0;
}
</style>
