import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    resendWebInvitation(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/${queryParams.userId}/resend-web-invitation`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAccessTypes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/access-type', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/institution/user', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id, institutionId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/institution/user/${id}`, { params: { institution_id: institutionId } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        const cpf = userData.cpf.replace(/\D/g, '')
        axios
          .post('/institution/user', { ...userData, cpf })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        const cpf = userData.cpf.replace(/\D/g, '')
        axios
          .put(`/institution/user/${userData.id}`, { ...userData, cpf })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
