<template>
  <quill-editor
    ref="myQuillEditor"
    v-model="contract.template"
    :options="editorOption"
  >
    <div
      id="toolbar"
      slot="toolbar"
    >
      <!-- Add a bold button -->
      <button class="ql-bold">
        Bold
      </button>
      <button class="ql-italic">
        Italic
      </button>

      <!-- Add font size dropdown -->
      <select class="ql-size">
        <option value="small" />
        <!-- Note a missing, thus falsy value, is used to reset to default -->
        <option selected />
        <option value="large" />
        <option value="huge" />
      </select>

      <select class="ql-font">
        <option selected="selected" />
        <option value="serif" />
        <option value="monospace" />
      </select>

      <!-- Add subscript and superscript buttons -->
      <button
        class="ql-script"
        value="sub"
      />
      <button
        class="ql-script"
        value="super"
      />
      <button
        v-for="marker in markers"
        :key="marker.value"
        class="w-auto btn btn-primary btn-marker ml-1"
        :class="{'btn-success': marker.enabled}"
        @click="addMarker(marker.value)"
      >
        <feather-icon
          icon="PlusIcon"
          size="16"
        />
        {{ marker.label }}
      </button>
    </div>
  </quill-editor>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import { computed, ref, watch } from '@vue/composition-api'

export default {
  components: {
    quillEditor,
  },
  props: {
    contract: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: '#toolbar',
        },
      },
    }
  },
  setup() {
    const myQuillEditor = ref(null)
    const editor = computed(() => myQuillEditor.value && myQuillEditor.value.quill)
    const markers = ref([
      { value: 'name', label: 'Nome do usuário', enabled: false },
      { value: 'cpf', label: 'CPF do usuário', enabled: false },
      { value: 'value', label: 'Valor', enabled: false },
      { value: 'value_full_name', label: 'Valor por extenso', enabled: false },
      { value: 'interest', label: 'Juros', enabled: false },
      { value: 'fine', label: 'Multa', enabled: false },
      { value: 'service_fee', label: 'Tx. Serviço', enabled: false },
      { value: 'cnpj_institution', label: 'CNPJ Instituição', enabled: false },
      { value: 'name_institution', label: 'Nome Instituição', enabled: false },
      { value: 'name_workplace', label: 'Nome Unidade', enabled: false },
      { value: 'start_date', label: 'Data Início', enabled: false },
      { value: 'end_date', label: 'Data Fim', enabled: false },
      { value: 'physician_address', label: 'Endereço do Médico', enabled: false },
      { value: 'date', label: 'Data', enabled: false },
      { value: 'due_date', label: 'D. Vencimento', enabled: false },
      { value: 'physician_company_name', label: 'Empresa médica Nome', enabled: false },
      { value: 'physician_company_cnpj', label: 'Empresa médica CNPJ', enabled: false },
    ])

    watch(() => editor.value, value => {
      value.on('selection-change', range => {
        const text = editor.value.getText(range.index - 10, 20)
        const matches = text.match(/{\w+}/)
        const marker = matches && matches[0] && matches[0].replace(/[{}]/g, '')
        markers.value.forEach(m => {
          // eslint-disable-next-line no-param-reassign
          m.enabled = m.value === marker
        })
      })
    })

    return {
      editor,
      markers,
      myQuillEditor,
    }
  },
  methods: {
    addMarker(type) {
      const content = `{${type}}`
      const selection = this.editor.getSelection()
      this.editor.insertText(selection.index, content)
    },
    hasMarker(label) {
      return this.currentMarkers.indexOf(label) !== -1
    },
  },
}
</script>
<style>
.btn-marker {
  padding: 5px!important;
  margin: 1px;
}
</style>
