import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchFinancialInstitutions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/financial-institution/list-all', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchFinancialInstitution(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/financial-institution/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addFinancialInstitution(ctx, dutyTypeData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/financial-institution', dutyTypeData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateFinancialInstitution(ctx, dutyTypeData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/financial-institution/${dutyTypeData.id}`, dutyTypeData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
