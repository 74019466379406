<template>
  <e-charts
    ref="line"
    autoresize
    :option="option"
    theme="theme-color"
    auto-resize
    :update-options="{notMerge:true}"
  />
</template>

<script>
// eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies
import { ref, toRef, watch } from '@vue/composition-api'
import ECharts from 'vue-echarts'
import {
  use,
  registerTheme,
} from 'echarts/core'
import {
  BarChart,
  LineChart,
  PieChart,
  LinesChart,
} from 'echarts/charts'
import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  ToolboxComponent,
  DataZoomComponent,
} from 'echarts/components'
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers'
import {
  formatCpf,
  formatCurrency, formatPercent, truncateString,
} from '@core/utils/utils'
import theme from '../theme.json'

use([
  BarChart,
  LineChart,
  PieChart,
  LinesChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  CanvasRenderer,
  SVGRenderer,
  ToolboxComponent,
  DataZoomComponent,
])
registerTheme('theme-color', theme)

export default {
  components: {
    ECharts,
  },
  props: {
    mobile: {
      type: Object,
      default: () => ({ mobile: false }),
    },
    year: {
      type: Number,
      default: 0,
    },
    chartData: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const chartData = toRef(props, 'chartData')
    const mobile = toRef(props, 'mobile')

    const year = toRef(props, 'year')
    const option = ref({
      title: {
        text: '',
        subtext: '',
      },
      tooltip: {
        trigger: 'item',
        formatter: data => `<b style="color:${data.color}">${data.data.name}</b><br/><b>${formatCpf(data.data.cpf)}</b><br/>${formatCurrency(data.value)}<br/><b>${formatPercent(data.percent)}</b>`,
      },
      grid: {
        right: 0,
      },
      legend: { },
      series: [
        {
          type: 'pie',
          radius: '65%',
          center: ['30%', '50%'],
          selectedMode: 'single',
          data: [],
          label: {
            formatter: ({ percent }) => formatPercent(percent),
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    })
    watch([chartData, mobile], ([data]) => {
      if (mobile.value.mobile) {
        option.value.series[0].radius = '50%'
        option.value.series[0].center = ['50%', '40%']
        option.value.legend = {
          ...(mobile.value.breakpoint === 'md' ? { type: 'scroll' } : {}),
          bottom: 0,
          left: 'center',
          data: [],
          formatter: name => truncateString(name, 20),
          tooltip: {
            show: true,
          },
        }
      } else {
        option.value.series[0].radius = '65%'
        option.value.series[0].center = ['30%', '50%']
        option.value.legend = {
          ...(mobile.value.breakpoint === 'md' ? { type: 'scroll' } : {}),
          itemGap: 4,
          itemHeight: 7,
          itemWidth: 15,
          orient: 'vertical',
          align: 'left',
          top: '7%',
          right: '1%',
          width: '27%',
          padding: 1,
          data: [],
          formatter: name => truncateString(name, 20),
          tooltip: {
            show: true,
          },
        }
      }

      if (!data || !data.total_year_per_physician) return

      const years = Object.keys(data.total_year_per_physician)
      const physicians = years.length > 0 ? Object.values(data.total_year_per_physician[years[0]]) : []
      option.value.title.text = `Vendas anuais em ${year.value}`
      option.value.title.subtext = 'por médico'
      option.value.series[0].data = physicians.map(i => ({
        value: i.total_advanced_year / 100,
        name: `${i.name}`,
        cpf: i.cpf || '00000000000',
        colorBy: 'data',
      }))
      option.value.legend.data = physicians.map(i => (`${i.name}`))
    }, { deep: true })

    return {
      option,
    }
  },
}
</script>
