import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useDutyTypesList() {
  // Use toast
  const toast = useToast()

  const refDutyTypeListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'type', sortable: true, label: 'Nome', sortKey: 'type',
    },
    { key: 'status' },
    { key: 'actions', label: 'Ações' },
  ]
  const perPage = ref(10)
  const totalDutyTypes = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('type')
  const isSortDirDesc = ref(false)
  const situationFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refDutyTypeListTable.value ? refDutyTypeListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDutyTypes.value,
    }
  })

  const refetchData = () => {
    refDutyTypeListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, situationFilter, statusFilter], () => {
    refetchData()
  })

  const fetchDutyTypes = (ctx, callback) => {
    store
      .dispatch('app-duty-type/fetchDutyTypes', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDirection: isSortDirDesc.value ? 'desc' : 'asc',
        situation: situationFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { data: dutyTypes } = response.data
        const dutyTypeList = dutyTypes.map(dutyType => {
          const p = { ...dutyType }
          return p
        })
        callback(dutyTypeList)
        totalDutyTypes.value = response.data.meta.pagination.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao buscar',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveDutyTypeStatusVariant = status => {
    if (status === false) return 'warning'
    if (status === true) return 'success'
    return 'primary'
  }

  return {
    fetchDutyTypes,
    tableColumns,
    perPage,
    currentPage,
    totalDutyTypes,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDutyTypeListTable,

    resolveDutyTypeStatusVariant,
    refetchData,

    // Extra Filters
    situationFilter,
    statusFilter,
  }
}
