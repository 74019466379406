<template>
  <e-charts
    ref="line"
    autoresize
    :option="option"
    theme="theme-color"
    auto-resize
    :update-options="{notMerge:true}"
  />
</template>

<script>
import ECharts from 'vue-echarts'
import { ref, toRef, watch } from '@vue/composition-api'
import { registerTheme, use } from 'echarts/core'
import {
  BarChart, LineChart, LinesChart, PieChart,
} from 'echarts/charts'
import {
  DatasetComponent,
  DataZoomComponent,
  GridComponent,
  LegendComponent,
  LegendScrollComponent, MarkLineComponent, MarkPointComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent,
} from 'echarts/components'
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers'
import { roundNumber } from '@core/utils/utils'
import theme from '../theme.json'

use([
  BarChart,
  LineChart,
  PieChart,
  LinesChart,
  LegendScrollComponent,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  CanvasRenderer,
  SVGRenderer,
  ToolboxComponent,
  DataZoomComponent,
  MarkLineComponent,
  MarkPointComponent,
])
registerTheme('theme-color', theme)

export default {
  components: {
    ECharts,
  },
  props: {
    mobile: {
      type: Object,
      default: () => ({ mobile: false }),
    },
    chartData: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const chartData = toRef(props, 'chartData')
    // const mobile = toRef(props, 'mobile')

    const option = ref(null)

    const app = { config: {} }
    app.config = {
      rotate: 90,
      align: 'left',
      verticalAlign: 'middle',
      position: 'insideBottom',
      distance: 15,
    }
    const labelOption = {
      show: true,
      position: app.config.position,
      distance: app.config.distance,
      align: app.config.align,
      verticalAlign: app.config.verticalAlign,
      rotate: app.config.rotate,
      formatter: '{name|{a}} | {c}',
      fontSize: 14,
      padding: 4,
      // textBorderColor: 'black',
      // backgroundColor: 'rgba(0,0,0,.05)',
      // borderColor: 'black',
      borderRadius: 2,
      rich: {
        // name: {
        //   color: '#fff',
        // },
      },
    }

    watch(chartData, data => {
      if (!Object.keys(data).length) return

      const institution = data[Object.keys(data)[0]]
      const years = Object.keys(institution.total_year).sort()
      const dimensions = years.map(year => (`${year}`))
      const series = years.map(year => ({
        name: `${year}`,
        type: 'bar',
        barGap: 0,
        label: labelOption,
        emphasis: {
          focus: 'series',
        },
        data: [
          roundNumber((institution.total_year[year] || 0) / 100),
          roundNumber((institution.average_year[year] || 0) / 100),
        ],
      }))

      option.value = {
        grid: {
          left: 0,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        legend: {
          bottom: 25,
          data: dimensions,
        },
        xAxis: [
          {
            type: 'category',
            axisTick: { show: false },
            data: ['Total Geral', 'Média'],
          },
        ],
        yAxis: [
          {
            type: 'value',
            show: false,
          },
        ],
        series,
      }
    })

    return {
      option,
    }
  },
}
</script>
