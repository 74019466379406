<template>

  <div>

    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :user-id="currentUserId"
      :institution-id="institutionId"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row align-h="end">

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
              <b-button
                variant="primary"
                :disabled="!$can('manage', 'Institution')"
                @click="addUser()"
              >
                <span class="text-nowrap">
                  Novo Usuário
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum registro correspondente encontrado"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.name)"
                :variant="`light-${resolveUserRoleVariant(data.item.situation)}`"
                :to="{ name: 'users-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
            <small class="text-muted text-truncate">{{ data.item.id }}</small>
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            v-if="data.item.institution"
            pill
            :variant="`light-${resolveUserInactivatedVariant(data.item.institution.inactivated_at)}`"
            class="text-capitalize"
          >
            {{ data.item.institution.inactivated_at ? 'Inativo' : 'Ativo' }}
          </b-badge>
          <b-badge
            v-else
            pill
            variant="light-success"
            class="text-capitalize"
          >
            Ativo
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :disabled="!$can('manage', 'Institution')"
              @click="editUser(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="!$can('manage', 'Institution')"
              @click="resendInvitation(data.item)"
            >
              <feather-icon icon="MailIcon" />
              <span class="align-middle ml-50">Enviar convite</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BMedia, BAvatar, BLink,
  BBadge, BPagination, BButton, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useUserList from './useUserList'
import institutionUserStoreModule from '../institutionUserStoreModule'
import UserListAddNew from './UserListAddNew.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    BButton,
    BDropdown,
    BDropdownItem,

    UserListAddNew,
  },
  directives: {
    Ripple,
  },
  props: {
    institutionId: {
      type: String,
      required: true,
    },
  },
  setup({ institutionId }) {
    const USER_APP_STORE_MODULE_NAME = 'app-institution-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, institutionUserStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)
    const currentUserId = ref(null)

    const statusOptions = [
      { label: 'Pendente', value: 'pending' },
      { label: 'Ativo', value: 'active' },
      { label: 'Inativo', value: 'inactive' },
    ]

    function addUser() {
      currentUserId.value = undefined
      isAddNewUserSidebarActive.value = true
    }

    function editUser(user) {
      currentUserId.value = user.id
      isAddNewUserSidebarActive.value = true
    }

    async function resendInvitation(user) {
      const result = await this.$swal({
        title: 'Você tem certeza?',
        text: `Enviar email de convite para ${user.email}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, enviar!',
        cancelButtonText: 'Não',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      })

      if (result.value) {
        const params = { institution: institutionId, userId: user.id }
        store
          .dispatch(`${USER_APP_STORE_MODULE_NAME}/resendWebInvitation`, params)
          .then(() => {
            Vue.swal({
              icon: 'success',
              title: 'Convite Enviado!',
              html: `<strong class="text-capitalize">${user.name.toLowerCase()}</strong> 
        receberá um convite no email <strong>${user.email}</strong>.`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Erro ao enviar convite',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    }

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserInactivatedVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      institutionFilter,
      statusFilter,
    } = useUserList()

    institutionFilter.value = institutionId

    return {

      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      addUser,
      editUser,
      resendInvitation,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserInactivatedVariant,

      currentUserId,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      institutionFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
