import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { resolveDeadlineVariant } from '@/views/financial/payment-remittance-list/usePaymentRemittanceList'
import { maskCnpj } from '@core/utils/utils'

export default function useUserList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)
  // Table Handlers
  const tableColumns = [
    { key: 'invoice_number', label: 'Código', formatter: c => `${c}`.padStart(6, '0') },
    { key: 'institution.name', label: 'Empresa' },
    { key: 'workplaces', label: 'Unidade' },
    { key: 'start_date', label: 'Período' },
    { key: 'total', label: 'Valor' },
    { key: 'status', label: 'Status' },
    { key: 'actions', label: 'Ações' },
  ]
  const perPage = ref(25)
  const totalInvoices = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const isSortDirDesc = ref(true)
  const contractList = ref([])
  const institutionId = ref(null)
  const workplaceId = ref(null)
  const statusFilter = ref(null)
  const institutionOptions = ref([])
  const workplaceOptions = ref([])
  const statusOptions = [
    { label: 'Todos', value: null },
    { label: 'Aberto', value: 'open' },
    { label: 'Pago', value: 'paid' },
    { label: 'Vencido', value: 'expired' },
  ]

  const checkAll = computed({
    get: () => {
      if (contractList.value.length === 0) return false
      return contractList.value.filter(user => user.checked).length === contractList.value.length
    },
    set: val => {
      contractList.value.forEach(user => {
        // eslint-disable-next-line no-param-reassign
        user.checked = val
      })
    },
  })

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, institutionId, workplaceId, statusFilter], () => {
    refetchData()
  })

  const getInvoices = (ctx, callback) => {
    store
      .dispatch('app-invoice/invoice', {
        page: currentPage.value,
        perPage: perPage.value,
        status: statusFilter.value || undefined,
        institution: institutionId.value ? institutionId.value.value : undefined,
        workplace: workplaceId.value ? workplaceId.value.value : undefined,
      })
      .then(response => {
        contractList.value = response.data.data
        callback(contractList.value)
        totalInvoices.value = response.data.meta.pagination.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao listar',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const createPaymentRemittance = async paymentDate => {
    const items = contractList.value.filter(contract => contract.checked).map(contract => ({ contract_id: contract.id, operation: null }))
    try {
      const { data } = await store
        .dispatch('app-invoice/createPaymentRemittance', {
          items,
          payment_date: paymentDate,
        })
      return {
        id: data.data.id,
        code: data.data.code,
      }
    } catch (err) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Erro',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
    return null
  }

  const fetchInstitutions = () => {
    store.dispatch('app-reports/fetchInstitutions', { perPage: 99999999 })
      .then(response => {
        institutionOptions.value = response.data.data.map(inst => ({
          value: inst.id,
          label: `${inst.name} | ${maskCnpj(inst.cnpj)}`,
        }))
      })
  }

  const fetchWorkplaces = () => {
    if (institutionId.value) {
      store.dispatch('app-invoice/fetchWorkplaceByInstitution', { institution_id: institutionId.value.value })
        .then(response => {
          workplaceOptions.value = response.data.data.map(w => ({
            value: w.id,
            label: w.name,
          }))
        })
    } else {
      workplaceOptions.value = []
      workplaceId.value = null
    }
  }

  return {
    getInvoices,
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    isSortDirDesc,
    refUserListTable,
    refetchData,
    fetchInstitutions,
    institutionId,
    workplaceId,
    institutionOptions,
    fetchWorkplaces,
    workplaceOptions,
    checkAll,
    createPaymentRemittance,
    statusFilter,
    statusOptions,
    resolveDeadlineVariant,
  }
}
