var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"mx-2 mb-2 mt-1"},[_c('b-row',[_c('b-col',{staticClass:"mt-1 mt-lg-0 d-flex align-items-end justify-content-start",attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end w-100"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.downloadReport}},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Exportar "),_c('feather-icon',{attrs:{"icon":"DownloadIcon","size":"16"}})],1)])],1)])],1)],1),_c('b-table',{ref:"refUserListTable",staticClass:"position-relative",attrs:{"items":_vm.contractCanceledAdvancedHistory,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","sort-by":_vm.sortBy,"show-empty":"","empty-text":"Nenhum registro correspondente encontrado","sort-desc":_vm.isSortDirDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event},"row-clicked":function (item) { item.checked = !item.checked }},scopedSlots:_vm._u([{key:"cell(canceled_by)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.canceled_by))]),_c('div',{staticClass:"row-subtitle"},[_vm._v(" "+_vm._s(_vm.formatISODateTimeDDMMYYHHMMSS(item.canceled_at))+" ")])]}},{key:"cell(data.value_physician_duty)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatCurrencyInt(item.data.value_physician_duty)))]),_c('div',{staticClass:"row-subtitle"},[_vm._v(" "+_vm._s(_vm.formatDateTimeDDMMYYHHMM(item.data.start_date))+" - "+_vm._s(_vm.formatDateTimeDDMMYYHHMM(item.data.end_date))+" ")])]}},{key:"cell(data.institution_name)",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('strong',[_vm._v(_vm._s(item.data.institution_name))])]),_c('div',{staticClass:"row-subtitle"},[_vm._v(" "+_vm._s(item.data.workplace_name)+" ")])]}},{key:"cell(data.physician_name)",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('strong',[_vm._v(_vm._s(item.data.physician_name))])]),_c('div',{staticClass:"row-subtitle"},[_vm._v(" CPF: "+_vm._s(_vm.maskHiddenCpf2(item.data.physician_cpf))+" ")])]}},{key:"cell(data.company)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{attrs:{"pill":"","variant":item.person_type === 'PJ' ? 'light-danger' : 'light-success'}},[_vm._v(" "+_vm._s(item.person_type || 'PF')+" ")]),_c('div',[_c('strong',[_vm._v(_vm._s(item.data.company_name || item.data.physician_name))]),_c('div',[_vm._v(_vm._s(item.data.company_cnpj ? _vm.formatCnpj(item.data.company_cnpj) : _vm.maskHiddenCpf2(item.data.physician_cpf)))])])]}},{key:"cell(data.account)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"account-info"},[_c('div',[_vm._v("Banco")]),_c('div',[_vm._v(_vm._s(item.data.account_bank_code)+" - "+_vm._s(item.data.account_bank_name))])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"account-info"},[_c('div',[_vm._v("Agência")]),_c('div',[_vm._v(_vm._s(item.data.branch_number)),(item.data.branch_code)?_c('span',[_vm._v("-"+_vm._s(item.data.branch_code))]):_vm._e()])]),_c('div',{staticClass:"account-info"},[_c('div',[_vm._v(_vm._s(_vm.resolveAccountType(item.data.account_type)))]),_c('div',[_vm._v(_vm._s(item.data.account_number)+"-"+_vm._s(item.data.account_code))])])])])]}},{key:"cell(amount_physician_duty)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.amount_physician_duty))+" ")]}}])}),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v("Exibindo "+_vm._s(_vm.dataMeta.from)+" a "+_vm._s(_vm.dataMeta.to)+" de "+_vm._s(_vm.dataMeta.of)+" entradas")])]),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalUsers,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }