import Vue from 'vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import flatpickr from 'flatpickr'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format'

flatpickr.localize(Portuguese) // default locale is now Russian

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.use(VueFilterDateFormat)
