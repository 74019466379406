<template>
  <div>
    <partner-list-add-new
      :is-add-new-partner-sidebar-active.sync="isAddNewPartnerSidebarActive"
      :partner-id="currentPartnerId"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row align-h="end">
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibir</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entradas</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
                debounce="500"
              />
              <b-button
                v-if="$can('manage', 'Partner')"
                variant="primary"
                @click="addPartner()"
              >
                <span class="text-nowrap">
                  Novo Parceiro
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refPartnerListTable"
        class="position-relative"
        :items="fetchPartners"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum registro correspondente encontrado"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(title)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.image.download_link"
              />
            </template>
            <div
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.title }}
            </div>
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolvePartnerStatusVariant(data.item.inactivated_at)}`"
            class="text-capitalize"
          >
            {{ data.item.inactivated_at ? 'Inativo' : 'Ativo' }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :disabled="!$can('manage', 'Partner')"
              @click="editPartner(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalPartners"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BMedia, BAvatar,
  BBadge, BPagination, BButton, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vSelect from 'vue-select'
import { onUnmounted, ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import usePartnersList from './usePartnersList'
import partnerStoreModule from '../partnerStoreModule'
import PartnerListAddNew from './PartnerListAddNew.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,

    PartnerListAddNew,
    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-partner'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, partnerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const isAddNewPartnerSidebarActive = ref(false)
    const currentPartnerId = ref(null)

    function addPartner() {
      currentPartnerId.value = undefined
      isAddNewPartnerSidebarActive.value = true
    }

    function editPartner(partner) {
      currentPartnerId.value = partner.id
      isAddNewPartnerSidebarActive.value = true
    }

    const {
      fetchPartners,
      tableColumns,
      perPage,
      currentPage,
      totalPartners,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPartnerListTable,
      refetchData,

      // UI
      resolvePartnerRoleVariant,
      resolvePartnerRoleIcon,
      resolvePartnerStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      situationFilter,
      statusFilter,
    } = usePartnersList()

    return {
      isAddNewPartnerSidebarActive,
      fetchPartners,
      tableColumns,
      perPage,
      currentPage,
      totalPartners,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPartnerListTable,
      refetchData,
      addPartner,
      editPartner,
      // Filter
      avatarText,
      // UI
      resolvePartnerRoleVariant,
      resolvePartnerRoleIcon,
      resolvePartnerStatusVariant,
      currentPartnerId,
      // Extra Filters
      roleFilter,
      planFilter,
      situationFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
