<template>
  <div>
    <back-btn />
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="contractData === undefined"
    >
      <h4 class="alert-heading">
        Erro ao buscar dados do contrato
      </h4>
      <div class="alert-body">
        Nenhum contrato encontrado com este ID de contrato. Verificar
        <b-link
          class="alert-link"
          :to="{ name: 'users-list'}"
        >
          Lista
        </b-link>
        para outros contratos.
      </div>
    </b-alert>

    <template v-if="contractData">
      <b-row>
        <b-col
          cols="12"
          xl="6"
          lg="8"
          md="12"
          class="d-flex justify-content-between flex-column"
        >
          <b-card>
            <h2>Contrato de {{ resolveContractType(contractData.type) }}</h2>
            <h5 class="mt-2">
              {{ contractData.description }}
            </h5>
          </b-card>
          <b-card>
            <!-- eslint-disable vue/no-v-html -->
            <span v-html="contractData.template" />
            <!-- eslint-enable vue/no-v-html -->
          </b-card>
        </b-col>
      </b-row>
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink, BCard,
} from 'bootstrap-vue'
import useContractsList from '@/views/contract/contract-list/useContractList'
import BackBtn from '@core/components/navigation/BackBtn.vue'
import contractStoreModule from '../contractStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,
    BackBtn,

    // Local Components
  },
  setup() {
    const contractData = ref(null)

    const CONTRACT_APP_STORE_MODULE_NAME = 'app-contract'

    // Register module
    if (!store.hasModule(CONTRACT_APP_STORE_MODULE_NAME)) store.registerModule(CONTRACT_APP_STORE_MODULE_NAME, contractStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CONTRACT_APP_STORE_MODULE_NAME)) store.unregisterModule(CONTRACT_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-contract/fetchContract', { id: router.currentRoute.params.id })
      .then(response => { contractData.value = response.data.data })
      .catch(error => {
        if (error.response.status === 404) {
          contractData.value = undefined
        }
      })

    const {
      resolveContractType,
    } = useContractsList()

    return {
      contractData,
      resolveContractType,
    }
  },
}
</script>

<style>

</style>
