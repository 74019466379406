import { ref, watch } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  endOfDay, format, startOfDay,
} from 'date-fns'

// 'primary', 'primary', 'success', 'danger', 'warning', 'info', 'dark'
export const resolveTypeVariant = type => {
  if (type === 'Institution') return 'success'
  if (type === 'PhysicianDuty') return 'warning'
  return 'primary'
}

export const resolveEventVariant = event => {
  if (/create/.test(event)) return 'success'
  if (/physician-duty/.test(event)) return 'danger'
  if (/payment-remittance/.test(event)) return 'info'
  return 'primary'
}

export default function useAuditList() {
  // Use toast
  const toast = useToast()

  const refInstitutionListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'type',
      label: 'Entidade',
    },
    { key: 'event', label: 'Evento' },
    {
      key: 'physician_name',
      label: 'Médico',
    },
    {
      key: 'physician_cpf',
      label: 'CPF',
    },
    { key: 'account', sortable: false, label: 'Conta' },
    {
      key: 'data.company',
      label: 'Favorecido',
      sortable: false,
    },
    {
      key: 'favorite',
      label: 'Favoritada',
      formatter: value => (value === true ? 'Sim' : 'Não'),
    },
    {
      key: 'created_at',
      label: 'Data',
    },
  ]
  const perPage = ref(50)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100, 200]
  const searchQuery = ref('')
  const sortBy = ref('created_at')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const beginDate = ref(format(startOfDay(new Date()), 'yyyy-MM-dd'))
  const endDate = ref(format(endOfDay(new Date()), 'yyyy-MM-dd'))
  const userId = ref('')
  const userOptions = ref([])

  const refetchData = () => {
    refInstitutionListTable.value.refresh()
  }

  watch([
    currentPage,
    perPage,
    searchQuery,
    statusFilter,
    beginDate,
    endDate,
    userId,
  ], () => {
    refetchData()
  })

  const fetchAudits = (ctx, callback) => {
    store
      .dispatch('app-account-history/fetchAccountAudits', {
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDirection: isSortDirDesc.value ? 'desc' : 'asc',
        begin: beginDate.value,
        end: endDate.value,
        user: userId.value ? userId.value.value.id : '',
      })
      .then(response => {
        const { data, meta } = response.data
        callback(data)
        totalItems.value = meta.pagination.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao buscar',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchAudits,
    resolveEventVariant,
    tableColumns,
    resolveTypeVariant,
    perPage,
    currentPage,
    totalItems,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refInstitutionListTable,

    refetchData,
    beginDate,
    endDate,
    userId,
    userOptions,
  }
}
