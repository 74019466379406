<template>

  <div>
    <physician-list-add-new
      :is-add-new-physician-sidebar-active.sync="isAddNewPhysicianSidebarActive"
      :physician-id="currentPhysicianId"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="12"
            lg="7"
            class="
              d-flex
              align-items-center
              justify-content-start
              mb-1 mb-lg-0
            "
          >
            <v-select
              id="status-id"
              v-model="scheduleRestrictionFilter"
              class="w-75 mr-1"
              name="status-id"
              :options="scheduleRestrictionOptions"
              label="label"
              item-value="value"
              item-text="label"
              placeholder="Restrição"
              :clearable="false"
            />
            <v-select
              id="classification-id"
              v-model="classificationFilter"
              class="w-100 mr-1"
              name="classification-id"
              :options="classificationOptions"
              label="label"
              item-value="value"
              item-text="label"
              placeholder="Classificação"
            >
              <template #option="{ label, value }">
                <feather-icon
                  icon="SmartphoneIcon"
                  size="20"
                  :color="getClassificationColor(value)"
                />
                <span>{{ label }}</span>
              </template>
              <template
                #selected-option="{ label, value }"
                class="d-flex align-items-center justify-content-start"
              >
                <feather-icon
                  icon="SmartphoneIcon"
                  size="20"
                  :color="getClassificationColor(value)"
                />
                <span class="d-none d-xl-inline">{{ label.length > 17 ? label.substring(0, 16) + '...' : label }}</span>
              </template>
            </v-select>
            <v-select
              id="status-id"
              v-model="statusFilter"
              class="w-75"
              name="status-id"
              :options="statusOptions"
              label="label"
              item-value="value"
              item-text="label"
              placeholder="Status"
            />
          </b-col>
          <!-- Search -->
          <b-col
            class="mt-1 mt-lg-0 d-flex align-items-center justify-content-start"
            cols="12"
            md="12"
            lg="5"
          >
            <div class="d-flex align-items-center justify-content-end w-100">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
                debounce="500"
              />
              <b-button
                variant="primary"
                @click="downloadReport"
              >
                <span class="text-nowrap">
                  Exportar
                  <feather-icon
                    icon="DownloadIcon"
                    size="16"
                  />
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refPhysicianListTable"
        class="position-relative"
        :items="fetchPhysicians"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum registro correspondente encontrado"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'physician-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              <feather-icon
                v-if="data.item.schedule_restriction"
                :id="`schedule-restriction${data.item.id}`"
                icon="AlertCircleIcon"
                color="#F97794"
                size="16"
              />
              <b-tooltip
                v-if="data.item.schedule_restriction"
                :target="`schedule-restriction${data.item.id}`"
                placement="top"
              >
                <p class="mb-0">
                  {{ data.item.schedule_restriction_reason }}
                </p>
              </b-tooltip>
              {{ data.item.name }}
            </b-link>
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolvePhysicianStatusVariant(data.item.confirmed_at)}`"
            class="text-capitalize"
          >
            {{ data.item.confirmed_at ? 'Ativo' : 'Inativo' }}
          </b-badge>
          <span
            :title="data.item.classification_label"
          >
            <feather-icon
              icon="SmartphoneIcon"
              size="20"
              :color="getClassificationColor(data.item.classification_color)"
            />
          </span>
        </template>

        <!-- Column: Institution -->
        <template #cell(institution)="data">
          <b-link
            :to="{ name: 'institution-view', params: { id: data.item.institution.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.institution.name }}
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :disabled="!$can('manage', 'Physician')"
              @click="editUser(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalPhysicians"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BMedia, BLink,
  BBadge, BPagination, BButton, BTooltip, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import usePhysicianList from './usePhysicianList'
import physicianStoreModule from '../physicianStoreModule'
import PhysicianListAddNew from './PhysicianListAddNew.vue'

export default {
  components: {
    PhysicianListAddNew,
    BDropdownItem,
    BDropdown,
    BCard,
    BTooltip,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BPagination,
    BButton,

    vSelect,
  },
  setup() {
    const PHYSICIAN_APP_STORE_MODULE_NAME = 'app-physician'

    // Register module
    if (!store.hasModule(PHYSICIAN_APP_STORE_MODULE_NAME)) store.registerModule(PHYSICIAN_APP_STORE_MODULE_NAME, physicianStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PHYSICIAN_APP_STORE_MODULE_NAME)) store.unregisterModule(PHYSICIAN_APP_STORE_MODULE_NAME)
    })

    const paymentStatusOptions = []

    const scheduleRestrictionOptions = [
      { label: 'Todos', value: null },
      { label: 'Com restrição', value: 'yes' },
      { label: 'Sem restrição', value: 'no' },
    ]
    const statusOptions = [
      { label: 'Ativo', value: '1' },
      { label: 'Inativo', value: '0' },
    ]
    const classificationOptions = [
      { label: 'Não confirmado', value: 'red' },
      { label: 'Ativo e não adiantou nos últimos 30 dias.', value: 'yellow' },
      { label: 'Ativo e adiantou nos últimos 30 dias.', value: 'green' },
    ]
    const isAddNewPhysicianSidebarActive = ref(false)
    const currentPhysicianId = ref(null)

    const getClassificationColor = color => {
      const colors = {
        red: '#df7273',
        yellow: '#ffb976',
        green: '#48da89',
      }
      return colors[color]
    }

    const {
      fetchPhysicians,
      tableColumns,
      perPage,
      currentPage,
      totalPhysicians,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPhysicianListTable,
      refetchData,
      downloadReport,

      // UI
      resolvePhysicianRoleVariant,
      resolvePhysicianRoleIcon,
      resolvePhysicianStatusVariant,

      // Extra Filters
      situationFilter,
      statusFilter,
      classificationFilter,
      scheduleRestrictionFilter,
    } = usePhysicianList()

    function editUser(user) {
      currentPhysicianId.value = user.id
      isAddNewPhysicianSidebarActive.value = true
    }

    return {
      editUser,
      currentPhysicianId,
      isAddNewPhysicianSidebarActive,
      downloadReport,
      fetchPhysicians,
      tableColumns,
      perPage,
      currentPage,
      totalPhysicians,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPhysicianListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolvePhysicianRoleVariant,
      resolvePhysicianRoleIcon,
      resolvePhysicianStatusVariant,

      paymentStatusOptions,
      statusOptions,

      // Extra Filters
      situationFilter,
      statusFilter,
      getClassificationColor,
      classificationOptions,
      classificationFilter,
      scheduleRestrictionOptions,
      scheduleRestrictionFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.physician-class {
  width: 15px;
  height: 15px;
  border-spacing: 0!important;
  margin-top: 2px;
}
.physician-class {
  width: 15px;
  height: 15px;
  border-spacing: 0!important;
  margin-top: 2px;
  &:before {
    content: '';
    transform: translateY(3px);
    width: 15px;
    height: 15px;
    display: inline-block;
    border-radius: 15px;
    background-position: center center;
    background-repeat: no-repeat;
  }
  &-red {
    &:before {
      background-color: #f08182;
    }
  }
  &-yellow {
    &:before {
      background-color: #ffb976;
    }
  }
  &-green {
    &:before {
      background-color: #48da89;
    }
  }
}
@media (max-width: 1400px) {
  .v-select {
    font-size: 12px;
  }
}
.v-select {
  .physician-class {
    &:before {
      transform: translateY(2px) translateX(-4px);
    }
  }
}
.vs__selected {
  .physician-class {
    &:before {
      transform: translateY(-4px) translateX(-2px);
    }
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
