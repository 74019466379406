<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <div class="d-flex align-items-start justify-content-start mt-2 ml-1">
            <div>
              <p class="mr-1">
                Exibir empresa Imediato?
              </p>
            </div>
            <b-form-checkbox
              :checked="showImediato"
              class="custom-control-secondary"
              name="check-button"
              switch
              @input="showImediato = !showImediato;"
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <div class="d-flex align-items-end justify-content-end my-1 ml-2">
            <b-button
              class="mr-1"
              variant="outline-primary"
              :to="{name: 'payment-remittance-delete-history'}"
            >
              Cancelamentos <feather-icon icon="ExternalLinkIcon" />
            </b-button>
          </div>
        </b-col>
      </b-row>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="contractNotInPaymentRemittance"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum registro correspondente encontrado"
        :sort-desc.sync="isSortDirDesc"
        @row-clicked="onRowClicked"
      >
        <!-- Column: created_at -->
        <template
          #cell(created_at)="{ item }"
          class="w-100"
        >
          {{ formatDateTimeDDMMYYHHMM(new Date(item.created_at)) }}
        </template>

        <!-- Column: code -->
        <template
          #cell(code)="{ item }"
          class="w-100"
        >
          <strong>{{ item.code }}</strong>
        </template>

        <template
          #cell(amount_physician_duty)="{ item }"
          class="w-100"
        >
          {{ formatCurrency(item.amount_physician_duty) }}
        </template>
        <template
          #cell(amount_with_discount)="{ item }"
          class="w-100"
        >
          {{ formatCurrency(item.amount_with_discount) }}
        </template>

        <!-- Column: status -->
        <template #cell(status)="{ item }">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(item.status)}`"
            class="text-capitalize"
          >
            {{ item.status_title }}
          </b-badge>
        </template>

        <!-- Column: deadline -->
        <template #cell(deadline)="{ item }">
          <b-badge
            pill
            :variant="`${resolveDeadlineVariant(item.deadline_level)}`"
            class="text-capitalize"
          >
            {{ item.deadline }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'payment-remittance-view', params: { id: data.item.id } }">
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">Detalhes</span>
            </b-dropdown-item>
            <b-dropdown-item @click="exportPaymentRemittance(data.item)">
              <feather-icon icon="DownloadIcon" />
              <span class="align-middle ml-50">Exportar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal
      id="modal-paymentRemittance"
      ref="paymentRemittanceModal"
      title="Remessa criada"
      hide-footer
    >
      <b-card-text v-if="paymentRemittance">
        <b-link
          :to="{ name: 'users-list'}"
        >
          Acessar remessa
          <h5 class="d-inline">
            #{{ paymentRemittance.code }}
          </h5>
          <feather-icon icon="ExternalLinkIcon" />
        </b-link>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BLink,
  BBadge, BPagination, BModal, BCardText, BDropdown, BDropdownItem, BFormCheckbox, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { formatCurrency, formatDateTimeDDMMYYHHMM, useRouter } from '@core/utils/utils'
import { format } from 'date-fns'
import usePaymentRemittance from './usePaymentRemittanceList'
import contractStoreModule from '../contractStoreModule'

export default {
  components: {
    BButton,
    BLink,
    BModal,
    BFormCheckbox,
    BCardText,
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BPagination,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  setup() {
    const { router } = useRouter()
    const CONTRACT_APP_STORE_MODULE_NAME = 'app-contract'

    // Register module
    if (!store.hasModule(CONTRACT_APP_STORE_MODULE_NAME)) store.registerModule(CONTRACT_APP_STORE_MODULE_NAME, contractStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CONTRACT_APP_STORE_MODULE_NAME)) store.unregisterModule(CONTRACT_APP_STORE_MODULE_NAME)
    })

    const beginDate = ref('')
    const endDate = ref('')
    const isAddNewUserSidebarActive = ref(false)
    const currentUserId = ref(null)
    const paymentRemittanceModal = ref(null)
    const paymentRemittance = ref(null)

    function load() {
      //
    }

    function exportPaymentRemittance(item) {
      store.dispatch('app-contract/exportPaymentRemittance', { id: item.id })
        .then(response => {
          const blob = new Blob([response.data], { type: response.headers['content-type'] })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          const now = format(new Date(), 'yyyy-MM-dd hh:mm:ss')
          link.download = `Remessa_${item.code}_${now}.xlsx`
          link.click()
          URL.revokeObjectURL(link.href)
        })
    }

    function onRowClicked(item) {
      router.push({ name: 'payment-remittance-view', params: { id: item.id } })
    }

    const {
      contractNotInPaymentRemittance,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      showImediato,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveDeadlineVariant,
      resolveStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      situationFilter,
      statusFilter,
      createPaymentRemittance,
    } = usePaymentRemittance()

    async function authorize() {
      const result = await this.$swal({
        title: 'Prosseguir com criação da remessa?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Sim, criar!',
        cancelButtonText: 'Não',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      })
      if (!result.value) return

      paymentRemittance.value = null
      paymentRemittance.value = await createPaymentRemittance()
      refetchData()

      const text = `Acessar remessa criado Nº${paymentRemittance.value.code}`
      const resultCreated = await this.$swal({
        title: 'Remessa criado!',
        text,
        icon: 'success',
        showCancelButton: true,
        confirmButtonText: 'Acessar',
        cancelButtonText: 'Fechar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      })
      if (resultCreated.value) {
        this.$router.push({
          name: 'payment-remittance-view',
          params: { id: paymentRemittance.value.id },
        })
      }
    }

    return {
      isAddNewUserSidebarActive,

      contractNotInPaymentRemittance,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      authorize,
      beginDate,
      endDate,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveDeadlineVariant,
      resolveStatusVariant,

      currentUserId,
      showImediato,
      // Extra Filters
      roleFilter,
      planFilter,
      situationFilter,
      statusFilter,
      load,
      formatCurrency,
      formatDateTimeDDMMYYHHMM,
      createPaymentRemittance,
      paymentRemittanceModal,
      paymentRemittance,
      onRowClicked,
      exportPaymentRemittance,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.dates-row {
  max-width: 500px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

tr:has(.custom-checkbox.checked) {
  background-color: rgba(98, 98, 98, 0.05);
}
tbody {
  cursor: pointer;
}
</style>
