<template>
  <div style="height: inherit">
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row class="justify-content-end">
          <b-col
            cols="12"
            md="3"
            xl="2"
          >
            <div class="d-flex align-items-center font-small-2 justify-content-end">
              <b-button
                variant="outline-primary"
                class="action"
                @click="previousDate"
              >
                <feather-icon
                  size="18"
                  icon="ChevronLeftIcon"
                />
              </b-button>
              <div class="title">
                {{ dateTitle }}
              </div>
              <b-button
                variant="outline-primary"
                class="action"
                :disabled="disabledNext"
                @click="nextDate"
              >
                <feather-icon
                  size="18"
                  icon="ChevronRightIcon"
                />
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <!-- institutions -->
            <div
              v-if="conflicts.length === 0"
              class="m-5"
            >
              <h4 class="text-center w-100">
                Não há conflitos
              </h4>
            </div>
            <app-collapse
              accordion
              type="border"
            >
              <app-collapse-item
                v-for="item in conflicts"
                :key="item.id"
                :title="item.institution_name"
              >
                <template v-slot:header>
                  <div class="d-flex justify-content-start w-100">
                    <div>
                      <h4>
                        {{ item.institution_name }}
                      </h4>
                      <h6 class="text-muted mb-0">
                        {{ item.workplace_name }}
                      </h6>
                      <h6 class="text-muted font-small-1">
                        {{ formatCnpj(item.cnpj) }}
                      </h6>
                    </div>
                    <div class="text-left ml-3 d-flex">
                      <div>
                        <div>
                          <span class="text-muted">CPF</span>
                          <span class="collapse-title"> {{ formatCpf(item.cpf) }}</span>
                        </div>
                        <div>
                          <span class="text-muted">Médico</span>
                          <span class="collapse-title"> {{ item.physician_name }}</span>
                        </div>
                      </div>
                      <div class="ml-2">
                        <div>
                          <span class="text-muted">Plantão</span>
                          <span class="collapse-title">
                            {{ formatDateTimeDDMMYYHHMM(item.start_date) }}
                            -
                            {{ formatDateTimeDDMMYYHHMM(item.end_date) }}
                          </span>
                        </div>
                        <div>
                          <span class="text-muted">Serviço</span>
                          <span class="collapse-title"> {{ item.type_duty }}</span>
                        </div>
                      </div>
                      <div class="ml-2 mb-2">
                        <div>
                          <span class="text-muted">Valor Plantão</span>
                          <span class="collapse-title">
                            {{ formatCurrencyInt(item.amount_paid || 0) }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <!-- Physician -->
                <h5>Conflitos</h5>
                <b-table
                  :items="() => item.conflicts"
                  responsive
                  :fields="[
                    { key: 'physician_name', label: 'Médico' },
                    { key: 'institution_name', label: 'Instituição' },
                    { key: 'workplace_name', label: 'Unidades' },
                    { key: 'start_date', label: 'Plantão' },
                    { key: 'type_duty', label: 'Serviço' },
                    { key: 'amount_paid', label: 'Valor' },
                  ]"
                  primary-key="id"
                  class="position-relative"
                  small
                >
                  <template #cell(physician_name)="{ item: {physician_name, cpf} }">
                    <div class="physician-name">
                      {{ physician_name }}
                    </div>
                    <div class="text-muted font-small-2">
                      {{ formatCpf(cpf) }}
                    </div>
                  </template>
                  <template #cell(institution_name)="{ item: {institution_name} }">
                    <div
                      class="text-truncate overflow-hidden"
                      style="max-width: 250px"
                      :title="institution_name"
                    >
                      {{ institution_name }}
                    </div>
                  </template>
                  <template #cell(start_date)="{ item: {start_date, end_date} }">
                    {{ formatDateTimeDDMMYYHHMM(start_date) }} - {{ formatDateTimeDDMMYYHHMM(end_date) }}
                  </template>
                  <template #cell(amount_paid)="{ item: {amount_paid} }">
                    {{ formatCurrencyInt(amount_paid || 0) }}
                  </template>
                </b-table>
              </app-collapse-item>
            </app-collapse>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { computed, onUnmounted } from '@vue/composition-api'
import {
  BCard, BRow, BCol, BButton, BTable,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import { addMonths, format, isFuture } from 'date-fns'
import ptBrLocale from 'date-fns/locale/pt-BR'
import {
  formatCnpj, formatCpf, formatCurrencyInt, formatDateTimeDDMMYYHHMM, formatPercent,
} from '@core/utils/utils'
import reportStoreModule from '../reportStoreModule'
import usePhysicianDutyConflict from './usePhysicianDutyConflict'

export default {
  directives: {
    Ripple,
  },
  components: {
    BTable,
    BButton,
    AppCollapse,
    AppCollapseItem,
    BCard,
    BRow,
    BCol,
  },
  methods: {
    formatPercent, formatCurrencyInt, formatCnpj, formatCpf, formatDateTimeDDMMYYHHMM,
  },
  setup() {
    const REPORT_STORE_MODULE_NAME = 'app-reports'
    // Register module
    if (!store.hasModule(REPORT_STORE_MODULE_NAME)) store.registerModule(REPORT_STORE_MODULE_NAME, reportStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORT_STORE_MODULE_NAME)) store.unregisterModule(REPORT_STORE_MODULE_NAME)
    })

    const {
      conflicts,
      date,
      previousDate,
      nextDate,
    } = usePhysicianDutyConflict()

    const dateTitle = computed(() => format(date.value, 'MMMM yyyy', { locale: ptBrLocale }).toUpperCase())
    const disabledNext = computed(() => isFuture(addMonths(date.value, 1)))

    return {
      conflicts,
      dateTitle,
      previousDate,
      nextDate,
      disabledNext,
    }
  },
}
</script>

<style lang="scss" scoped>
.text-muted {
  color: #898993!important;
}
.duty-date, .duty-date .collapse-title {
  font-size: 12px;
}
.physician-group-item {
  padding-top: .25rem;
  padding-bottom: .25rem;
  .collapse-title, .text-right {
    font-size: 12px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.panel-physician {
  background-color: rgb(248, 248, 248);
}

label {
  margin-top: 0.2857rem!important;
}

.collapse-border .card .card-header {
  padding-top: .5rem;
  padding-bottom: .5rem;
  h4 {
    margin-bottom: 0;
  }
  div.d-flex {
    align-items: center;
  }
}

.duty-dates {
  align-items: center;
  h1 {
    color: #898993;
    font-size: 14px;
    margin-bottom: 0;
    margin-right: 10px;
  }
}

.physician-label {
  font-size: 12px;
}

.title {
  margin: 0 5px;
}
.btn.action {
  padding: 2px 3px;
}
.institution-link {
  padding: 4px 4px 0;
}
.physician-name {
  line-height: 14px;
}
</style>
