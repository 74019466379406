import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function usePartnersList() {
  // Use toast
  const toast = useToast()

  const refPartnerListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'title', label: 'Parceiro' },
    { key: 'url', label: 'URL' },
    {
      key: 'partnership_category',
      label: 'Categoria',
      formatter: c => c.title,
    },
    { key: 'status', label: 'status' },
    { key: 'actions', label: 'Ações' },
  ]
  const perPage = ref(10)
  const totalPartners = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(true)
  const situationFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refPartnerListTable.value ? refPartnerListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPartners.value,
    }
  })

  const refetchData = () => {
    refPartnerListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, situationFilter, statusFilter], () => {
    refetchData()
  })

  const fetchPartners = (ctx, callback) => {
    store
      .dispatch('app-partner/fetchPartners', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDirection: isSortDirDesc.value ? 'desc' : 'asc',
        situation: situationFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { data } = response.data
        callback(data)
        totalPartners.value = response.data.meta.pagination.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao listar parceiros',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolvePartnerRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolvePartnerRoleIcon = role => {
    if (role === 'subscriber') return 'PartnerIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'PartnerIcon'
  }

  const resolvePartnerStatusVariant = status => {
    if (status) return 'primary'
    return 'success'
  }

  return {
    fetchPartners,
    tableColumns,
    perPage,
    currentPage,
    totalPartners,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPartnerListTable,

    resolvePartnerRoleVariant,
    resolvePartnerRoleIcon,
    resolvePartnerStatusVariant,
    refetchData,

    // Extra Filters
    situationFilter,
    statusFilter,
  }
}
