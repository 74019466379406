import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useBusinessProcessList() {
  // Use toast
  const toast = useToast()

  const refInstitutionListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'view', label: 'Abrir' },
    { key: 'category', label: 'Categoria' },
    { key: 'title', label: 'Título' },
    { key: 'description', label: 'Descrição' },
    { key: 'tags', label: 'Tags' },
    { key: 'actions', label: 'Ações' },
  ]
  const perPage = ref(10)
  const totalInstitutions = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(false)
  const situationFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refInstitutionListTable.value ? refInstitutionListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInstitutions.value,
    }
  })

  const refetchData = () => {
    refInstitutionListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, situationFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchBusinessProcesses = (ctx, callback) => {
    store
      .dispatch('app-business-process/fetchBusinessProcesses', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value ? 'desc' : 'asc',
      })
      .then(response => {
        const { data, meta } = response.data
        callback(data)
        totalInstitutions.value = meta.pagination.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao buscar',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchBusinessProcesses,
    tableColumns,
    perPage,
    currentPage,
    totalInstitutions,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refInstitutionListTable,

    refetchData,

    // Extra Filters
    situationFilter,
    planFilter,
    statusFilter,
  }
}
