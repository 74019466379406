<template>
  <div>
    <b-row class="mb-2">
      <b-col
        cols="12"
        md="6"
        class=""
      >
        <div class="d-flex align-items-center date-interval-picker">
          <b-button
            variant="outline-primary"
            class="action d-none d-md-inline-block mt-2 mr-1"
            :disabled="disabledPrevious || loading"
            title="Voltar 1 ano"
            @click="previous"
          >
            <feather-icon
              size="18"
              icon="ChevronLeftIcon"
            />
          </b-button>
          <div>
            <label for="physician-id">De</label>
            <v-select
              v-model="lastYearDate"
              :options="yearOptions"
              :clearable="false"
              class="year-picker"
              :disabled="loading"
            />
          </div>
          <div class="ml-1">
            <label for="physician-id">Até Ano</label>
            <v-select
              v-model="baseYearDate"
              :options="yearOptions"
              :clearable="false"
              class="year-picker"
              :disabled="loading"
            />
          </div>
          <b-button
            variant="outline-primary"
            class="action d-none d-md-inline-block mt-2 ml-1"
            title="Avançar 1 ano"
            :disabled="loading"
            @click="next"
          >
            <feather-icon
              size="18"
              icon="ChevronRightIcon"
            />
          </b-button>
        </div>
      </b-col>
    </b-row>

    <b-row class="mt-1">
      <b-col
        cols="12"
        md="12"
        xl="10"
        :style="mobile.mobile ? 'min-height: 900px' : 'min-height: 500px'"
      >
        <div
          class="h-100 d-flex flex-column flex-xl-row align-items-center text-left"
        >
          <physicians-pie
            :year="baseYearDate"
            :mobile="mobile"
            :chart-data="chartDataPhysician"
          />
        </div>
      </b-col>
      <b-col
        cols="12"
        md="12"
        xl="10"
        style="min-height: 500px"
      >
        <div
          class="h-100 d-flex flex-column flex-xl-row align-items-center text-left"
        >
          <physicians-bar
            :mobile="mobile"
            :chart-data="chartDataPhysician"
          />
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col
        cols="12"
        md="12"
        xl="12"
        class="chart-container"
      >
        <div
          class="chart-inner-container h-100 d-flex flex-column flex-xl-row align-items-center text-left"
        >
          <evolution-physician-bar
            :year="baseYearDate"
            :mobile="mobile"
            :chart-data="chartDataPhysician"
          />
        </div>
      </b-col>
    </b-row>

    <hr class="mt-2">
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <label for="physician-id">Médico</label>
        <v-select
          id="physician-id"
          v-model="physicianId"
          name="physician-id"
          :clearable="false"
          :options="physicianOptions"
          :filter="filterPhysicians"
          label="name"
        >
          <template
            #selected-option="{ name}"
            class="d-flex align-items-center justify-content-start"
          >
            <div class="font-weight-bold">
              <strong>{{ name }}</strong>
            </div>
          </template>
          <template #option="{ name, cpf }">
            <div class="font-weight-bold">
              <strong>{{ name }}</strong>
            </div>
            <div class="select-subtitle">
              <span>CPF: <strong>{{ cpf }}</strong></span>
            </div>
          </template>
          <span slot="no-options">Nenhum dado encontrado.</span>
        </v-select>
      </b-col>
    </b-row>

    <b-row class="mt-1">
      <b-col
        cols="12"
        md="12"
        xl="9"
        style="min-height: 500px"
      >
        <div
          class="h-100 d-flex flex-column flex-xl-row align-items-center text-left"
        >
          <evolution-by-physician-bar
            :year="baseYearDate"
            :mobile="mobile"
            :chart-data="chartDataPhysician2"
          />
        </div>
      </b-col>
      <b-col
        cols="12"
        md="12"
        xl="3"
        style="min-height: 500px"
      >
        <div
          class="h-100 d-flex flex-column flex-xl-row align-items-center text-left"
        >
          <resume-bar
            :mobile="mobile"
            :chart-data="chartDataPhysician2"
          />
        </div>
      </b-col>
      <b-col
        cols="12"
        xs="12"
        xl="12"
        style="min-height: 500px"
      >
        <div
          class="h-100 d-flex flex-column flex-xl-row align-items-center text-left"
        >
          <evolution-by-physician-line
            :year="baseYearDate"
            :mobile="mobile"
            :chart-data="chartDataPhysician2"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCol, BRow, BButton,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted, ref, watch } from '@vue/composition-api'
import { addYears } from 'date-fns'
import vSelect from 'vue-select'
import chartStoreModule from '@/views/charts/chartStoreModule'
import Fuse from 'fuse.js'
import { formatCpf } from '@core/utils/utils'
import EvolutionPhysicianBar from '@/views/charts/physician/EvolutionPhysicianBar.vue'
import PhysiciansBar from '@/views/charts/physician/PhysiciansBar.vue'
import PhysiciansPie from '@/views/charts/physician/PhysiciansPie.vue'
import EvolutionByPhysicianBar from '@/views/charts/physician/EvolutionByPhysicianBar.vue'
import EvolutionByPhysicianLine from '@/views/charts/physician/EvolutionByPhysicianLine.vue'
import ResumeBar from '@/views/charts/physician/ResumeBar.vue'

export default {
  components: {
    EvolutionByPhysicianBar,
    EvolutionByPhysicianLine,
    ResumeBar,
    vSelect,
    BRow,
    BCol,
    BButton,
    EvolutionPhysicianBar,
    PhysiciansBar,
    PhysiciansPie,
  },
  props: {
    mobile: {
      type: Object,
      default: () => ({ mobile: false }),
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-chart'
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, chartStoreModule)

    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const currentYear = new Date().getFullYear()
    const getYear = (sub = 0) => addYears(new Date(), sub).getFullYear()

    const yearOptions = []
    const lastYearDate = ref(getYear(-1))
    const baseYearDate = ref(getYear())
    const loading = ref(true)
    const disabledNext = ref(true)
    const disabledPrevious = ref(false)
    const currentDateFormatted = ref('')
    const chartDataPhysician = ref(null)
    const chartDataPhysician2 = ref(null)
    const physicianId = ref(null)
    const physicianOptions = ref([])

    const countYears = currentYear - 2022
    new Array(countYears + 1).fill(0).forEach((_, i) => {
      yearOptions.push(2022 + i)
    })

    const refresh = () => {
      loading.value = true
      const baseYear = baseYearDate.value
      const lastYear = lastYearDate.value

      store.dispatch('app-chart/fetchAverageAndTotalByPhysician', {
        base_year: baseYear,
        last_year: lastYear,
      })
        .then(response => {
          chartDataPhysician.value = response.data.data || {}
        })
        .finally(() => {
          loading.value = false
        })

      if (physicianId.value) {
        const physician = physicianId.value.id
        store.dispatch('app-chart/fetchEvolutionAdvanceByPhysician', {
          base_year: baseYear,
          last_year: lastYear,
          user_id: physician,
        })
          .then(response => {
            chartDataPhysician2.value = response.data.data || {}
          })
          .finally(() => {
            loading.value = false
          })
      }
    }
    refresh()

    watch([baseYearDate, lastYearDate, physicianId], () => {
      refresh()
    })

    store.dispatch('app-chart/fetchPhysicians', { perPage: 99999999 })
      .then(response => {
        physicianOptions.value = response.data.data.map(work => ({
          id: work.id,
          name: work.name,
          cpf: formatCpf(work.cpf),
        }))
        // eslint-disable-next-line prefer-destructuring
        // physicianId.value = physicianOptions.value[0]
        physicianId.value = physicianOptions.value.find(({ id }) => id === '13fad6ff-9d1c-484c-b7ba-3725de0ba286')
      })

    function filterPhysicians(options, search) {
      const fuse = new Fuse(options, {
        keys: ['name', 'institution.name', 'institution.cnpj'],
        shouldSort: true,
      })
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list
    }

    const previous = () => {
      const year = lastYearDate.value - 1
      if (year <= 2022) {
        disabledPrevious.value = true
      } else {
        disabledPrevious.value = false
      }
      if (year < 2022) {
        return
      }

      lastYearDate.value = year
      baseYearDate.value -= 1
      refresh()
    }
    const next = () => {
      const year = baseYearDate.value + 1
      if (year <= currentYear) {
        lastYearDate.value += 1
        baseYearDate.value += 1
        disabledPrevious.value = false
        refresh()
      }
    }

    return {
      physicianId,
      physicianOptions,
      filterPhysicians,
      loading,
      yearOptions,
      baseYearDate,
      lastYearDate,
      previous,
      next,
      disabledNext,
      disabledPrevious,
      currentDateFormatted,
      chartDataPhysician,
      chartDataPhysician2,
    }
  },
}
</script>

<style lang="scss" scoped>
.v-text-field--box .v-input__slot, .v-text-field--outline .v-input__slot{
  max-height: 56px!important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select";
.select-subtitle {
  font-size: 12px;
}

.card-body{
  position: relative;
  .pie-text{
    width: 105px;
    position:absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 44%;
    bottom: 0;
  }
  }

.card-header .title {
  min-width: 45px;
  text-align: center;
}
.action {
  padding: 2px 3px;
  margin-right: 5px;
  & ~ .action {
    margin-left: 5px;
  }
}
.year-picker {
  min-width: 100px;
}

.chart-container {
  min-height: 500px;
  overflow: auto;
  .chart-inner-container {
    min-width: 1300px
  }
}
</style>
