<template>
  <b-card>
    <h4>Contrato de {{ resolveContractType(contract.type) }}</h4>
    <b-form-group
      class="mt-1"
      label="Descrição"
      label-for="description"
    >
      <b-form-textarea
        id="description"
        v-model="contract.description"
        rows="4"
        placeholder="Descrição..."
      />
    </b-form-group>

    <b-form-group
      label="Template"
      label-for="template"
    >
      <contract-editor :contract="contract" />
    </b-form-group>

    <b-button
      variant="outline-primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :to="{name: 'contract-list'}"
    >
      Voltar
    </b-button>
    <b-button
      variant="secondary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      :disabled="!hasEdited"
      @click="onSubmit"
    >
      Salvar
    </b-button>
  </b-card>
</template>

<script>
import {
  BCard, BFormGroup, BFormTextarea, BButton,
} from 'bootstrap-vue'
import { computed, onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import contractStoreModule from '@/views/contract/contractStoreModule'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useContractsList from '@/views/contract/contract-list/useContractList'
import ContractEditor from './ContractEditor.vue'
import codeCustom from './code'

export default {
  components: {
    BCard,
    BButton,
    BFormGroup,
    BFormTextarea,

    ContractEditor,
  },
  data() {
    return {
      codeCustom,
    }
  },
  setup() {
    const contract = ref({})
    const originalContract = ref({})

    const CONTRACT_APP_STORE_MODULE_NAME = 'app-contract'

    // Register module
    if (!store.hasModule(CONTRACT_APP_STORE_MODULE_NAME)) store.registerModule(CONTRACT_APP_STORE_MODULE_NAME, contractStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CONTRACT_APP_STORE_MODULE_NAME)) store.unregisterModule(CONTRACT_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-contract/fetchContract', { id: router.currentRoute.params.id })
      .then(response => {
        originalContract.value = response.data.data
        contract.value = JSON.parse(JSON.stringify(response.data.data))
      })
      .catch(error => {
        if (error.response.status === 404) {
          contract.value = undefined
          originalContract.value = undefined
        }
      })

    const hasEdited = computed(() => contract.value.description !== originalContract.value.description
      || contract.value.template !== originalContract.value.template)

    function onSubmit() {
      const {
        description, template, type, tag,
      } = contract.value
      store.dispatch('app-contract/addContract', {
        description, template, type, tag,
      })
        .then(response => {
          const { id } = response.data.data
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Save',
              icon: 'CheckIcon',
              variant: 'success',
              text: 'Save successfully!',
            },
          })
          router.push({ name: 'contract-list', params: { id } })
        })
    }

    const {
      resolveContractType,
    } = useContractsList()

    return {
      contract,
      resolveContractType,
      onSubmit,
      hasEdited,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
