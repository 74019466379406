<template>
  <b-sidebar
    id="add-new-financial-institution-sidebar"
    :visible="isAddNewFinancialInstitutionSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-financial-institution-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ financialInstitutionId ? 'Editar banco' : 'Novo banco' }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <validation-provider
            #default="validationContext"
            name="Código"
            rules="required|digits:3"
          >
            <b-form-group
              label="Código"
              label-for="bank_code"
            >
              <b-form-input
                id="bank_code"
                v-model="financialInstitutionData.bank_code"
                autofocus
                :state="validationContext.touched ? getValidationState(validationContext) : null"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Nome"
            rules="required"
          >
            <b-form-group
              label="Nome"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="financialInstitutionData.name"
                autofocus
                :state="validationContext.touched ? getValidationState(validationContext) : null"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            name="active"
            rules="required"
          >
            <b-form-group label="">
              <b-form-radio-group
                id="financial-institution-status"
                v-model="financialInstitutionData.active"
                name="active"
                :options="statusOptions"
              />
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-primary"
              @click="hide"
            >
              Voltar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormRadioGroup,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, watch } from '@vue/composition-api'
import {
  required, alphaNum, digits,
} from '@validations'
// import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import formError from '@core/comp-functions/forms/form-error'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormRadioGroup,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewFinancialInstitutionSidebarActive',
    event: 'update:is-add-new-financial-institution-sidebar-active',
  },
  props: {
    isAddNewFinancialInstitutionSidebarActive: {
      type: Boolean,
      required: true,
    },
    financialInstitutionId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      required,
      digits,
      alphaNum,
    }
  },
  setup(props, { emit }) {
    const accessTypes = ref([])
    const blankFinancialInstitutionData = {
      name: '',
      bank_code: '',
      active: true,
    }

    const financialInstitutionData = ref(JSON.parse(JSON.stringify(blankFinancialInstitutionData)))
    const resetFinancialInstitutionData = () => {
      financialInstitutionData.value = JSON.parse(JSON.stringify(blankFinancialInstitutionData))
    }

    function getFinancialInstitution(id) {
      store
        .dispatch('app-financial-institution/fetchFinancialInstitution', { id })
        .then(response => {
          financialInstitutionData.value = response.data.data
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetFinancialInstitutionData)

    function onSubmit() {
      const financialInstitution = { ...financialInstitutionData.value }
      store.dispatch(financialInstitution.id ? 'app-financial-institution/updateFinancialInstitution' : 'app-financial-institution/addFinancialInstitution', financialInstitution)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-financial-institution-sidebar-active', false)
        })
        .catch(error => {
          const { setErrors, getErrorMessage } = formError(refFormObserver.value, error)
          setErrors()
          if (getErrorMessage()) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Erro',
                icon: 'XOctagonIcon',
                variant: 'danger',
                text: getErrorMessage(),
              },
            })
          }
        })
    }

    watch(() => props.isAddNewFinancialInstitutionSidebarActive, show => {
      resetForm()
      resetFinancialInstitutionData()
      if (show && props.financialInstitutionId) {
        getFinancialInstitution(props.financialInstitutionId)
      }
    })

    const statusOptions = [
      { text: 'Ativo', value: true },
      { text: 'Inativo', value: false },
    ]

    return {
      financialInstitutionData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      accessTypes,
      statusOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-financial-institution-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
