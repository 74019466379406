<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              label="Status"
              label-for="status"
            >
              <v-select
                v-model="statusFilter"
                :options="statusOptions"
                :reduce="val => val.value"
                :clearable="false"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="5"
          >
            <label for="institution-id">Instituição</label>
            <v-select
              id="institution-id"
              v-model="institutionId"
              name="institution-id"
              :options="institutionOptions"
              label="label"
              item-value="value"
              item-text="label"
              @input="fetchWorkplaces"
            >
              <span slot="no-options">Nenhuma instituição encontrada.</span>
            </v-select>
          </b-col>
          <b-col
            cols="12"
            md="5"
          >
            <div class="d-flex w-100">
              <div class="flex-grow-1">
                <label for="workplace-id">Unidade</label>
                <v-select
                  id="workplace-id"
                  v-model="workplaceId"
                  name="workplace-id"
                  :options="workplaceOptions"
                  label="label"
                  item-value="value"
                  item-text="label"
                  :disabled="!institutionId"
                >
                  <span slot="no-options">Nenhuma unidade encontrado.</span>
                </v-select>
              </div>
              <div class="d-flex align-items-end justify-content-end mt-2 mx-2">
                <b-button
                  variant="primary"
                  :to="{ name: 'advanced-physician-duties-invoice' }"
                >
                  <span class="text-nowrap">
                    Nova fatura
                  </span>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="getInvoices"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum registro correspondente encontrado"
        :sort-desc.sync="isSortDirDesc"
        @row-clicked="(item) => { item.checked = !item.checked }"
      >
        <template
          #cell(institution.name)="{ item }"
          class="w-100"
        >
          <div
            class="text-truncate overflow-hidden"
            style="max-width: 150px"
            :title="item.institution.name"
          >
            {{ item.institution.name }}
          </div>
        </template>
        <template
          #cell(workplaces)="{ item }"
          class="w-100"
        >
          <div :title="item.workplaces.join(', ')">
            <div
              class="text-truncate overflow-hidden"
              style="max-width: 150px"
            >
              {{ item.workplaces.join(', ') }}
            </div>
            <span
              v-if="item.workplaces.length > 1"
              class="alert-link"
            >e mais {{ item.workplaces.length - 1 }} unidades</span>
          </div>
        </template>
        <template
          #cell(total)="{ item }"
          class="w-100"
        >
          {{ formatCurrency(item.total / 100) }}
        </template>
        <template
          #cell(status)="{ item }"
          class="w-100"
        >
          <div v-if="item.status === 'paid'">
            <b-badge
              pill
              variant="light-success"
            >
              Pago
            </b-badge>
            <div class="invoice-date">
              Pagamento
              <div>{{ formatDateTimeDDMMYYYY(item.paid_at) }}</div>
            </div>
          </div>
          <div v-else>
            <b-badge
              v-if="item.status === 'open'"
              pill
              variant="light-primary"
            >
              Aberto
            </b-badge>
            <b-badge
              v-if="item.status === 'expired'"
              pill
              variant="light-danger"
            >
              Vencido
            </b-badge>
            <div class="invoice-date">
              Vencimento
              <div>{{ formatDateTimeDDMMYYYY(item.deadline) }}</div>
            </div>
          </div>
        </template>
        <template
          #cell(start_date)="{ item }"
          class="w-100"
        >
          {{ formatDateTimeDDMMYYYY(item.start_date) }}
          <span class="d-none d-xl-inline">-</span>
          {{ formatDateTimeDDMMYYYY(item.end_date) }}
        </template>
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{ name: 'invoice-view', params: { id: data.item.id } }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">Detalhes</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.status === 'paid'"
              @click="startReopenInvoice(data.item)"
            >
              <feather-icon icon="RefreshCwIcon" />
              <span class="align-middle ml-50">Reabrir</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-else
              :to="{ name: 'invoice-edit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.status !== 'paid'"
              @click="openPayInvoice(data.item)"
            >
              <feather-icon icon="DollarSignIcon" />
              <span class="align-middle ml-50">Pagar</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="downloadInvoice(data.item)"
            >
              <feather-icon icon="DownloadIcon" />
              <span class="align-middle ml-50">Exportar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-modal
      id="modal-invoice"
      ref="invoiceModal"
      :title="`Pagar Fatura Nº ${invoiceData && pad(invoiceData.invoice_number, 6)}`"
      hide-footer
      centered
    >
      <div>
        <div class="mb-1">
          <label for="deadline-date">Data de pagamento</label>
          <b-form-datepicker
            id="deadline-date"
            v-model="invoiceData.paid_at"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="pt-BR"
            label-help=""
            label-no-date-selected="Selecione uma data"
          />
        </div>
        <div class="d-flex">
          <div class="mr-2">
            <label for="others-add">Valor</label>
            <h5>
              {{ formatCurrency(invoiceData.total / 100) }}
            </h5>
          </div>
          <div class="flex-grow-1">
            <label for="others-add">Outros Acréscimos</label>
            <cleave
              id="others-add"
              v-model="invoiceData.additions"
              class="form-control"
              :options="options.number"
              :raw="true"
              @input="onChangeAmount"
            />
          </div>
        </div>
        <div>
          <div>
            <label for="others-add">Total</label>
            <h5>
              {{ formatCurrency(invoiceData.amount_paid / 100) }}
            </h5>
          </div>
        </div>
        <div class="text-right">
          <b-button
            variant="primary"
            class="ml-1"
            :disabled="loading"
            @click="payInvoice"
          >
            <span class="d-none d-sm-inline">Pagar</span>
            <feather-icon
              icon="TrashIcon"
              class="d-inline d-sm-none"
            />
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-invoice"
      ref="invoiceReopenModal"
      :title="`Reabrir Fatura Nº ${invoiceData && pad(invoiceData.invoice_number, 6)}`"
      hide-footer
      centered
    >
      <div>
        <b-form-group
          class="mt-1"
          label="Motivo"
          label-for="reopen-reason"
        >
          <b-form-textarea
            id="reopen-reason"
            v-model="reopenReason"
            rows="4"
            placeholder="Descreva o motivo..."
          />
        </b-form-group>
        <div class="text-right">
          <b-button
            variant="primary"
            class="ml-1"
            :disabled="loading"
            @click="reopenInvoice"
          >
            <span class="d-none d-sm-inline">Reabrir</span>
            <feather-icon
              icon="RefreshCwIcon"
              class="d-inline d-sm-none"
            />
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BBadge,
  BPagination, BButton, BFormGroup, BDropdown, BDropdownItem, BModal, BFormDatepicker, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import {
  formatCurrency, formatDateTimeDDMMYYHHMM, formatDateTimeDDMMYYYY, pad,
} from '@core/utils/utils'
import vSelect from 'vue-select'
import reportStoreModule from '@/views/report/reportStoreModule'
import Vue from 'vue'
import Cleave from 'vue-cleave-component'
import useNotInPaymentRemittance from './useInvoiceList'
import contractStoreModule from '../contractStoreModule'

export default {
  components: {
    BFormTextarea,
    BFormDatepicker,
    BBadge,
    BModal,
    BDropdownItem,
    BDropdown,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BButton,
    vSelect,
    Cleave,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      options: {
        number: {
          numeralPositiveOnly: true,
          numericOnly: true,
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.',
          numeralDecimalScale: 2,
          prefix: 'R$ ',
          rawValueTrimPrefix: true,
        },
      },
    }
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-invoice'
    const REPORTS_STORE_MODULE_NAME = 'app-reports'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, contractStoreModule)
    if (!store.hasModule(REPORTS_STORE_MODULE_NAME)) store.registerModule(REPORTS_STORE_MODULE_NAME, reportStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
      if (store.hasModule(REPORTS_STORE_MODULE_NAME)) store.unregisterModule(REPORTS_STORE_MODULE_NAME)
    })

    const invoiceModal = ref(null)
    const invoiceReopenModal = ref(null)
    const reopenReason = ref(null)
    const paymentDate = ref('')
    const loading = ref(false)
    const currentUserId = ref(null)
    const paymentRemittanceModal = ref(null)
    const paymentRemittance = ref(null)
    const invoiceData = ref({
      id: null,
      paid_at: '',
      total: 0,
      amount_paid: 0,
      additions: 0,
    })

    const downloadInvoice = invoice => {
      store.dispatch('app-invoice/downloadInvoice', invoice)
        .then(response => {
          const blob = new Blob([response.data], { type: response.headers['content-type'] })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `fatura_${pad(invoice.invoice_number, 6)}.pdf`
          link.click()
          URL.revokeObjectURL(link.href)
        })
    }

    const {
      getInvoices,
      fetchInstitutions,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveDeadlineVariant,
      checkAll,
      showImediato,

      // Extra Filters
      roleFilter,
      planFilter,
      situationFilter,
      statusFilter,
      statusOptions,
      createPaymentRemittance,
      institutionId,
      workplaceId,
      institutionOptions,
      workplaceOptions,
      fetchWorkplaces,
    } = useNotInPaymentRemittance()

    fetchInstitutions()

    const openPayInvoice = invoice => {
      invoiceData.value = invoice
      invoiceData.value.amount_paid = 0
      invoiceModal.value.show()
    }

    const onChangeAmount = () => {
      // eslint-disable-next-line operator-assignment
      invoiceData.value.amount_paid = invoiceData.value.total + invoiceData.value.additions * 100
    }

    const payInvoice = () => {
      const data = {
        id: invoiceData.value.id,
        paid_at: invoiceData.value.paid_at,
        amount_paid: invoiceData.value.amount_paid,
        additions: invoiceData.value.additions * 100,
      }
      store.dispatch('app-invoice/payInvoice', data)
        .then(async () => {
          refetchData()
          invoiceModal.value.hide()
          await Vue.swal({
            title: 'Fatura atualizada!',
            text: 'Data de pagamento foi atualizada',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    }

    function startReopenInvoice(invoice) {
      invoiceData.value = invoice
      invoiceReopenModal.value.show()
    }

    function reopenInvoice() {
      const payload = {
        id: invoiceData.value.id,
        reopen_reason: reopenReason.value,
      }
      store.dispatch('app-invoice/reopenInvoice', payload)
        .then(() => {
          invoiceReopenModal.value.hide()
          refetchData()
        })
    }

    return {
      downloadInvoice,
      invoiceData,
      openPayInvoice,
      payInvoice,
      workplaceOptions,
      fetchWorkplaces,
      checkAll,
      loading,

      getInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalUsers: totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      paymentDate,
      showImediato,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveDeadlineVariant,

      currentUserId,
      invoiceModal,
      invoiceReopenModal,

      // Extra Filters
      roleFilter,
      onChangeAmount,
      planFilter,
      situationFilter,
      statusFilter,
      formatCurrency,
      formatDateTimeDDMMYYHHMM,
      formatDateTimeDDMMYYYY,
      createPaymentRemittance,
      paymentRemittanceModal,
      paymentRemittance,
      institutionId,
      institutionOptions,
      statusOptions,
      workplaceId,
      startReopenInvoice,
      reopenInvoice,
      reopenReason,
      pad,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.dates-row {
  max-width: 500px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

tr:has(.custom-checkbox.checked) {
  background-color: rgba(98, 98, 98, 0.05);
}

.table-responsive {
  min-height: 200px;
}
.invoice-date {
  margin-top: 2px;
  font-size: 0.757rem;
  font-weight: 400;
  line-height: 0.857rem;
  div {
    font-size: 0.957rem;
    line-height: 0.757rem;
  }
}
h5 {
  margin-bottom: 0;
}
</style>
