<template>
  <component :is="institutionData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="institutionData === undefined"
    >
      <h4 class="alert-heading">
        Erro ao buscar user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'institution-list'}"
        >
          Institution List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <b-tabs
      v-if="institutionData"
      class="g-med-tabs"
      pills
    >
      <!-- Tab: Account -->
      <b-tab
        :active="tab === 'general'"
        @click="changeTab('general')"
      >
        <template #title>
          <div>
            <feather-icon
              icon="HomeIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Instituição</span>
          </div>
        </template>
        <institution-edit-tab-general
          :institution-data="institutionData"
          :read-only="readOnly"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab
        :active="tab === 'users'"
        @click="changeTab('users')"
      >
        <template #title>
          <div>
            <feather-icon
              icon="UnlockIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Acesso</span>
          </div>
        </template>
        <institution-edit-tab-users
          :institution-data="institutionData"
          :read-only="readOnly"
        />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab
        :active="tab === 'physician'"
        @click="changeTab('physician')"
      >
        <template #title>
          <div>
            <feather-icon
              icon="UsersIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Médicos</span>
          </div>
        </template>
        <institution-edit-tab-physicians
          :institution-data="institutionData"
          :read-only="readOnly"
        />
      </b-tab>

      <b-tab
        :active="tab === 'workplace'"
        @click="changeTab('workplace')"
      >
        <template #title>
          <div>
            <feather-icon
              icon="MapPinIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Unidades</span>
          </div>
        </template>
        <institution-edit-tab-workplaces
          :institution-data="institutionData"
          :read-only="readOnly"
        />
      </b-tab>
    </b-tabs>

  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import { useRouter } from '@core/utils/utils'
import { can } from '@core/libs/acl/utils'
import institutionStoreModule from '../institutionStoreModule'
import InstitutionEditTabGeneral from './InstitutionEditTabGeneral.vue'
import InstitutionEditTabUsers from './InstitutionEditTabUsers.vue'
import InstitutionEditTabPhysicians from './InstitutionEditTabPhysicians.vue'
import InstitutionEditTabWorkplaces from './InstitutionEditTabWorkplaces.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    InstitutionEditTabGeneral,
    InstitutionEditTabUsers,
    InstitutionEditTabPhysicians,
    InstitutionEditTabWorkplaces,
  },
  setup() {
    const { route, router } = useRouter()
    const tab = ref(route.value.query.tab || 'general')
    const institutionData = ref(null)
    const readOnly = ref(!can('manage', 'Institution'))

    const INSTITUTION_APP_STORE_MODULE_NAME = 'app-institution'

    // Register module
    if (!store.hasModule(INSTITUTION_APP_STORE_MODULE_NAME)) store.registerModule(INSTITUTION_APP_STORE_MODULE_NAME, institutionStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INSTITUTION_APP_STORE_MODULE_NAME)) store.unregisterModule(INSTITUTION_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-institution/fetchInstitution', { id: router.currentRoute.params.id })
      .then(response => {
        const institution = response.data.data
        institutionData.value = {
          id: institution.id,
          name: institution.name,
          fancy_name: institution.fancy_name,
          email: institution.email,
          cnpj: institution.cnpj,
          contact: institution.contact,
          phone: institution.phone,
          limit: `${institution.limit / 100}`.replace('.', ','),
          payday: institution.payday,
          financial_restriction: institution.financial_restriction,
          financial_restriction_reason: institution.financial_restriction_reason,
          cashback: institution.cashback && institution.cashback.replace('.', ','),
          interest: institution.interest?.replace('.', ','),
          service_fee: institution.service_fee?.replace('.', ','),
          payment_method: institution.payment_method,
          status: institution.status,
          address: {
            id: institution.address[0].id,
            street: institution.address[0].street || '',
            number: institution.address[0].number || '',
            postal_code: institution.address[0].postal_code,
            neighborhood: institution.address[0].neighborhood || '',
            complement: institution.address[0].complement || '',
            city_id: institution.address[0].city.id || '',
            state_id: institution.address[0].city.state_id || '',
          },
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          institutionData.value = undefined
        }
      })

    function changeTab(t) {
      tab.value = t
      router.replace({ query: { tab: t } })
    }

    return {
      tab,
      changeTab,
      institutionData,
      readOnly,
    }
  },
}
</script>

<style>

</style>
