<template>
  <e-charts
    ref="line"
    autoresize
    :option="option"
    theme="theme-color"
    auto-resize
    :update-options="{notMerge:true}"
  />
</template>

<script>
import ECharts from 'vue-echarts'
import { ref, toRef, watch } from '@vue/composition-api'
import { registerTheme, use } from 'echarts/core'
import {
  BarChart, LineChart, LinesChart, PieChart,
} from 'echarts/charts'
import {
  DatasetComponent,
  DataZoomComponent,
  GridComponent,
  LegendComponent,
  LegendScrollComponent, MarkLineComponent, MarkPointComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent,
} from 'echarts/components'
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers'
import { formatCurrency, formatCurrencyNonDecimal } from '@core/utils/utils'
import theme from '../theme.json'

use([
  BarChart,
  LineChart,
  PieChart,
  LinesChart,
  LegendScrollComponent,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  CanvasRenderer,
  SVGRenderer,
  ToolboxComponent,
  DataZoomComponent,
  MarkLineComponent,
  MarkPointComponent,
])
registerTheme('theme-color', theme)

export default {
  components: {
    ECharts,
  },
  props: {
    mobile: {
      type: Object,
      default: () => ({ mobile: false }),
    },
    chartData: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const chartData = toRef(props, 'chartData')
    const mobile = toRef(props, 'mobile')

    const option = ref({
      title: {
        text: 'Média Mensal',
        left: 'center',
      },
      xAxis: {
        type: 'category',
        data: [],
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          rotate: 55,
          formatter: value => (value ? `${formatCurrencyNonDecimal(value)}` : ''),
        },
      },
      grid: {
        left: 100,
      },
      series: [
        {
          data: [],
          type: 'bar',
          label: {
            show: true,
            formatter: params => `${formatCurrency(params.value)}`,
          },
          markPoint: {
            data: [],
          },
        },
      ],
    })

    watch([chartData, mobile], ([data]) => {
      if (mobile.value.mobile) {
        option.value.title.left = 'left'
        option.value.grid.left = 50
      } else {
        option.value.title.left = 'center'
        option.value.grid.left = 100
      }

      if (!data.average_year) return

      const years = Object.keys(data.average_year).sort()
      const labels = years.map(year => `${year}`)
      const values = years.map((year, index) => {
        const value = data.average_year[year] / 100
        // eslint-disable-next-line no-mixed-operators
        const color = [(index % theme.color.length + theme.color.length) % theme.color.length]
        return {
          value,
          itemStyle: {
            color: theme.color[color],
          },
        }
      })

      option.value.xAxis.data = labels
      option.value.series[0].data = values
      option.value.series[0].markPoint.data = []
      years.forEach((year, i) => {
        if (i === 0) return
        const isPositive = data.average_year_percentage[year] >= 0
        option.value.series[0].markPoint.data.push(
          {
            name: '%',
            value: `${data.average_year_percentage[year]}%`,
            xAxis: i,
            yAxis: data.average_year[year] / 100,
            itemStyle: {
              color: isPositive ? '#28a745' : '#dc3545',
            },
            label: {
              position: 'top',
              fontSize: '12px',
              distance: -25,
            },
            symbol: 'triangle',
            symbolSize: '35',
            symbolRotate: isPositive ? '0' : '180',
            symbolOffset: [0, '-22px'],
          },
        )
      })
    })

    return {
      option,
    }
  },
}
</script>
