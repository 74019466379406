<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col xl="12">
        <home-statistics
          :data="data.statisticsItems"
          :title="currentDateFormatted"
          :disabled-next="disabledNext"
          @previous="previous"
          @next="next"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="12">
        <physician-duty />
      </b-col>
    </b-row>
    <!--    <b-row>-->
    <!--      <b-col-->
    <!--        xs="12"-->
    <!--        xl="6"-->
    <!--      >-->
    <!--        <b-card-->
    <!--          no-body-->
    <!--        >-->
    <!--          <b-card-body class="text-center">-->
    <!--            <iframe-->
    <!--              src="https://vmi1241216.contaboserver.net:3000/d-solo/52SzzE-4k/imediato-pendentes?orgId=2&refresh=10s&from=1678918914999&to=1678919214999&theme=light&panelId=2"-->
    <!--              width="450"-->
    <!--              height="200"-->
    <!--              frameborder="0"-->
    <!--            />-->
    <!--          </b-card-body>-->
    <!--        </b-card>-->
    <!--      </b-col>-->
    <!--    </b-row>-->
  </section>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import {
  format, addMonths, subMonths, isFuture,
} from 'date-fns'
import ptBrLocale from 'date-fns/locale/pt-BR'
import HomeStatistics from './HomeStatistics.vue'
import PhysicianDuty from './PhysicianDuty.vue'

export default {
  components: {
    BRow,
    BCol,
    HomeStatistics,
    PhysicianDuty,
  },
  data() {
    return {
      currentDate: new Date(),
      disabledNext: true,
      currentDateFormatted: null,
      data: {
        statisticsItems: [
          {
            icon: 'UsersIcon',
            color: 'light-info',
            title: '0',
            subtitle: 'Médicos',
            customClass: 'mb-2 mb-xl-0',
          },
          {
            icon: 'ListIcon',
            color: 'light-primary',
            title: 'R$0,00',
            subtitle: 'Empresas',
            customClass: '',
          },
          {
            icon: 'ClockIcon',
            color: 'light-warning',
            title: 'R$0,00',
            subtitle: 'Plantões Agendados',
            customClass: 'mb-2 mb-sm-0',
          },
          {
            icon: 'ArrowUpRightIcon',
            color: 'light-danger',
            title: 'R$0,00',
            subtitle: 'Plantões Adiantados',
            customClass: 'mb-2 mb-sm-0',
          },
          {
            icon: 'DollarSignIcon',
            color: 'light-primary',
            title: 'R$0,00',
            subtitle: 'Lucro Bruto',
            customClass: 'mb-2 mb-sm-0',
          },
          {
            icon: 'DollarSignIcon',
            color: 'light-success',
            title: 'R$0,00',
            subtitle: 'Cashback',
            customClass: 'mb-2 mb-sm-0',
          },
          // {
          //   icon: 'DollarSignIcon',
          //   color: 'light-success',
          //   title: 'R$0,00',
          //   subtitle: 'Lucro Líquido',
          //   customClass: 'mb-2 mb-sm-0',
          // },
        ],
      },
    }
  },
  created() {
    this.refresh(this.currentDate)
  },
  methods: {
    previous() {
      this.currentDate = subMonths(this.currentDate, 1)
      this.refresh(this.currentDate)
      this.disabledNext = false
    },
    next() {
      const currentDate = addMonths(this.currentDate, 1)
      const futureDate = isFuture(currentDate)
      if (!futureDate) {
        this.currentDate = currentDate
        this.refresh(this.currentDate)
      } else {
        this.disabledNext = true
      }
    },
    refresh(date) {
      const numberFormat = value => new Intl.NumberFormat('id').format(value)
      this.currentDateFormatted = format(date, 'MMMM yyyy', { locale: ptBrLocale }).toUpperCase()

      const dateParam = format(date, 'yyyy-MM')
      this.$http.get('/admin-report/physician-count', { params: { date: dateParam } })
        .then(response => {
          if (response.data.data.count !== undefined) {
            this.data.statisticsItems[0].title = response.data.data.count
          }
        })

      this.$http.get('/admin-report/institution-count', { params: { date: dateParam } })
        .then(response => {
          if (response.data.data.count !== undefined) {
            this.data.statisticsItems[1].title = response.data.data.count
          }
        })

      this.$http.get('/admin-report/insight-physician-duties', { params: { date: dateParam } })
        .then(response => {
          if (response.data.data.amount_scheduled !== undefined) {
            this.data.statisticsItems[2].title = `R$${numberFormat(response.data.data.amount_scheduled / 100)}`
          }
          if (response.data.data.amount_advanced !== undefined) {
            this.data.statisticsItems[3].title = `R$${numberFormat(response.data.data.amount_advanced / 100)}`
          }
          if (response.data.data.gross_profit !== undefined) {
            this.data.statisticsItems[4].title = `R$${numberFormat(response.data.data.gross_profit / 100)}`
          }
          if (response.data.data.institution_cashback !== undefined) {
            this.data.statisticsItems[5].title = `R$${numberFormat(response.data.data.institution_cashback / 100)}`
          }
          // if (response.data.data.net_profit !== undefined) {
          //   this.data.statisticsItems[5].title = `R$${numberFormat(response.data.data.net_profit / 100)}`
          // }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
