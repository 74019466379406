import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  resolveDeadlineVariant,
  resolveDeleteStatusVariant,
} from '@/views/financial/payment-remittance-list/usePaymentRemittanceList'

export default function useUserList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'data.contract_code', label: 'Contrato', sortable: false },
    { key: 'status', label: 'Status', sortable: false },
    { key: 'canceled_by', label: 'Cancelado por', sortable: false },
    { key: 'motive', label: 'Motivo', sortable: false },
    {
      key: 'data.company',
      label: 'Favorecido',
      sortable: false,
    },
    {
      key: 'data.account',
      label: 'Conta',
      sortable: false,
    },
    {
      key: 'data.value_physician_duty',
      label: 'Plantão',
      sortable: false,
    },
    { key: 'data.institution_name', label: 'Instituição', sortable: false },
    {
      key: 'data.physician_name',
      label: 'Médico',
      sortable: false,
    },
  ]
  const perPage = ref(25)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('created_at')
  const showImediato = ref(false)
  const isSortDirDesc = ref(true)
  const situationFilter = ref(null)
  const statusFilter = ref(null)
  const contractList = ref([])
  const institutionId = ref('')
  const institutionOptions = ref([])

  const checkAll = computed({
    get: () => {
      if (contractList.value.length === 0) return false
      return contractList.value.filter(user => user.checked).length === contractList.value.length
    },
    set: val => {
      contractList.value.forEach(user => {
        // eslint-disable-next-line no-param-reassign
        user.checked = val
      })
    },
  })

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, situationFilter, statusFilter, showImediato, institutionId], () => {
    refetchData()
  })

  const paymentRemittanceDeleteHistory = (ctx, callback) => {
    store
      .dispatch('app-contract/fetchPaymentRemittanceDeleteHistory', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDirection: isSortDirDesc.value ? 'desc' : 'asc',
        show_imediato: '1',
      })
      .then(response => {
        const { data: contracts } = response.data
        contractList.value = contracts
        callback(contractList.value)
        totalUsers.value = response.data.meta.pagination.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao buscar',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const createPaymentRemittance = async paymentDate => {
    const items = contractList.value.filter(contract => contract.checked).map(contract => ({ contract_id: contract.id, operation: null }))
    try {
      const { data } = await store
        .dispatch('app-contract/createPaymentRemittance', {
          items,
          payment_date: paymentDate,
        })
      return {
        id: data.data.id,
        code: data.data.code,
      }
    } catch (err) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Erro',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
    return null
  }

  return {
    paymentRemittanceDeleteHistory,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    refetchData,
    institutionId,
    institutionOptions,

    // Extra Filters
    situationFilter,
    statusFilter,
    checkAll,
    showImediato,
    createPaymentRemittance,
    resolveDeadlineVariant,
    resolveDeleteStatusVariant,
  }
}
