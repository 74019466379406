import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchRates(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/rate', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRate(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/rate/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRateByType(ctx, { type }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/rate/getByType?type=${type}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addRate(ctx, rateData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/rate', rateData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
