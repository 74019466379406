<template>
  <b-sidebar
    id="add-new-duty-type-sidebar"
    :visible="isAddNewDutyTypeSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-duty-type-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ dutyTypeId ? 'Editar classificaçao de plantão' : 'Nova classificaçao de plantão' }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Nome"
            rules="required"
          >
            <b-form-group
              label="Nome"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="dutyTypeData.type"
                autofocus
                :state="validationContext.touched ? getValidationState(validationContext) : null"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            name="active"
            rules="required"
          >

            <b-form-group label="Status">
              <b-form-radio-group
                id="duty-type-status"
                v-model="dutyTypeData.active"
                name="active"
                :options="statusOptions"
                :disabled="!dutyTypeId"
              />
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              class="mr-2"
              type="submit"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-primary"
              @click="hide"
            >
              Voltar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormRadioGroup,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, watch } from '@vue/composition-api'
import {
  required, alphaNum,
} from '@validations'
// import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import formError from '@core/comp-functions/forms/form-error'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormRadioGroup,
    // vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewDutyTypeSidebarActive',
    event: 'update:is-add-new-duty-type-sidebar-active',
  },
  props: {
    isAddNewDutyTypeSidebarActive: {
      type: Boolean,
      required: true,
    },
    dutyTypeId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      required,
      alphaNum,
    }
  },
  setup(props, { emit }) {
    const accessTypes = ref([])
    const blankDutyTypeData = {
      type: '',
      active: true,
    }

    const dutyTypeData = ref(JSON.parse(JSON.stringify(blankDutyTypeData)))
    const resetDutyTypeData = () => {
      dutyTypeData.value = JSON.parse(JSON.stringify(blankDutyTypeData))
    }

    function getDutyType(id) {
      store
        .dispatch('app-duty-type/fetchDutyType', { id })
        .then(response => {
          dutyTypeData.value = response.data.data
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetDutyTypeData)

    function onSubmit() {
      const dutyType = { ...dutyTypeData.value }
      store.dispatch(dutyType.id ? 'app-duty-type/updateDutyType' : 'app-duty-type/addDutyType', dutyType)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-duty-type-sidebar-active', false)
        })
        .catch(error => {
          const { setErrors, getErrorMessage } = formError(refFormObserver.value, error)
          setErrors()
          if (getErrorMessage()) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Erro',
                icon: 'XOctagonIcon',
                variant: 'danger',
                text: getErrorMessage(),
              },
            })
          }
        })
    }

    watch(() => props.isAddNewDutyTypeSidebarActive, show => {
      resetForm()
      resetDutyTypeData()
      if (show && props.dutyTypeId) {
        getDutyType(props.dutyTypeId)
      }
    })

    const statusOptions = [
      { text: 'Ativo', value: true },
      { text: 'Inativo', value: false },
    ]

    return {
      dutyTypeData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      accessTypes,
      statusOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-duty-type-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
