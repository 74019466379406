import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchBusinessProcessesToken() {
      return new Promise((resolve, reject) => {
        axios
          .get('/web/aws/token')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBusinessProcesses(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/business-process', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchContract(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/business-process/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createContract(ctx, { file, ...data }) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('file', file, file.name)
        formData.append('title', data.title)
        formData.append('description', data.description)
        formData.append('category', data.category)
        formData.append('html_content', data.html_content)
        formData.append('tags', data.tags)
        axios
          .post('/business-process', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateContract(ctx, { id, ...data }) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('file', data.file, data.file.name)
        formData.append('title', data.title)
        formData.append('description', data.description)
        formData.append('category', data.category)
        formData.append('html_content', data.html_content)
        formData.append('tags', data.tags)
        axios
          .put(`/business-process/${id}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
