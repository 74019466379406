<template>
  <div>
    <back-btn />
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="institutionData === undefined"
    >
      <h4 class="alert-heading">
        Erro ao buscar institution data
      </h4>
      <div class="alert-body">
        No institution found with this institution id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'institutions-list'}"
        >
          Institution List
        </b-link>
        for other institutions.
      </div>
    </b-alert>

    <template v-if="institutionData">
      <b-row class="match-height">
        <b-col
          cols="12"
          md="12"
          lg="7"
          xl="8"
        >
          <institution-view-institution-info-card :institution-data="institutionData" />
        </b-col>
        <b-col
          cols="12"
          md="12"
          lg="5"
          xl="4"
        >
          <institution-view-institution-address-card :institution-data="institutionData" />
        </b-col>
      </b-row>
      <physician-list :institution-id="institutionData.id" />
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BAlert, BLink, BRow, BCol,
} from 'bootstrap-vue'
import BackBtn from '@core/components/navigation/BackBtn.vue'
import userStoreModule from '../institutionStoreModule'
import PhysicianList from '../institution-edit/physician-list/PhysicianList.vue'
import InstitutionViewInstitutionInfoCard from './InstitutionViewInstitutionInfoCard.vue'
import InstitutionViewInstitutionAddressCard from './InstitutionViewInstitutionAddressCard.vue'

export default {
  components: {
    BAlert,
    BLink,
    BRow,
    BCol,
    BackBtn,
    // Local Components
    PhysicianList,
    InstitutionViewInstitutionInfoCard,
    InstitutionViewInstitutionAddressCard,
  },
  setup() {
    const institutionData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-institution'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-institution/fetchInstitution', { id: router.currentRoute.params.id })
      .then(response => { institutionData.value = response.data.data })
      .catch(error => {
        if (error.response.status === 404) {
          institutionData.value = undefined
        }
      })

    return {
      institutionData,
    }
  },
}
</script>

<style>

</style>
