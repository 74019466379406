<template>
  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <div class="mx-2 mb-2 mt-0">
      <b-row class="justify-content-end">
        <b-col
          cols="12"
          md="4"
          lg="2"
        >
          <label for="begin-date">Início em</label>
          <b-form-datepicker
            id="begin-date"
            v-model="beginDate"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="pt-BR"
            label-help=""
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          lg="2"
        >
          <label for="end-date">Fim em</label>
          <b-form-datepicker
            id="end-date"
            v-model="endDate"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="pt-BR"
            label-help=""
          />
        </b-col>
        <b-col
          cols="12"
          md="12"
          lg="8"
        >
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <div class="d-flex w-100">
                <div class="flex-grow-1">
                  <label for="user-id">Usuário</label>
                  <v-select
                    id="user-id"
                    v-model="userId"
                    name="user-id"
                    :options="userOptions"
                    label="label"
                    item-value="value"
                    item-text="label"
                  >
                    <template #option="{ value: { name, email } }">
                      <div class="font-weight-bold">
                        {{ name }}
                      </div>
                      <div class="user-email">
                        <span>{{ email }}</span>
                      </div>
                      <div class="user-type">
                        <span class="user-type-label">Tipo: </span>
                        <span class="user-type-value">{{ 'Médico' }}</span>
                      </div>
                    </template>
                    <span slot="no-options">Nenhum médico encontrado.</span>
                  </v-select>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <b-table
      ref="refInstitutionListTable"
      :items="fetchAudits"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="Nenhum registro correspondente encontrado"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
      <template #cell(type)="data">
        <b-badge
          pill
          :variant="`light-${resolveTypeVariant(data.item.auditable.type)}`"
          class="text-capitalize"
        >
          {{ data.item.auditable.type_translated }}
        </b-badge>
      </template>
      <template #cell(event)="data">
        <b-badge
          pill
          :variant="`light-${resolveEventVariant(data.item.event)}`"
          class="text-capitalize"
        >
          {{ data.item.event_translated }}
        </b-badge>
      </template>

      <template
        #cell(account)="{ item }"
        class="w-100"
      >
        <div class="d-flex flex-column">
          <div class="account-info">
            <div>Banco</div>

            <div>{{ item.financial_institution.bank_code }} - {{ item.financial_institution.name }}</div>
          </div>
          <div class="d-flex">
            <div class="account-info">
              <div>Agência</div>
              <div>{{ item.branch_number }}<span v-if="item.branch_code">-{{ item.branch_code }}</span></div>
            </div>
            <div class="account-info">
              <div>{{ resolveAccountType(item.account_type) }}</div>
              <div>{{ item.account_number }}-{{ item.account_code }}</div>
            </div>
          </div>
        </div>
      </template>

      <template
        #cell(account)="{ item }"
        class="w-100"
      >
        <div class="d-flex flex-column">
          <div class="account-info">
            <div>Banco</div>

            <div>{{ item.financial_institution.bank_code }} - {{ item.financial_institution.name }}</div>
          </div>
          <div class="d-flex">
            <div class="account-info">
              <div>Agência</div>
              <div>{{ item.branch_number }}<span v-if="item.branch_code">-{{ item.branch_code }}</span></div>
            </div>
            <div class="account-info">
              <div>{{ resolveAccountType(item.account_type) }}</div>
              <div>{{ item.account_number }}-{{ item.account_code }}</div>
            </div>
          </div>
        </div>
      </template>

      <template #cell(physician_name)="{ item: { physician_name } }">
        <div class="physician-name">
          {{ physician_name }}
        </div>
      </template>

      <template #cell(physician_cpf)="{ item: { physician_cpf } }">
        <div class="physician-cpf">
          {{ maskHiddenCpf2(physician_cpf) }}
        </div>
      </template>
      <template
        #cell(data.company)="{ item }"
        class="w-100"
      >
        <b-badge
          pill
          :variant="item.person_type === 'PJ' ? 'light-danger' : 'light-success'"
        >
          {{ item.person_type || 'PF' }}
        </b-badge>
        <div>
          <strong>{{ item.person_type === 'PJ' ? item.company_name : item.physician_name }}</strong>

          <div>{{ item.person_type === 'PJ' ? formatCnpj(item.company_cnpj) : maskHiddenCpf2(item.physician_cpf) }}</div>
        </div>
      </template>
      <template #cell(created_at)="data">
        {{ formatDate(new Date(data.item.created_at), 'dd/MM/yy HH:mm:ss') }}
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Exibir</label>
          <v-select
            v-model="perPage"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entradas</label>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>
      </b-row>
    </div>

  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BBadge, BFormDatepicker, BPagination,
} from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import { format } from 'date-fns'
import vSelect from 'vue-select'
import { formatCnpj, maskHiddenCpf2, resolveAccountType } from '@core/utils/utils'
import useAccountHistoryList from './useAccountHistoryList'
import accountHistoryStoreModule from '../accountHistoryStoreModule'

export default {
  components: {
    BPagination,
    BCard,
    BRow,
    BCol,
    vSelect,
    BTable,
    BBadge,
    BFormDatepicker,
  },
  methods: { resolveAccountType, maskHiddenCpf2 },
  setup() {
    const AUDITS_APP_STORE_MODULE_NAME = 'app-account-history'

    // Register module
    if (!store.hasModule(AUDITS_APP_STORE_MODULE_NAME)) store.registerModule(AUDITS_APP_STORE_MODULE_NAME, accountHistoryStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AUDITS_APP_STORE_MODULE_NAME)) store.unregisterModule(AUDITS_APP_STORE_MODULE_NAME)
    })

    const audit = ref(null)

    const {
      fetchAudits,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInstitutionListTable,
      refetchData,
      resolveTypeVariant,
      resolveEventVariant,
      beginDate,
      endDate,
      userId,
      userOptions,
    } = useAccountHistoryList()

    store.dispatch('app-account-history/fetchUsers', { perPage: 99999999, sort: 'name' })
      .then(response => {
        userOptions.value = response.data.data.map(user => ({
          value: user,
          label: `${user.name}`,
        }))
      })

    return {
      formatCnpj,
      audit,
      userId,
      userOptions,
      beginDate,
      endDate,
      resolveEventVariant,
      fetchAudits,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInstitutionListTable,
      formatDate: format,
      refetchData,
      resolveTypeVariant,
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

.per-page-selector {
  width: 90px;
}

.transaction-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.user-email {
  font-size: 12px;
}
.user-type {
  font-size: 12px;
  .user-type-label {
    color: #5e5873;
  }
  .user-type-value {
    font-weight: bold!important;
  }
}
.account-info {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  div {
    font-size: 13px;
    line-height: 13px;
  }
  div:first-child {
    font-size: 10px;
    color: #959a9c;
  }
}
</style>
