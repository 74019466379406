import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useFinancialInstitutionsList() {
  // Use toast
  const toast = useToast()

  const refFinancialInstitutionListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'bank_code', sortable: true, label: 'Código' },
    { key: 'name', sortable: true, label: 'Nome' },
    { key: 'active', label: 'Status' },
    { key: 'actions', label: 'Ações' },
  ]
  const perPage = ref(10)
  const totalFinancialInstitutions = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(false)
  const situationFilter = ref(null)
  const statusFilter = ref(null)
  const showActiveOnly = ref({ label: 'Todos', value: null })
  const activeOptions = ref([
    { label: 'Todos', value: null },
    { label: 'Ativos', value: '1' },
    { label: 'Inativos', value: '0' },
  ])

  const dataMeta = computed(() => {
    const localItemsCount = refFinancialInstitutionListTable.value ? refFinancialInstitutionListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalFinancialInstitutions.value,
    }
  })

  const refetchData = () => {
    refFinancialInstitutionListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, situationFilter, statusFilter, showActiveOnly], () => {
    refetchData()
  })

  const fetchFinancialInstitutions = (ctx, callback) => {
    store
      .dispatch('app-financial-institution/fetchFinancialInstitutions', {
        q: searchQuery.value,
        active: showActiveOnly.value.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDirection: isSortDirDesc.value ? 'desc' : 'asc',
        situation: situationFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { data: dutyTypes } = response.data
        const dutyTypeList = dutyTypes.map(dutyType => {
          const p = { ...dutyType }
          return p
        })
        callback(dutyTypeList)
        totalFinancialInstitutions.value = response.data.meta.pagination.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao buscar',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveFinancialInstitutionStatusVariant = active => {
    if (active === false) return 'warning'
    if (active === true) return 'success'
    return 'primary'
  }

  return {
    fetchFinancialInstitutions,
    tableColumns,
    perPage,
    currentPage,
    totalFinancialInstitutions,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refFinancialInstitutionListTable,

    resolveFinancialInstitutionStatusVariant,
    refetchData,

    // Extra Filters
    situationFilter,
    statusFilter,
    showActiveOnly,
    activeOptions,
  }
}
