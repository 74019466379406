import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useInstitutionList() {
  // Use toast
  const toast = useToast()

  const refInstitutionListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'id', sortable: true, sortKey: 'name', label: 'Instituição',
    },
    { key: 'email', sortable: true },
    { key: 'cnpj', sortable: true },
    { key: 'status', sortable: false },
    { key: 'actions', label: 'Ações' },
  ]
  const perPage = ref(10)
  const totalInstitutions = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(false)
  const situationFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refInstitutionListTable.value ? refInstitutionListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInstitutions.value,
    }
  })

  const refetchData = () => {
    refInstitutionListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, situationFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchInstitutions = (ctx, callback) => {
    store
      .dispatch('app-institution/fetchInstitutions', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value ? 'desc' : 'asc',
        show_imediato: true,
      })
      .then(response => {
        const { data, meta } = response.data
        const institutions = data.map(institution => {
          const i = { ...institution }
          i.cnpj = institution.cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
          return i
        })
        callback(institutions)
        totalInstitutions.value = meta.pagination.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao buscar',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveInstitutionRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveInstitutionRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveInstitutionStatusVariant = inactivatedAt => {
    if (!inactivatedAt) return 'success'
    if (inactivatedAt) return 'primary'
    return 'primary'
  }

  const resolveInstitutionStatusTitle = inactivatedAt => {
    if (!inactivatedAt) return 'ativo'
    if (inactivatedAt) return 'inativo'
    return 'primary'
  }

  return {
    fetchInstitutions,
    tableColumns,
    perPage,
    currentPage,
    totalInstitutions,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refInstitutionListTable,

    resolveInstitutionRoleVariant,
    resolveInstitutionRoleIcon,
    resolveInstitutionStatusVariant,
    resolveInstitutionStatusTitle,
    refetchData,

    // Extra Filters
    situationFilter,
    planFilter,
    statusFilter,
  }
}
