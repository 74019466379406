<template>
  <div>

    <!-- User Info: Input Fields -->
    <validation-observer ref="formValidation">
      <b-form
        @submit.prevent
      >
        <!-- Header: Personal Info -->
        <div class="d-flex">
          <feather-icon
            icon="HomeIcon"
            size="19"
          />
          <h4 class="mb-1 ml-50">
            Geral
          </h4>
        </div>

        <b-row>
          <!-- Field: Nome -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Razão Social"
              vid="name"
              rules="required"
            >
              <b-form-group
                label="Razão Social"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="institutionData.name"
                  :disabled="readOnly"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Full Name -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Nome Fantasia"
              vid="fancy_name"
              rules="required"
            >
              <b-form-group
                label="Nome Fantasia"
                label-for="fancy_name"
              >
                <b-form-input
                  id="fancy_name"
                  v-model="institutionData.fancy_name"
                  :disabled="readOnly"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- CNPJ -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <validation-provider
              #default="{ errors }"
              name="CNPJ"
              vid="cnpj"
              rules="required|cnpj"
            >
              <b-form-group
                label="CNPJ"
                label-for="cnpj"
              >
                <b-form-input
                  id="cnpj"
                  v-model="institutionData.cnpj"
                  v-mask="'##.###.###/####-##'"
                  :disabled="readOnly"
                  :state="errors.length > 0 ? false:null"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: contact -->
          <b-col
            cols="12"
            md="6"
            lg="3"
          >
            <validation-provider
              #default="{ errors }"
              name="Contato"
              vid="contact"
              rules="required"
            >
              <b-form-group
                label="Contato"
                label-for="contact"
              >
                <b-form-input
                  id="contact"
                  v-model="institutionData.contact"
                  :disabled="readOnly"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- phone -->
          <b-col
            cols="12"
            md="6"
            lg="3"
          >
            <validation-provider
              #default="{ errors }"
              name="Telefone"
              vid="phone"
              rules="required"
            >
              <b-form-group
                label="Telefone"
                label-for="phone"
              >
                <b-form-input
                  id="phone"
                  v-model="institutionData.phone"
                  v-mask="'(##) #####-####'"
                  :disabled="readOnly"
                  :state="errors.length > 0 ? false:null"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Email -->
          <b-col
            cols="12"
            md="6"
            lg="3"
          >
            <validation-provider
              #default="{ errors }"
              name="Email"
              vid="email"
              rules="required|email"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="institutionData.email"
                  :disabled="readOnly"
                  type="email"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Status -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group>
              <label for="status">
                Status
                <app-help
                  title="Ajuda: Status Instituição"
                  description="Caso Inativo usuários da empresa perdem acesso ao sistema cliente da Imediato e o médico não vai conseguir antecipar os plantões da empresa."
                />
              </label>
              <validation-provider
                #default="{ errors }"
                name="Status"
                vid="status"
                rules="required"
              >
                <v-select
                  id="status"
                  v-model="institutionData.status"
                  name="status"
                  :disabled="readOnly"
                  :options="statusOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="status"
                  :class="errors.length > 0 ? 'is-invalid':null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>

        <!-- Header: Finacial -->
        <div class="d-flex mt-2">
          <feather-icon
            icon="MapPinIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            Financeiro
          </h4>
        </div>

        <b-row class="mt-1">
          <!-- Field: Limit -->
          <b-col
            cols="12"
            md="3"
            lg="2"
          >
            <validation-provider
              #default="{ errors }"
              name="Limite"
              vid="limit"
              rules="required"
            >
              <b-form-group
                label="Limite"
                label-for="limit"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <cleave
                  id="limit"
                  v-model="institutionData.limit"
                  :disabled="readOnly"
                  name="limit"
                  class="form-control"
                  :options="options.number"
                  :class="errors.length > 0 ? 'is-invalid':null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: cashback -->
          <b-col
            cols="12"
            md="3"
            lg="2"
          >
            <validation-provider
              #default="{ errors }"
              name="Cashback"
              vid="cashback"
              rules="required"
            >
              <b-form-group
                label="Cashback"
                label-for="cashback"
              >
                <b-input-group
                  label="Cashback"
                  label-for="cashback"
                  append="%"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <cleave
                    id="cashback"
                    v-model="institutionData.cashback"
                    :disabled="readOnly"
                    name="cashback"
                    autofocus
                    class="form-control"
                    :options="options.percent"
                    :raw="false"
                    placeholder="0,00"
                    maxlength="6"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: payday -->
          <b-col
            cols="12"
            md="3"
            lg="2"
          >
            <validation-provider
              #default="{ errors }"
              name="Dias p/ Pagamento"
              vid="payday"
              rules="required"
            >
              <b-form-group
                label="Dias p/ Pagamento"
                label-for="payday"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <cleave
                  id="payday"
                  v-model="institutionData.payday"
                  :disabled="readOnly"
                  class="form-control"
                  :options="options.percent"
                  :class="errors.length > 0 ? 'is-invalid':null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: payday -->
          <b-col
            cols="12"
            md="3"
            lg="2"
          >
            <validation-provider
              #default="{ errors }"
              name="Forma de pagamento"
              vid="payment_method"
              rules="required"
            >
              <b-form-group
                label="Forma de pagamento"
                label-for="payment_method"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <v-select
                  v-model="institutionData.payment_method"
                  :disabled="readOnly"
                  name="payment_method"
                  :options="paymentMethodsOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="payment_method"
                  :class="errors.length > 0 ? 'is-invalid':null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <!-- Field: service_fee -->
          <b-col
            cols="12"
            md="3"
            lg="2"
          >
            <validation-provider
              #default="{ errors }"
              name="Taxa de serviço"
              vid="service_fee"
              rules="required"
            >
              <b-form-group
                label="Taxa de serviço"
                label-for="service_fee"
              >
                <b-input-group
                  label="Taxa de serviço"
                  label-for="service_fee"
                  append="%"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <cleave
                    id="service_fee"
                    v-model="institutionData.service_fee"
                    :disabled="readOnly"
                    name="Taxa de serviço"
                    autofocus
                    class="form-control"
                    :options="options.percent"
                    :raw="false"
                    placeholder="0,00"
                    maxlength="6"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: interest -->
          <b-col
            cols="12"
            md="3"
            lg="2"
          >
            <validation-provider
              #default="{ errors }"
              name="Juros"
              vid="interest"
              rules="required"
            >
              <b-form-group
                label="Juros"
                label-for="interest"
              >
                <b-input-group
                  label="Juros"
                  label-for="interest"
                  append="%"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <cleave
                    id="interest"
                    v-model="institutionData.interest"
                    :disabled="readOnly"
                    autofocus
                    class="form-control"
                    :options="options.percent"
                    :raw="false"
                    placeholder="0,00"
                    maxlength="6"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col
            cols="12"
            md="6"
          >
            <div>
              <label for="textarea-default">
                <b-form-checkbox
                  v-model="institutionData.financial_restriction"
                  :disabled="readOnly"
                  name="financial_restriction_reason"
                  class="custom-control-danger"
                  switch
                  inline
                  @change="changeRestriction"
                >
                  Com restrição?
                  <app-help
                    title="Ajuda: Instituição com restrição"
                    description="Bloqueia apenas a possibilidade do médico antecipar o plantão da empresa no app."
                  />
                </b-form-checkbox>
              </label>
              <validation-provider
                #default="{ errors }"
                name="Descrição da Restrição"
                vid="financial_restriction_reason"
                :rules="institutionData.financial_restriction ? 'required' : ''"
              >
                <b-form-textarea
                  id="financial_restriction_reason"
                  v-model="institutionData.financial_restriction_reason"
                  name="financial_restriction_reason"
                  :disabled="readOnly || !institutionData.financial_restriction"
                  placeholder="Descrição da restrição"
                  rows="3"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </b-col>
        </b-row>

        <div class="d-flex mt-2">
          <feather-icon
            icon="MapPinIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            Endereço
          </h4>
        </div>

        <b-row class="mt-1">
          <!-- Field: Postcode -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <validation-provider
              #default="{ errors }"
              name="CEP"
              vid="address.postal_code"
              rules="required|length:9"
            >
              <b-form-group
                label="CEP"
                label-for="address.postal_code"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="address.postal_code"
                    v-model="institutionData.address.postal_code"
                    v-mask="'#####-###'"
                    :disabled="readOnly"
                    placeholder="00000-000"
                    :state="errors.length > 0 ? false:null"
                    :loading="loadingAddressPostalCode"
                    @input="changePostalCode(institutionData.address.postal_code)"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: State -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Estado"
              label-for="address.state"
            >
              <v-select
                v-model="institutionData.address.state_id"
                autocomplete="state"
                :options="stateOptions"
                :clearable="false"
                label="name"
                :reduce="value => value.id"
                input-id="state"
                :disabled="readOnly || !stateOptions.length"
                :loading="loadingAddressStates"
                @option:selected="selectedState"
              />
            </b-form-group>
          </b-col>

          <!-- Field: City -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Cidade"
              label-for="address.city"
            >
              <v-select
                v-model="institutionData.address.city_id"
                autocomplete="city"
                :options="cityOptions"
                :clearable="false"
                label="name"
                :reduce="value => value.id"
                input-id="city"
                :disabled="readOnly || !cityOptions.length"
                :loading="loadingAddressCities"
              />
            </b-form-group>
          </b-col>

          <!-- Field: neighborhood -->
          <b-col
            cols="12"
            md="6"
            lg="2"
          >
            <validation-provider
              #default="{ errors }"
              name="Bairro"
              vid="address.neighborhood"
              rules="required"
            >
              <b-form-group
                label="Bairro"
                label-for="address.neighborhood"
              >
                <b-form-input
                  id="address.neighborhood"
                  v-model="institutionData.address.neighborhood"
                  :disabled="readOnly"
                  autocomplete="neighborhood"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Street -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Logradouro"
              vid="address.street"
              rules="required"
            >
              <b-form-group
                label="Logradouro"
                label-for="address.street"
              >
                <b-form-input
                  id="address.street"
                  v-model="institutionData.address.street"
                  :disabled="readOnly"
                  autocomplete="street"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Number -->
          <b-col
            cols="12"
            md="2"
            lg="2"
          >
            <validation-provider
              #default="{ errors }"
              name="Nº"
              vid="address.number"
              rules="required"
            >
              <b-form-group
                label="Nº"
                label-for="address.number"
              >
                <b-form-input
                  id="address.number"
                  v-model="institutionData.address.number"
                  :disabled="readOnly"
                  autocomplete="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Complement -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Complemento"
              label-for="address-line-2"
            >
              <b-form-input
                id="address-line-2"
                v-model="institutionData.address.complement"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>

        </b-row>
      </b-form>
    </validation-observer>

    <!-- Action Buttons -->
    <b-button
      v-if="!readOnly"
      variant="secondary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="onSubmit"
    >
      {{ isEditMode ? 'Salvar' : 'Criar & Continuar' }}
    </b-button>
    <b-button
      :to="{ name: 'institution-list' }"
      variant="outline-primary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Voltar
    </b-button>
  </div>
</template>

<script>
import Cleave from 'vue-cleave-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BInputGroup, BFormCheckbox, BFormTextarea,
} from 'bootstrap-vue'
import {
  required, email, cnpj, length,
} from '@validations'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  computed, onUnmounted, ref,
} from '@vue/composition-api'
import store from '@/store'
import institutionStoreModule from '@/views/institution/institutionStoreModule'
import { useRouter } from '@core/utils/utils'
import AppHelp from '@core/components/app-help/AppHelp.vue'
import useInstitutionList from '../institution-list/useInstitutionList'

export default {
  components: {
    AppHelp,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroup,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    Cleave,
  },
  props: {
    institutionData: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      cnpj,
      length,
      daysOptions: [
        ...new Array(30).fill().map((_, i) => ({ label: `${i + 1}`, value: i + 1 })),
      ],
      options: {
        customDelimiter: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          numericOnly: true,
        },
        phone: {
          delimiters: ['(', ')', ' ', '-'],
          blocks: [0, 2, 0, 4, 5],
          numericOnly: true,
          delimiterLazyShow: true,
        },
        phoneWith9: {
          delimiters: ['(', ')', ' ', '-'],
          blocks: [0, 2, 0, 5, 4],
          numericOnly: true,
          delimiterLazyShow: true,
        },
        percent: {
          numericOnly: true,
          numeral: true,
          numeralThousandsGroupStyle: 'none',
          numeralDecimalMark: ',',
          numeralDecimalScale: 4,
          numeralPositiveOnly: true,
          rawValueTrimPrefix: true,
        },
        number: {
          numeralPositiveOnly: true,
          numericOnly: true,
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.',
          numeralDecimalScale: 2,
          prefix: 'R$ ',
          rawValueTrimPrefix: true,
        },
        postalCode: {
          delimiters: ['-'],
          blocks: [5, 3],
          numericOnly: true,
        },
      },
    }
  },
  setup({ institutionData }) {
    const { router } = useRouter()
    const { resolveInstitutionRoleVariant } = useInstitutionList()

    const stateOptions = ref([])
    const cityOptions = ref([])
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const loadingAddressPostalCode = ref(false)
    const loadingAddressStates = ref(true)
    const loadingAddressCities = ref(true)

    const statusOptions = [
      { label: 'Ativo', value: 'active' },
      { label: 'Inativo', value: 'inactive' },
    ]
    const paymentMethodsOptions = [
      { label: 'Avcapital', value: 'avcapital' },
      { label: 'Imediato', value: 'imediato' },
    ]

    const INSTITUTION_APP_STORE_MODULE_NAME = 'app-institution-tab'

    // Register module
    if (!store.hasModule(INSTITUTION_APP_STORE_MODULE_NAME)) store.registerModule(INSTITUTION_APP_STORE_MODULE_NAME, institutionStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INSTITUTION_APP_STORE_MODULE_NAME)) store.unregisterModule(INSTITUTION_APP_STORE_MODULE_NAME)
    })

    const isEditMode = computed(() => !!institutionData.id)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      institutionData.avatar = base64
    })

    const getAddressByZipCode = zipCode => store.dispatch(`${INSTITUTION_APP_STORE_MODULE_NAME}/getAddressByZipCode`, { zipCode })

    function fetchCities(stateId) {
      loadingAddressCities.value = true
      return store.dispatch('app-institution-tab/fetchCities', { state_id: stateId })
        .then(response => {
          const cities = response.data.data
          cityOptions.value = response.data.data
          loadingAddressCities.value = false
          return cities
        })
        .catch(() => {
          loadingAddressCities.value = false
        })
    }

    function fetchStates() {
      loadingAddressStates.value = true
      return store.dispatch('app-institution-tab/fetchStates')
        .then(response => {
          stateOptions.value = response.data.data
          loadingAddressStates.value = false
          return stateOptions.value
        })
    }

    function selectedState() {
      return fetchCities(institutionData.address.state_id)
        .then(cities => {
          // eslint-disable-next-line no-param-reassign
          institutionData.address.city_id = cities[0].id
        })
    }

    if (institutionData.address.state_id) {
      fetchStates()
      fetchCities(institutionData.address.state_id)
    } else {
      fetchStates()
        .then(states => {
          // eslint-disable-next-line no-param-reassign
          institutionData.address.state_id = states[0].id
          selectedState()
        })
    }

    const getState = stateCode => stateOptions.value.find(s => s.abbreviation === stateCode)
    function changePostalCode(postalCode) {
      if (!postalCode || postalCode.length !== 9) return
      getAddressByZipCode(postalCode)
        .then(async data => {
          const state = getState(data.state)
          await fetchCities(state.id)
          const city = cityOptions.value.find(c => c.name === data.city)
          /* eslint-disable no-param-reassign */
          institutionData.address.street = data.street
          institutionData.address.neighborhood = data.neighborhood
          institutionData.address.complement = data.complement
          institutionData.address.city_id = city.id
          institutionData.address.state_id = state.id
        })
    }

    function onSubmit() {
      const type = isEditMode.value ? 'app-institution/updateInstitution' : 'app-institution/createInstitution'
      store.dispatch(type, institutionData)
        .then(response => {
          if (isEditMode.value) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Salvo',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Salvo com sucesso!',
              },
            })
          } else {
            const { id } = response.data.data
            router.push({ name: 'institution-edit', params: { id }, query: { tab: 'users' } })
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            const errors = (error.response && error.response.status === 422 && error.response.data.errors) || {}
            const message = Object.keys(errors).map(key => errors[key][0]).join(',')
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Erro',
                icon: 'XOctagonIcon',
                variant: 'danger',
                text: message,
              },
            })
            const errorMessages = Object.keys(errors).reduce((messages, key) => ({ ...messages, [key]: errors[key][0] }), {})
            this.$refs.formValidation.setErrors(errorMessages)
          } else {
            const message = (error.response && error.response.data.message) || 'Erro desconhecido'
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Erro',
                icon: 'XOctagonIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
        })
    }

    function changeRestriction() {
      if (!institutionData.financial_restriction) {
        institutionData.financial_restriction_reason = null
      }
    }

    return {
      changeRestriction,
      resolveInstitutionRoleVariant,
      avatarText,
      statusOptions,
      paymentMethodsOptions,
      stateOptions,
      cityOptions,
      refInputEl,
      previewEl,
      inputImageRenderer,

      loadingAddressPostalCode,
      loadingAddressCities,
      loadingAddressStates,
      onSubmit,
      fetchCities,
      selectedState,
      isEditMode,
      changePostalCode,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.v-select.is-invalid [role="combobox"] {
  border-color: #ea5455;
}
</style>
