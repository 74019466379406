<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>
    <div
      v-if="!isProduction"
      class="beta"
    >
      BETA
    </div>
    <div
      class="build"
    >
      {{ build }}
    </div>
  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { ref, watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'
import useSanctum from '@/auth/sanctum/useSanctum'
import router from '@/router'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    isProduction() {
      return !/(beta|lourivaldo)/.test(window.location.host)
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')

    // Check auth before app start
    useSanctum
      .me()
      .catch(() => {
        useSanctum.resetState()
        router.push({ name: 'login' }).catch(() => {})
      })
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const build = ref(process.env.VUE_APP_BUILD_ID || '20241003')

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      build,
    }
  },
}
</script>

<style lang="scss">
.beta {
  z-index: 999;
  animation: blinker 3s linear infinite;
  background-color: #e85d2a;
  font-weight: bold;
  color: white;
  position: absolute;
  width: 70px;
  padding: 5px;
  border-radius: 0 0 5px 5px;
  text-align: center;
  font-size: 18px;
  top: 0;
  left: calc(50% - 35px);
}

.build {
  z-index: 999;
  color: rgba(130, 127, 127, 0.89);
  position: absolute;
  width: 70px;
  padding: 5px;
  border-radius: 0 0 5px 5px;
  text-align: center;
  font-size: 13px;
  bottom: 0;
  left: 0;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
