<template>
  <b-row>
    <!-- Per Page -->
    <b-col
      cols="12"
      md="12"
      lg="7"
      class="
        d-flex
        align-items-center
        justify-content-start
        mb-1 mb-lg-0
      "
    >
      <div
        class="d-flex align-items-center justify-content-start mr-1 mb-1 mb-md-0 w-100"
      >
        <label>Exibir</label>
        <v-select
          :value="perPage"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="perPageOptions"
          :clearable="false"
          class="per-page-selector d-inline-block mx-50"
          :searchable="false"
          @input="(val) => $emit('update:perPage', val)"
        />
        <label>entradas</label>
      </div>
      <v-select
        id="classification-id"
        :value="classificationFilter"
        class="w-100 mr-1"
        name="classification-id"
        :options="classificationOptions"
        label="label"
        item-value="value"
        item-text="label"
        placeholder="Classificação"
        @input="(val) => $emit('update:classificationFilter', val)"
      >
        <template #option="{ label, value }">
          <feather-icon
            icon="SmartphoneIcon"
            size="20"
            :color="getClassificationColor(value)"
          />
          <span>{{ label }}</span>
        </template>
        <template
          #selected-option="{ label, value }"
          class="d-flex align-items-center justify-content-start"
        >
          <feather-icon
            icon="SmartphoneIcon"
            size="20"
            :color="getClassificationColor(value)"
          />
          <span class="d-none d-xl-inline">{{ label.length > 17 ? label.substring(0, 16) + '...' : label }}</span>
        </template>
      </v-select>
      <v-select
        id="status-id"
        :value="statusFilter"
        class="w-100"
        name="status-id"
        :options="statusOptions"
        label="label"
        item-value="value"
        item-text="label"
        placeholder="Status"
        @input="(val) => $emit('update:statusFilter', val)"
      />
    </b-col>
    <!-- Search -->
    <b-col
      class="mt-1 mt-lg-0 d-flex align-items-center justify-content-start"
      cols="12"
      md="12"
      lg="5"
    >
      <div class="d-flex align-items-center justify-content-end w-100">
        <b-form-input
          :value="searchQuery"
          class="d-inline-block mr-1"
          placeholder="Buscar..."
          debounce="1000"
          @input="(val) => $emit('update:searchQuery', val)"
        />
        <b-button
          variant="primary"
          :disabled="!$can('read', 'Institution')"
          @click="downloadReport"
        >
          <span class="text-nowrap">
            Exportar
            <feather-icon
              icon="DownloadIcon"
              size="16"
            />
          </span>
        </b-button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormInput, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
    vSelect,
  },
  props: {
    isAddNewPhysicianSidebarActive: {
      type: Boolean,
      default: false,
    },
    perPage: {
      type: Number,
      required: true,
    },
    statusFilter: {
      type: [Object, null],
      default: null,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    perPageOptions: {
      type: Array,
      required: true,
    },
    classificationFilter: {
      type: [Object, null],
      default: null,
    },
    classificationOptions: {
      type: Array,
      required: true,
    },
    searchQuery: {
      type: [String, null],
      default: null,
    },
    downloadReport: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const getClassificationColor = color => {
      const colors = {
        red: '#df7273',
        yellow: '#ffb976',
        green: '#48da89',
      }
      return colors[color]
    }

    return {
      getClassificationColor,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
