<template>
  <div>
    <audit-header :audit="audit" />
    <hr>
    <div>
      <h6 class="text-capitalize mb-0">
        Código
      </h6>
      <div>{{ value.bank_code }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        Nome
      </h6>
      <div>{{ value.name }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        Status
      </h6>
      <div>{{ value.active ? 'Ativo' : 'Inativo' }}</div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { format } from 'date-fns'
import {
  formatCep, formatCnpj, formatCurrency, formatPhone,
} from '@core/utils/utils'
import AuditHeader from './Header.vue'

export default {
  components: {
    AuditHeader,
  },
  props: {
    audit: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const value = ref(props.audit.current_values)
    const user = ref(props.audit.user)
    const createdAt = ref(props.audit.created_at)
    return {
      formatCep,
      formatCnpj,
      formatPhone,
      formatCurrency,
      value,
      user,
      createdAt,
      formatDate: format,
    }
  },
}
</script>

<style lang="scss" scoped>
hr {
  margin-top: 4px;
  margin-bottom: 10px;
}
h6 {
  margin-top: 3px;
}
</style>
