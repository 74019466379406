import { ref, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { format, subDays } from 'date-fns'
import { formatDateTimeDDMMYYHHMM, formatPhone } from '@core/utils/utils'

export default function usePhysicianList() {
  // Use toast
  const toast = useToast()

  const refPhysicianListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'user', sortable: true, sortKey: 'name', label: 'Médico',
    },
    { key: 'email', sortable: true },
    { key: 'cpf', sortable: true },
    {
      key: 'phone',
      label: 'Telefone',
      sortable: false,
      formatter: formatPhone,
    },
    { key: 'status', sortable: true, sortKey: 'confirmed_at' },
    { key: 'institution', label: 'Instituição', sortable: false },
    {
      key: 'created_at',
      label: 'Criado',
      sortable: false,
      formatter: v => formatDateTimeDDMMYYHHMM(new Date(v)),
    },
  ]
  const perPage = ref(25)
  const totalPhysicians = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(true)

  const beginDate = ref(format(subDays(new Date(), 5), 'yyyy-MM-dd'))
  const endDate = ref(format(new Date(), 'yyyy-MM-dd'))
  const institutionId = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refPhysicianListTable.value ? refPhysicianListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPhysicians.value,
    }
  })

  const refetchData = () => {
    refPhysicianListTable.value.refresh()
  }

  const getReportParams = () => ({
    institution: institutionId.value ? institutionId.value.value : '',
    begin: beginDate.value,
    end: endDate.value,
    perPage: perPage.value,
    page: currentPage.value,
    sortBy: sortBy.value,
    sortDirection: isSortDirDesc.value ? 'desc' : 'asc',
  })

  const fetchPhysicians = (ctx, callback) => {
    store
      .dispatch('app-physician/fetchReportPhysicians', getReportParams())
      .then(response => {
        const { data: physicians, meta } = response.data
        const physicianList = physicians.map(physician => {
          const p = { ...physician, status: 'active' }
          p.cpf = p.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '***.$2.$3-**')
          return p
        })
        callback(physicianList)
        totalPhysicians.value = meta.pagination.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao buscar',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const downloadReport = () => {
    store.dispatch('app-physician/downloadReportPhysicians', getReportParams())
      .then(response => {
        const blob = new Blob([response.data], { type: response.headers['content-type'] })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        const now = format(new Date(), 'yyyy-MM-dd hh:mm:ss')
        link.download = `Medicos_${now}.xlsx`
        link.click()
        URL.revokeObjectURL(link.href)
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolvePhysicianRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolvePhysicianRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolvePhysicianStatusVariant = status => {
    if (status) return 'success'
    return 'primary'
  }

  return {
    fetchPhysicians,
    tableColumns,
    perPage,
    currentPage,
    totalPhysicians,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPhysicianListTable,

    resolvePhysicianRoleVariant,
    resolvePhysicianRoleIcon,
    resolvePhysicianStatusVariant,
    refetchData,
    downloadReport,

    // Extra Filters
    institutionId,
    beginDate,
    endDate,
  }
}
