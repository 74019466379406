<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <b-table
        ref="refWorkplaceListTable"
        style="min-height: 70vh"
        :sticky-header="true"
        class="position-relative workplace-table"
        :items="fetchWorkplaces"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro correspondente encontrado"
      >
        <template #head(blocked_at)="{ label }">
          {{ label }}
          <app-help
            title="Ajuda: Bloqueio cliente"
            description="Essa opção informa se o cliente bloqueou a inserção de novos plantões em uma unidade específica."
          />
        </template>
        <template #head(physician_duty_launch_mode)="{ label }">
          {{ label }}
          <app-help
            title="Ajuda: Tipo Lançamento"
            description="Identifica como o plantão da unidade será inserido no sistema, podendo ser através do próprio sistema da Imediato, por integração, ou uma combinação de ambos."
          />
        </template>
        <template #head(contract_effective_date)="{ label }">
          {{ label }}
          <app-help
            title="Ajuda: Vigência contrato"
            description="Permite que a imediato insira uma data de vigência do cliente com a empresa que ele fornece serviço."
          />
        </template>
        <template #head(can_advance)="{ label }">
          {{ label }}
          <app-help
            title="Ajuda: Pode Adiantar"
            description="Permite que a Imediato libere ou bloqueie a antecipação de plantões de uma unidade específica."
          />
        </template>
        <template #head(request_enabled_at)="{ label }">
          {{ label }}
          <app-help
            title="Ajuda: Plantão Liberado"
            description="Controlar o lançamento de plantões por parte da empresa cliente, garantindo que não sejam inseridos plantões com datas anteriores à permitida pela Imediato. Essa data é obrigatória e a ausência dessa informação no sistema impede o cliente de fazer qualquer lançamento."
          />
        </template>

        <template #cell(physician_duty_launch_mode)="data">
          <b-badge
            pill
            :variant="`light-${getLaunchMode(data.item.physician_duty_launch_mode).variant}`"
            class="text-capitalize"
          >
            {{ getLaunchMode(data.item.physician_duty_launch_mode).name }}
          </b-badge>
        </template>

        <template #cell(request_enabled_at)="data">
          <div v-if="data.item.request_enabled_at">
            {{ formatDateTimeDDMMYYYY(data.item.request_enabled_at) }}
          </div>
          <b-badge
            v-else
            pill
            variant="light-danger"
            class="text-capitalize"
          >
            Bloqueado
          </b-badge>
        </template>

        <template #cell(blocked_at)="data">
          <b-badge
            v-if="data.item.blocked_at"
            pill
            variant="light-danger"
            class="text-capitalize"
          >
            {{ formatDateTimeDDMMYYYY(data.item.blocked_at) }}
          </b-badge>
          <div v-else>
            Não
          </div>
        </template>

        <template #cell(actions)="{ item }">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="!item.request_enabled_at"
              :disabled="!$can('manage', 'Institution')"
              @click="showEnableForRequest(item)"
            >
              <feather-icon icon="UnlockIcon" />
              <span class="align-middle ml-50">Editar/Liberar Plantão</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-else
              :disabled="!$can('manage', 'Institution')"
              @click="showLockEnableForRequest(item)"
            >
              <feather-icon icon="LockIcon" />
              <span class="align-middle ml-50">Editar/Bloquear Plantão</span>
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="!$can('manage', 'Institution')"
              @click="showLaunchMode(item)"
            >
              <feather-icon icon="SendIcon" />
              <span class="align-middle ml-50">Editar T. Lançamento</span>
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="!$can('manage', 'Institution')"
              @click="showPayrollValueEdit(item)"
            >
              <feather-icon icon="DollarSignIcon" />
              <span class="align-middle ml-50">Editar Valor Folha</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="item.can_advance"
              :disabled="!$can('manage', 'Institution')"
              @click="showCanAdvance(item, false)"
            >
              <feather-icon icon="CircleIcon" />
              <span class="align-middle ml-50">Desabilitar antecipação</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="!item.can_advance"
              :disabled="!$can('manage', 'Institution')"
              @click="showCanAdvance(item, true)"
            >
              <feather-icon icon="CircleIcon" />
              <span class="align-middle ml-50">Habilitar antecipação</span>
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="!$can('manage', 'Institution')"
              @click="showContractEffectiveDate(item)"
            >
              <feather-icon icon="ClockIcon" />
              <span class="align-middle ml-50">Alterar vigência contrato</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>

    <b-modal
      id="modal-workplace"
      ref="workplaceModal"
      :title="workplaceEnableForRequest.workplace_name ? `Editar/Liberar Plantão (${workplaceEnableForRequest.workplace_name})` : ''"
      hide-footer
      centered
    >
      <div class="d-flex w-100">
        <div class="mr-2 -flex-grow-1">
          <div class="-mr-2">
            <label for="physician-id">Tipo Plantão/Serviço</label>
            <v-select
              id="person-type"
              v-model="workplaceEnableForRequest.person_type"
              name="person-type"
              :options="requestTypeOptions"
              label="label"
              item-value="value"
              item-text="label"
              :clearable="false"
            />
          </div>
          <div class="-mr-2 request-enabled-at">
            <label>Informe a data</label>
            <b-form-datepicker
              id="request-enabled-at"
              v-model="workplaceEnableForRequest.request_enabled_at"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="pt-BR"
              label-help=""
              label-no-date-selected="Selecione uma data"
            />
          </div>
        </div>
        <div class="flex-grow-1">
          <label for="physician-id">Tipo de Serviços</label>
          <v-select
            id="service-type-can-released"
            v-model="workplaceEnableForRequest.service_type_can_released"
            name="service-type-can-released"
            :options="serviceTypeCanReleasedOptions"
            label="label"
            item-value="value"
            item-text="label"
            :clearable="false"
          />
        </div>
      </div>
      <div class="text-right">
        <b-button
          variant="primary"
          class="mt-2"
          :disabled="loading"
          @click="enableForRequest"
        >
          <span class="d-none d-sm-inline">Liberar</span>
        </b-button>
      </div>
    </b-modal>
    <b-modal
      id="modal-workplace-lock"
      ref="workplaceLockModal"
      :title="workplaceEnableForRequest && workplaceEnableForRequest.workplace_name ? `Editar/Bloquear Plantão (${workplaceEnableForRequest.workplace_name})` : ''"
      hide-footer
      size="sm"
      centered
      no-close-on-backdrop
    >
      <div class="d-flex w-100">
        <div class="mr-2 flex-grow-1">
          <div class="mr-2">
            <label for="physician-id">Tipo Plantão/Serviço</label>
            <v-select
              id="person-type"
              v-model="workplaceEnableForRequest.person_type"
              name="person-type"
              :options="requestTypeOptions"
              label="label"
              item-value="value"
              item-text="label"
              :clearable="false"
            />
          </div>
          <div class="mr-2">
            <label for="physician-id">Tipo de Serviços</label>
            <v-select
              id="service-type-can-released"
              v-model="workplaceEnableForRequest.service_type_can_released"
              name="service-type-can-released"
              :options="serviceTypeCanReleasedOptions"
              label="label"
              item-value="value"
              item-text="label"
              :clearable="false"
            />
          </div>
        </div>
        <div class="flex-grow-1">
          <label>Informe a data</label>
          <b-form-datepicker
            id="request-enabled-at"
            v-model="workplaceEnableForRequest.request_enabled_at"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="pt-BR"
            label-help=""
            label-no-date-selected="Selecione uma data"
            reset-button
            label-reset-button="Limpar"
          />
        </div>
      </div>
      <div class="text-right">
        <b-button
          variant="primary"
          class="mt-2"
          :disabled="loading"
          @click="lockEnableForRequest"
        >
          <span class="d-none d-sm-inline">Salvar</span>
        </b-button>
      </div>
    </b-modal>
    <b-modal
      id="modal-workplace"
      ref="workplaceLaunchModal"
      :title="workplaceLaunchMode.workplace_name ? `Editar Tipo de Lançamento de Plantão (${workplaceLaunchMode.workplace_name})` : ''"
      hide-footer
      centered
    >
      <div class="pb-2">
        <p class="mb-0">
          Tipo Lançamento
        </p>
        <div class="-d-flex">
          <div class="w-100">
            <v-select
              id="launch-mode"
              v-model="workplaceLaunchMode.physician_duty_launch_mode"
              name="launch-mode"
              :options="launchModeOptions"
              label="label"
              item-value="value"
              item-text="label"
              :clearable="false"
            />
          </div>
          <div class="w-100 text-right">
            <b-button
              variant="primary"
              class="mt-3 -w-100"
              :disabled="loading"
              @click="editLaunchMode"
            >
              <span class="d-none d-sm-inline">Confirmar</span>
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modal-workplace"
      ref="workplacePayrollValueModal"
      :title="workplacePayrollValueMode.workplace_name ? `Editar Valor Folha (${workplacePayrollValueMode.workplace_name})` : ''"
      hide-footer
      centered
    >
      <div class="pb-2">
        <p class="mb-0">
          Valor Folha
        </p>
        <div class="-d-flex">
          <div class="w-100">
            <b-form
              @submit.prevent
            >
              <validation-provider
                #default="{ errors }"
                name="Limite"
                vid="payroll_value"
                rules="required"
              >
                <b-form-group
                  label=""
                  label-for="payroll_value"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <cleave
                    id="payroll_value"
                    v-model="workplacePayrollValueMode.payroll_value"
                    name="payroll_value"
                    class="form-control"
                    :options="options.number"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form>
          </div>
          <div class="w-100 text-right">
            <b-button
              variant="primary"
              class="mt-3 -w-100"
              :disabled="loading"
              @click="editPayrollValue"
            >
              <span class="d-none d-sm-inline">Confirmar</span>
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modal-workplace-contract-effective-date"
      ref="contractEffectiveDateModal"
      :title="workplaceContractEffectiveDate.workplace_name ? `Alterar vigência do contrato (${workplaceContractEffectiveDate.workplace_name})` : ''"
      hide-footer
      centered
    >
      <div>
        <p>Informe a data</p>
        <div class="d-flex">
          <b-form-datepicker
            id="request-enabled-at"
            v-model="workplaceContractEffectiveDate.contract_effective_date"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="pt-BR"
            label-help=""
            label-no-date-selected="Selecione uma data"
          />
          <b-button
            variant="primary"
            class="ml-1 w-100"
            :disabled="loading"
            @click="editContractEffectiveDate"
          >
            <span class="d-none d-sm-inline">Alterar</span>
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modal-workplace-can-advance"
      ref="canAdvanceModal"
      :title="workplaceCanAdvance.workplace_name ? `${workplaceCanAdvance.can_advance ? 'Habilitar' : 'Desabilitar'} (${workplaceCanAdvance.workplace_name})` : ''"
      hide-footer
      size="xs"
      centered
    >
      <div>
        <p>
          Você tem certeza?
        </p>
        <div class="d-flex justify-content-end">
          <b-button
            variant="primary"
            class="ml-1 w-50"
            :disabled="loading"
            @click="editCanAdvance"
          >
            <span class="d-none d-sm-inline">
              {{ workplaceCanAdvance.can_advance ? 'Habilitar' : 'Desabilitar' }}
            </span>
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard, BDropdown, BDropdownItem, BForm, BFormDatepicker, BFormGroup, BModal,
  BTable,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import institutionStoreModule from '@/views/institution/institutionStoreModule'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import {
  cnpj, email, length, required,
} from '@core/utils/validations/validations'
import Cleave from 'vue-cleave-component'
import { formatDateTimeDDMMYYYY } from '@core/utils/utils'
import AppHelp from '@core/components/app-help/AppHelp.vue'
import useWorkplaceList from './useWorkplaceList'

export default {
  components: {
    AppHelp,
    BBadge,
    BForm,
    ValidationProvider,
    BFormGroup,
    vSelect,
    Cleave,
    BFormDatepicker,
    BButton,
    BModal,
    BDropdown,
    BDropdownItem,
    BCard,
    BTable,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  props: {
    institutionId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      cnpj,
      length,
      daysOptions: [
        ...new Array(30).fill().map((_, i) => ({ label: `${i + 1}`, value: i + 1 })),
      ],
      options: {
        customDelimiter: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          numericOnly: true,
        },
        phone: {
          delimiters: ['(', ')', ' ', '-'],
          blocks: [0, 2, 0, 4, 5],
          numericOnly: true,
          delimiterLazyShow: true,
        },
        phoneWith9: {
          delimiters: ['(', ')', ' ', '-'],
          blocks: [0, 2, 0, 5, 4],
          numericOnly: true,
          delimiterLazyShow: true,
        },
        percent: {
          numericOnly: true,
          numeral: true,
          numeralThousandsGroupStyle: 'none',
          numeralDecimalMark: ',',
          numeralDecimalScale: 4,
          numeralPositiveOnly: true,
          rawValueTrimPrefix: true,
        },
        number: {
          numeralPositiveOnly: true,
          numericOnly: true,
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.',
          numeralDecimalScale: 2,
          prefix: 'R$ ',
          rawValueTrimPrefix: true,
        },
        postalCode: {
          delimiters: ['-'],
          blocks: [5, 3],
          numericOnly: true,
        },
      },
    }
  },
  setup({ institutionId }) {
    const INSTITUTION_APP_STORE_MODULE_NAME = 'app-institution'

    // Register module
    if (!store.hasModule(INSTITUTION_APP_STORE_MODULE_NAME)) store.registerModule(INSTITUTION_APP_STORE_MODULE_NAME, institutionStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INSTITUTION_APP_STORE_MODULE_NAME)) store.unregisterModule(INSTITUTION_APP_STORE_MODULE_NAME)
    })
    const loading = ref(false)
    const workplaceModal = ref(null)
    const contractEffectiveDateModal = ref(null)
    const canAdvanceModal = ref(null)
    const workplaceLockModal = ref(null)
    const workplaceLaunchModal = ref(null)
    const workplacePayrollValueModal = ref(null)
    const workplaceEnableForRequest = ref({
      workplace_id: null,
      request_enabled_at: '',
      service_type_can_released: '',
      person_type: null,
    })
    const workplaceContractEffectiveDate = ref({
      workplace_id: null,
      contract_effective_date: '',
    })
    const workplaceCanAdvance = ref({
      workplace_name: null,
      workplace_id: null,
      can_advance: true,
    })
    const workplaceLaunchMode = ref({
      workplace_name: null,
      workplace_id: null,
      physician_duty_launch_mode: null,
    })
    const workplacePayrollValueMode = ref({
      workplace_name: null,
      workplace_id: null,
      payroll_value: 0,
    })
    const launchModeOptions = [
      { label: 'IMEDIATO', value: 'imediato' },
      { label: 'Integração', value: 'integration' },
      { label: 'IMEDIATO/Integração', value: 'imediato_integration' },
    ]
    const requestTypeOptions = [
      { label: 'PJ', value: 'PJ' },
      { label: 'PF', value: 'PF' },
    ]
    const serviceTypeCanReleasedOptions = [
      { label: 'Plantão', value: 'physician_duty' },
      { label: 'Serviços', value: 'service_record' },
      { label: 'Todos', value: 'all' },
    ]

    const {
      fetchWorkplaces,
      refWorkplaceListTable,
      refetchData,
      institutionFilter,
      tableColumns,
      getLaunchMode,
    } = useWorkplaceList()

    institutionFilter.value = institutionId

    async function showEnableForRequest(workplace) {
      workplaceEnableForRequest.value.workplace_id = workplace.id
      workplaceEnableForRequest.value.workplace_name = workplace.name
      workplaceEnableForRequest.value.request_enabled_at = workplace.request_enabled_at
      const type = requestTypeOptions.find(t => t.value === workplace.person_type)
      workplaceEnableForRequest.value.person_type = type
      const option = serviceTypeCanReleasedOptions.find(t => t.value === workplace.service_type_can_released)
      workplaceEnableForRequest.value.service_type_can_released = option
      workplaceModal.value.show()
    }
    async function showLockEnableForRequest(workplace) {
      workplaceEnableForRequest.value.workplace_id = workplace.id
      workplaceEnableForRequest.value.workplace_name = workplace.name
      workplaceEnableForRequest.value.request_enabled_at = workplace.request_enabled_at
      const type = requestTypeOptions.find(t => t.value === workplace.person_type)
      workplaceEnableForRequest.value.person_type = type
      const option = serviceTypeCanReleasedOptions.find(t => t.value === workplace.service_type_can_released)
      workplaceEnableForRequest.value.service_type_can_released = option
      workplaceLockModal.value.show()
    }
    async function showContractEffectiveDate(workplace) {
      workplaceContractEffectiveDate.value.workplace_id = workplace.id
      workplaceContractEffectiveDate.value.workplace_name = workplace.name
      workplaceContractEffectiveDate.value.contract_effective_date = null
      contractEffectiveDateModal.value.show()
    }

    async function showCanAdvance(workplace, canAdvance) {
      workplaceCanAdvance.value.workplace_id = workplace.id
      workplaceCanAdvance.value.workplace_name = workplace.name
      workplaceCanAdvance.value.can_advance = canAdvance
      canAdvanceModal.value.show()
    }

    async function showLaunchMode(workplace) {
      workplaceLaunchMode.value.workplace_name = workplace.name
      workplaceLaunchMode.value.workplace_id = workplace.id
      const option = launchModeOptions.find(({ value }) => value === workplace.physician_duty_launch_mode)
      workplaceLaunchMode.value.physician_duty_launch_mode = option
      workplaceLaunchModal.value.show()
    }
    async function showPayrollValueEdit(workplace) {
      workplacePayrollValueMode.value.workplace_id = workplace.id
      workplacePayrollValueMode.value.workplace_name = workplace.name
      workplacePayrollValueMode.value.payroll_value = workplace.payroll_value
      workplacePayrollValueModal.value.show()
    }

    function enableForRequest() {
      loading.value = true
      const payload = {
        ...workplaceEnableForRequest.value,
        person_type: workplaceEnableForRequest.value.person_type.value,
        service_type_can_released: workplaceEnableForRequest.value.service_type_can_released.value,
      }
      store.dispatch('app-institution/enableForRequest', payload)
        .then(() => {
          refetchData()
          loading.value = false
          workplaceModal.value.hide()
        }).catch(() => {
          loading.value = false
        })
    }

    function editContractEffectiveDate() {
      loading.value = true
      const payload = workplaceContractEffectiveDate.value
      store.dispatch('app-institution/editContractEffectiveDate', payload)
        .then(() => {
          refetchData()
          loading.value = false
          contractEffectiveDateModal.value.hide()
        }).catch(() => {
          loading.value = false
        })
    }

    function editCanAdvance() {
      loading.value = true
      const payload = workplaceCanAdvance.value
      store.dispatch('app-institution/editCanAdvance', payload)
        .then(() => {
          refetchData()
          loading.value = false
          canAdvanceModal.value.hide()
        }).catch(() => {
          loading.value = false
        })
    }

    function editLaunchMode() {
      loading.value = true
      const payload = {
        workplace_id: workplaceLaunchMode.value.workplace_id,
        physician_duty_launch_mode: workplaceLaunchMode.value.physician_duty_launch_mode.value,
      }
      store.dispatch('app-institution/updateLaunchMode', payload)
        .then(() => {
          refetchData()
          loading.value = false
          workplaceLaunchModal.value.hide()
        }).catch(() => {
          loading.value = false
        })
    }

    function editPayrollValue() {
      loading.value = true
      const payload = {
        workplace_id: workplacePayrollValueMode.value.workplace_id,
        payroll_value: workplacePayrollValueMode.value.payroll_value,
      }
      store.dispatch('app-institution/updatePayrollValue', payload)
        .then(() => {
          refetchData()
          loading.value = false
          workplacePayrollValueModal.value.hide()
        }).catch(() => {
          loading.value = false
        })
    }

    async function lockEnableForRequest() {
      loading.value = true
      const payload = {
        ...workplaceEnableForRequest.value,
        person_type: workplaceEnableForRequest.value.person_type.value,
        service_type_can_released: workplaceEnableForRequest.value.service_type_can_released.value,
      }
      store.dispatch('app-institution/enableForRequest', payload)
        .then(() => {
          refetchData()
          loading.value = false
          workplaceLockModal.value.hide()
        }).catch(() => {
          loading.value = false
        })
    }

    return {
      requestTypeOptions,
      serviceTypeCanReleasedOptions,
      loading,
      workplaceLaunchModal,
      launchModeOptions,
      showLaunchMode,
      showPayrollValueEdit,
      editPayrollValue,
      workplacePayrollValueMode,
      workplacePayrollValueModal,
      lockEnableForRequest,
      showEnableForRequest,
      getLaunchMode,
      showLockEnableForRequest,
      workplaceEnableForRequest,
      showContractEffectiveDate,
      workplaceContractEffectiveDate,
      workplaceCanAdvance,
      editContractEffectiveDate,
      workplaceLockModal,
      editCanAdvance,
      contractEffectiveDateModal,
      showCanAdvance,
      canAdvanceModal,
      workplaceLaunchMode,
      editLaunchMode,
      enableForRequest,
      workplaceModal,
      tableColumns,
      fetchWorkplaces,
      refWorkplaceListTable,
      refetchData,
      institutionFilter,
      formatDateTimeDDMMYYYY,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.physician-class {
  width: 15px;
  height: 15px;
  border-spacing: 0!important;
  margin-top: 2px;
}

.workplace-table {
  min-height: 250px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.request-enabled-at {
  width: 230px;
}
</style>
