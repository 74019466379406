<template>
  <component :is="institutionData === undefined ? 'div' : 'b-card'">

    <b-tabs
      v-if="institutionData"
      pills
    >
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="HomeIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Instituição</span>
        </template>
        <institution-edit-tab-general
          :institution-data="institutionData"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab disabled>
        <template #title>
          <feather-icon
            icon="UnlockIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Acesso</span>
        </template>
      </b-tab>

      <!-- Tab: Information -->
      <b-tab disabled>
        <template #title>
          <feather-icon
            icon="UsersIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Médicos</span>
        </template>
      </b-tab>

    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import institutionStoreModule from '../institutionStoreModule'
import InstitutionEditTabGeneral from '../institution-edit/InstitutionEditTabGeneral.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    // Form Validation
    ValidationProvider,
    ValidationObserver,

    InstitutionEditTabGeneral,
  },
  directives: {
    Ripple,
  },
  setup() {
    const INSTITUTION_APP_STORE_MODULE_NAME = 'app-institution'

    // Register module
    if (!store.hasModule(INSTITUTION_APP_STORE_MODULE_NAME)) store.registerModule(INSTITUTION_APP_STORE_MODULE_NAME, institutionStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INSTITUTION_APP_STORE_MODULE_NAME)) store.unregisterModule(INSTITUTION_APP_STORE_MODULE_NAME)
    })

    const blankInstitutionData = {
      name: '',
      fancy_name: '',
      email: '',
      cnpj: '',
      phone: '',
      contact: '',
      limit: 0,
      payday: 0,
      cashback: '',
      financial_restriction: false,
      financial_restriction_reason: null,
      address: {
        state_id: '',
        city_id: '',
        number: '',
        postal_code: '',
        neighborhood: '',
        complement: '',
        street: '',
      },
    }
    const institutionData = ref(JSON.parse(JSON.stringify(blankInstitutionData)))

    return {
      institutionData,
    }
  },
}
</script>

<style>

</style>
