<template>
  <div>
    <audit-header :audit="audit" />
    <hr>
    <h4 class="text-capitalize mt-2">
      Geral
    </h4>
    <div>
      <h6 class="text-capitalize mb-0">
        Razão Social
      </h6>
      <div>{{ value.name }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        Nome Fantasia
      </h6>
      <div>{{ value.fancy_name }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        CNPJ
      </h6>
      <div>{{ formatCnpj(value.cnpj) }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        Contato
      </h6>
      <div>{{ value.contact }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        Fone
      </h6>
      <div>{{ formatPhone(value.phone) }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        Email
      </h6>
      <div>{{ value.email }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        Status
      </h6>
      <div>{{ value.inactivated_at ? 'Inativo' : 'Ativo' }}</div>
    </div>

    <h4 class="text-capitalize mt-2">
      Financeiro
    </h4>

    <div>
      <h6 class="text-capitalize mb-0">
        Limite
      </h6>
      <div>{{ formatCurrency(value.limit) }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        Dias p/ Pagamento
      </h6>
      <div>{{ value.payday }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        Cashback
      </h6>
      <div>{{ value.profit_percentage || value.cashback }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        Forma de pagamento
      </h6>
      <div>{{ value.payment_method }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        Com restrição?
      </h6>
      <div>{{ value.financial_restriction ? 'Sim' : 'Não' }}</div>
      <div>{{ value.financial_restriction_reason }}</div>
    </div>

    <h4 class="text-capitalize mt-2">
      Endereço
    </h4>

    <div>
      <h6 class="text-capitalize mb-0">
        CEP
      </h6>
      <div>{{ formatCep(value.address.postal_code) }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        Cidade
      </h6>
      <div>{{ value.address.city.name }} - {{ value.address.city.state.abbreviation }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        Bairro
      </h6>
      <div>{{ value.address.neighborhood }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        Rua
      </h6>
      <div>{{ value.address.street }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        N°
      </h6>
      <div>{{ value.address.number }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        Complemento
      </h6>
      <div>{{ value.address.complement || '-' }}</div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { format } from 'date-fns'
import {
  formatCep, formatCnpj, formatCurrency, formatPhone,
} from '@core/utils/utils'
import AuditHeader from './Header.vue'

export default {
  components: {
    AuditHeader,
  },
  props: {
    audit: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const value = ref(props.audit.current_values)
    const user = ref(props.audit.user)
    const createdAt = ref(props.audit.created_at)
    return {
      formatCep,
      formatCnpj,
      formatPhone,
      formatCurrency,
      value,
      user,
      createdAt,
      formatDate: format,
    }
  },
}
</script>

<style lang="scss" scoped>
hr {
  margin-top: 4px;
  margin-bottom: 10px;
}
h6 {
  margin-top: 3px;
}
</style>
