<template>
  <e-charts
    ref="line"
    autoresize
    :option="option"
    theme="theme-color"
    auto-resize
    :update-options="{notMerge:true}"
  />
</template>

<script>
import ECharts from 'vue-echarts'
import { ref, toRef, watch } from '@vue/composition-api'
import { formatCnpj, formatCurrency, truncateString } from '@core/utils/utils'
import { registerTheme, use } from 'echarts/core'
import {
  BarChart, LineChart, LinesChart, PieChart,
} from 'echarts/charts'
import {
  DatasetComponent,
  DataZoomComponent,
  GridComponent,
  LegendComponent,
  LegendScrollComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent,
} from 'echarts/components'
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers'
import theme from '../theme.json'

use([
  BarChart,
  LineChart,
  PieChart,
  LinesChart,
  LegendScrollComponent,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  CanvasRenderer,
  SVGRenderer,
  ToolboxComponent,
  DataZoomComponent,
])
registerTheme('theme-color', theme)

export default {
  components: {
    ECharts,
  },
  props: {
    mobile: {
      type: Object,
      default: () => ({ mobile: false }),
    },
    chartData: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const chartData = toRef(props, 'chartData')
    // const mobile = toRef(props, 'mobile')

    const dataset = {
      dimensions: ['name', 'total_advanced', 'short', 'institution', 'cnpj'],
      source: [],
    }
    const option = ref({
      grid: {
        left: 190,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: ([data]) => `<strong>${data.data[0]}</strong><br/>${data.data[3]}<br/>${formatCnpj(data.data[4])}<br/>${formatCurrency(data.data[1])}`,
      },
      dataset: [dataset],
      yAxis: {
        inverse: true,
        type: 'category',
        axisLabel: {
          interval: 0,
          rotate: 30,
        },
      },
      xAxis: {
        axisLabel: {
          formatter: formatCurrency,
          rotate: 30,
        },
      },
      series: [
        {
          type: 'bar',
          colorBy: 'data',
          encode: { y: 'short', x: 'total_advanced' },
        },
      ],
    })

    watch(chartData, data => {
      if (!data || !data.total_year_per_workplace) return
      const years = Object.keys(data.total_year_per_workplace)
      const workplaces = years.length > 0 ? Object.values(data.total_year_per_workplace[years[0]]) : []

      dataset.source.splice(0, dataset.source.length)
      const labels = workplaces.map(i => i.name)
      const institutions = workplaces.map(i => i.institution_name)
      const cnpjs = workplaces.map(i => i.cnpj)
      workplaces.forEach((i, index) => {
        const label = labels[index]
        const institution = institutions[index]
        const cnpj = cnpjs[index]
        const value = i.total_advanced_year / 100
        const shortLabel = truncateString(labels[index], 15)
        dataset.source.push([label, value, shortLabel, institution, cnpj])
      })
    })

    return {
      option,
    }
  },
}
</script>
