<template>
  <component :is="physicianData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="physicianData === undefined"
    >
      <h4 class="alert-heading">
        Erro ao buscar user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'physician-list'}"
        >
          User List
        </b-link>
        for other physicians.
      </div>
    </b-alert>

    <b-tabs
      v-if="physicianData"
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Acesso</span>
        </template>
        <physician-edit-tab-account
          :physician-data="physicianData"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="CalendarIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Pessoal</span>
        </template>
        <physician-edit-tab-information
          :physician-data="physicianData"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="CalendarIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Plantões</span>
        </template>
        <physician-edit-tab-information
          :physician-data="physicianData"
          class="mt-2 pt-75"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import physicianStoreModule from '../physicianStoreModule'
import PhysicianEditTabAccount from './PhysicianEditTabAccount.vue'
import PhysicianEditTabInformation from './PhysicianEditTabInformation.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    PhysicianEditTabAccount,
    PhysicianEditTabInformation,
  },
  setup() {
    const physicianData = ref(null)

    const PHYSICIAN_APP_STORE_MODULE_NAME = 'app-physician'

    // Register module
    if (!store.hasModule(PHYSICIAN_APP_STORE_MODULE_NAME)) store.registerModule(PHYSICIAN_APP_STORE_MODULE_NAME, physicianStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PHYSICIAN_APP_STORE_MODULE_NAME)) store.unregisterModule(PHYSICIAN_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-physician/fetchPhysician', { id: router.currentRoute.params.id })
      .then(response => {
        const physician = response.data.data
        physicianData.value = {
          id: physician.id,
          name: physician.profile.user.name,
          surname: physician.profile.user.name,
          email: physician.profile.user.email,
          cpf: physician.cpf,
          crm: physician.crm,
          phone: physician.phone,
          monthly_income: physician.monthly_incomes[0].income,
          status: 'active',
          address: {
            id: physician.address[0].id,
            street: physician.address[0].street,
            number: physician.address[0].number,
            postalCode: physician.address[0].postal_code, // @FIXME: postal_code/postalCode
            neighborhood: physician.address[0].neighborhood,
            complement: physician.address[0].complement,
            cityId: physician.address[0].city.id,
            stateId: physician.address[0].city.state_id,
          },
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          physicianData.value = undefined
        }
      })

    return {
      physicianData,
    }
  },
}
</script>

<style>

</style>
