import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPartnerCategories() {
      return new Promise((resolve, reject) => {
        axios
          .get('/partnership-category')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPartners(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/partnership', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPartner(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/partnership/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addPartner(ctx, { image, ...data }) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        // formData.append('image', image, image.name)
        formData.append('title', data.title)
        formData.append('url', data.url)
        formData.append('category', data.category)
        formData.append('active', data.active ? 1 : 0)
        formData.append('description', data.description)
        axios
          .post('/partnership', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePartner(ctx, { image, id, ...data }) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        // if (image) formData.append('image', image, image.name)
        formData.append('id', id)
        formData.append('title', data.title)
        formData.append('url', data.url)
        formData.append('category', data.category)
        formData.append('description', data.description)
        formData.append('active', data.active || false)
        axios
          .post('/partnership', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
