<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ faqId ? 'Editar FAQ' : 'Cadastrar FAQ' }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- form -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- question -->
          <b-form-group
            label-for="question"
            label="Dúvida"
          >
            <validation-provider
              #default="validationContext"
              name="Dúvida"
              vid="question"
              rules="required"
            >
              <b-form-input
                id="question"
                v-model="faqData.question"
                placeholder="Pergunta..."
                :state="validationContext.touched ? getValidationState(validationContext) : null"
              />
              <small class="text-danger">{{ validationContext.errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!--/ question -->

          <!-- answer -->
          <b-form-group
            label="Resposta"
            label-for="answer"
          >
            <validation-provider
              #default="validationContext"
              name="Resposta"
              vid="answer"
              rules="required"
            >
              <b-form-textarea
                id="answer"
                v-model="faqData.answer"
                rows="4"
                placeholder="Resposta aqui..."
                :state="validationContext.touched ? getValidationState(validationContext) : null"
              />
              <small class="text-danger">{{ validationContext.errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!--/ answer -->

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-primary"
              @click="hide"
            >
              Voltar
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              class="mr-2"
              :disabled="loading"
              type="submit"
            >
              Salvar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSidebar, BButton, BForm, BFormInput, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BSidebar,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    faqId: {
      type: String,
      default: undefined,
    },
  },
  setup(props, { emit }) {
    const mode = ref('create')
    const loading = ref(false)

    const blankFaqData = {
      question: '',
      answer: '',
    }

    const faqData = ref(JSON.parse(JSON.stringify(blankFaqData)))
    const resetFaqData = () => {
      faqData.value = JSON.parse(JSON.stringify(blankFaqData))
    }

    function getFaq(id) {
      loading.value = true
      store.dispatch('app-faq/fetchFaq', { id })
        .then(response => {
          faqData.value = response.data.data
          loading.value = false
        })
        .catch(() => {
          loading.value = false
        })
    }

    function onSubmit() {
      const faq = { ...faqData.value }
      store.dispatch(faq.id ? 'app-faq/updateFaq' : 'app-faq/addFaq', faq)
        .then(() => {
          mode.value = 'create'
          this.$swal({
            title: 'Salvo com sucesso!',
            timer: 5000,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
        .catch(error => {
          if (error.response.status === 422 && error.response.data.data) {
            Object.keys(error.response.data.data).forEach(field => {
              this.$refs.refFormObserver.setErrors({
                [field]: error.response.data.data[field],
              })
            })
          }
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetFaqData)

    watch(
      () => props.isAddNewUserSidebarActive, show => {
        resetForm()
        resetFaqData()
        if (show && props.faqId) {
          getFaq(props.faqId)
        }
      },
    )

    return {
      required,
      faqData,
      mode,
      loading,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
