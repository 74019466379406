<template>
  <div>
    <b-card-title
      title-tag="h2"
      class="font-weight-bold mb-1"
    >
      Esqueceu sua senha?
    </b-card-title>
    <b-card-text class="mb-2">
      Digite seu e-mail e enviaremos instruções para redefinir sua senha
    </b-card-text>

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form
        class="auth-forgot-password-form mt-2"
        @submit.prevent="forgotPassword"
      >
        <b-form-group
          label="Email"
          label-for="forgot-password-email"
        >
          <validation-provider
            #default="{ errors }"
            name="Email"
            rules="required|email"
          >
            <b-form-input
              id="forgot-password-email"
              v-model="user.email"
              :state="errors.length > 0 ? false:null"
              name="forgot-password-email"
              placeholder="seu-email@mail.com"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-button
          v-if="!submitting"
          type="submit"
          variant="primary"
          block
        >
          Recuperar
        </b-button>
        <b-button
          v-else
          disabled
          type="submit"
          variant="primary"
          block
        >
          Recuperar
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCardTitle, BCardText, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import {
  required, email, digits, password, confirmed,
} from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useSanctum from '@/auth/sanctum/useSanctum'

export default {
  components: {
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      submitting: false,
      // validation
      required,
      email,
      digits,
      password,
      confirmed,
    }
  },
  methods: {
    toast(props) {
      this.$toast({
        component: ToastificationContent,
        props: {
          icon: 'AlertCircleIcon',
          variant: 'danger',
          ...props,
        },
      })
    },
    forgotPassword() {
      this.$refs.simpleRules.validate().then(success => {
        if (!success) return null
        return this.sendForgotPassword()
          .then(() => {
            this.$emit('step', 1)
          })
      })
    },
    async sendForgotPassword() {
      this.submitting = true
      const token = await this.$recaptcha('login')

      return useSanctum.forgotPassword({
        email: this.user.email,
        recaptcha: token,
      })
        .then(() => {
          this.submitting = false
          this.user.code = ''
        })
        .catch(error => {
          this.submitting = false

          const errors = (error.response && error.response.status === 422 && error.response.data.errors) || {}
          if (Object.keys(errors).some(key => key === 'recaptcha')) {
            this.$refs.simpleRules.setErrors({
              email: 'Dispositivo não passou no teste de segurança',
            })
          } else {
            const title = error.response.status === 429 // throttled
              ? 'Tente novamente em alguns minutos' : 'Email inválido'
            this.toast({ title })
          }
          throw error
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
