<template>
  <div>
    <audit-header :audit="audit" />
    <hr>
    <div>
      <h6 class="text-capitalize mb-0">
        Número da Conta
      </h6>
      <div>
        {{ value.account_number || '-' }}
      </div>
    </div>
    <div class="mt-1">
      <h6 class="text-capitalize mb-0">
        Código da Conta
      </h6>
      <div>
        {{ value.account_code || '-' }}
      </div>
    </div>
    <div class="mt-1">
      <h6 class="text-capitalize mb-0">
        Agência
      </h6>
      <div>
        {{ value.branch_number || '-' }}
      </div>
    </div>
    <div class="mt-1">
      <h6 class="text-capitalize mb-0">
        Tipo de Conta
      </h6>
      <div>
        {{ accountTypeMap[value.account_type] || value.account_type || '-' }}
      </div>
    </div>
    <div class="mt-1">
      <h6 class="text-capitalize mb-0">
        Instituição Financeira
      </h6>
      <div>
        {{ value.financial_institution.name || '-' }} ({{ value.financial_institution.bank_code || '-' }})
      </div>
    </div>
    <div class="mt-1">
      <h6 class="text-capitalize mb-0">
        Usuário
      </h6>
      <div>
        {{ value.owner.name || '-' }} - {{ value.owner.email || '-' }}
      </div>
    </div>
    <div class="mt-1">
      <h6 class="text-capitalize mb-0">
        Favorita
      </h6>
      <div>
        {{ value.favorite ? 'Sim' : 'Não' }}
      </div>
    </div>
    <div class="mt-1">
      <h6 class="text-capitalize mb-0">
        Ativa
      </h6>
      <div>
        {{ value.active ? 'Sim' : 'Não' }}
      </div>
    </div>
    <div class="mt-1">
      <h6 class="text-capitalize mb-0">
        Criado em
      </h6>
      <div>
        {{ formatDateTime(new Date(value.created_at)) }}
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateTime } from '@core/utils/utils'
import AuditHeader from './Header.vue'

export default {
  components: {
    AuditHeader,
  },
  props: {
    audit: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const accountTypeMap = {
      checking_account: 'Conta Corrente',
      savings_account: 'Conta Poupança',
    }
    const value = props.audit.current_values

    return {
      value,
      accountTypeMap,
      formatDateTime,
    }
  },
}
</script>

<style lang="scss" scoped>
hr {
  margin-top: 4px;
  margin-bottom: 10px;
}
</style>
