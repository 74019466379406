<template>
  <div>
    <audit-header :audit="audit" />
    <hr>
    <h4 class="text-capitalize mt-2">
      Pessoal
    </h4>
    <div>
      <h6 class="text-capitalize mb-0">
        Tipo
      </h6>
      <div>{{ resolveType(value.type) }}</div>
    </div>
    <div v-if="value.access_type && value.access_type[0]">
      <h6 class="text-capitalize mb-0">
        Acesso
      </h6>
      <div>{{ value.access_type[0].name }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        Nome completo
      </h6>
      <div>
        {{ value.name }}
      </div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        E-mail
      </h6>
      <div>{{ value.email }}</div>
    </div>
    <div>
      <h6 class="text-capitalize mb-0">
        CPF
      </h6>
      <div>{{ formatCnpj(value.cpf) }}</div>
    </div>
    <div v-if="institutions.length">
      <h6 class="text-capitalize mb-0">
        Instituições
      </h6>
      <div
        v-for="institution in institutions"
        :key="institution.id"
        class="ml-1"
      >
        <div>
          <strong> {{ institution.name }}</strong> ({{ formatCnpj(institution.cnpj) }})
        </div>
        <div class="ml-1">
          <div>Acesso: {{ institution.access_type.name }}</div>
          <div>Status: {{ institution.inactivated_at ? 'Inativo' : 'Ativo' }}</div>
          <div>Ver cashback: {{ institution.show_cashback ? 'Sim' : 'Não' }}</div>
          <div v-if="institution.workplaces.length">
            <strong>Unidades</strong>
            <ul>
              <li
                v-for="workplace in institution.workplaces"
                :key="workplace.id"
              >
                {{ workplace.name }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { format } from 'date-fns'
import {
  formatCep, formatCnpj, formatCurrency, formatPhone, formatDateTimeDDMMYYYY,
} from '@core/utils/utils'
import AuditHeader from './Header.vue'

export default {
  components: {
    AuditHeader,
  },
  props: {
    audit: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const value = ref(props.audit.current_values)
    const user = ref(props.audit.user)
    const address = ref(null)
    const createdAt = ref(props.audit.created_at)
    const accounts = ref([])
    if (Array.isArray(value.value.accounts)) {
      accounts.value = value.value.accounts
    }
    if (Array.isArray(value.value.address) && value.value.address[0]) {
      // eslint-disable-next-line prefer-destructuring
      address.value = value.value.address[0]
    }

    const getWorkplaces = institution => {
      const { workplaces } = value.value
      return workplaces?.filter(workplace => workplace.pivot.institution_id === institution) || []
    }

    const getAccessType = institution => {
      if (!value.value) return null
      const accesses = value.value.access_type
      // eslint-disable-next-line no-restricted-syntax
      for (const access of accesses) {
        if (access.pivot.institution_id === institution) {
          return access
        }
      }
      return null
    }

    const institutions = computed(() => {
      const ins = {}
      if (value.value && value.value.institutions && value.value.institutions.length) {
        // eslint-disable-next-line no-restricted-syntax
        for (const institution of value.value.institutions) {
          const workplaces = getWorkplaces(institution.id)
          ins[institution.id] = {
            id: institution.id,
            name: institution.name,
            cnpj: institution.cnpj,
            show_cashback: institution.pivot.show_cashback,
            inactivated_at: institution.pivot.inactivated_at,
            access_type: getAccessType(institution.id),
            workplaces,
          }
        }
      }
      return Object.values(ins)
    })

    const resolveType = type => {
      if (type === 'physician') {
        return 'Médico'
      } if (type === 'institution') {
        return 'Instituição'
      }
      return 'Administrador'
    }

    return {
      institutions,
      resolveType,
      address,
      accounts,
      formatCep,
      formatCnpj,
      formatPhone,
      formatCurrency,
      value,
      user,
      createdAt,
      formatDateTimeDDMMYYYY,
      formatDate: format,
    }
  },
}
</script>

<style lang="scss" scoped>
hr {
  margin-top: 4px;
  margin-bottom: 10px;
}
h6 {
  margin-top: 3px;
}
</style>
