import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import { ref, watch } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useFailedPhysicianDutiesErrors() {
  const perPage = ref(50)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100, 200]
  const searchQuery = ref('')
  const isSortDirDesc = ref(true)
  const sortBy = ref('id')
  const refSyncErrorsTable = ref(null)
  const toast = useToast()

  const tableColumns = [
    { key: 'created_at', label: 'Data' },
    { key: 'action', label: 'URL' },
    { key: 'status_code', label: 'Status Code' },
    { key: 'integration_code', label: 'Integration Code' },
    { key: 'actions', label: 'Ações' },
  ]

  const showImediato = ref(false)

  const refetchData = () => {
    refSyncErrorsTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, showImediato], () => {
    refetchData()
  })

  const fetchSyncErrors = (ctx, callback) => {
    store
      .dispatch('app-failed-physician-duties-errors/failedPhysicianDutiesErrors', {
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDirection: isSortDirDesc.value ? 'desc' : 'asc',
      })
      .then(response => {
        const { data, meta } = response.data
        const syncErrors = data.map(syncError => syncError)
        callback(syncErrors)
        totalItems.value = meta.pagination.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao buscar',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const reSync = ({ contractId }) => {
    store
      .dispatch('app-sync-errors/omieReSync', { contractId })
      .then(() => {
        refetchData()
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro na request OMIE',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchSyncErrors,
    reSync,
    refSyncErrorsTable,
    tableColumns,
    showImediato,
    refetchData,
    perPageOptions,
    perPage,
    currentPage,
    totalItems,
  }
}
