import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchContracts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/contract-template', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchContract(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/contract-template/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addContract(ctx, rateData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/contract-template', rateData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
