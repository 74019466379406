import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { format } from 'date-fns'

export default function usePhysicianList() {
  // Use toast
  const toast = useToast()

  const refPhysicianListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'user', sortable: true, sortKey: 'user_id', label: 'Médico',
    },
    { key: 'email', sortable: true },
    { key: 'cpf', sortable: true },
    { key: 'status' },
    { key: 'actions', label: 'Ações' },
  ]
  const perPage = ref(10)
  const totalPhysicians = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('created_at')
  const isSortDirDesc = ref(true)
  const institutionFilter = ref(null)
  const situationFilter = ref(null)
  const statusFilter = ref(null)
  const classificationFilter = ref(null)
  const statusOptions = [
    { label: 'Ativo', value: '1' },
    { label: 'Inativo', value: '0' },
  ]
  const classificationOptions = [
    { label: 'Não confirmado', value: 'red' },
    { label: 'Ativo e não adiantou nos últimos 30 dias.', value: 'yellow' },
    { label: 'Ativo e adiantou nos últimos 30 dias.', value: 'green' },
  ]

  const dataMeta = computed(() => {
    const localItemsCount = refPhysicianListTable.value ? refPhysicianListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPhysicians.value,
    }
  })

  const refetchData = () => {
    refPhysicianListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, institutionFilter, statusFilter, situationFilter, classificationFilter], () => {
    refetchData()
  })

  const getReportParams = () => ({
    q: searchQuery.value,
    perPage: perPage.value,
    page: currentPage.value,
    sortBy: sortBy.value,
    sortDesc: isSortDirDesc.value ? 'desc' : 'asc',
    sortDirection: isSortDirDesc.value ? 'desc' : 'asc',
    institution: institutionFilter.value,
    situation: situationFilter.value,
    confirmed: statusFilter.value ? statusFilter.value.value : null,
    color: classificationFilter.value ? classificationFilter.value.value : null,
  })

  const fetchPhysicians = (ctx, callback) => {
    store
      .dispatch('app-physician/fetchPhysicians', getReportParams())
      .then(response => {
        const { data: physicians, meta } = response.data
        const physicianList = physicians.map(physician => {
          const p = { ...physician, status: 'active' }
          p.cpf = p.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '***.$2.$3-**')
          return p
        })
        callback(physicianList)
        totalPhysicians.value = meta.pagination.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao buscar',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const downloadReport = () => {
    store.dispatch('app-physician/downloadReport', getReportParams())
      .then(response => {
        const blob = new Blob([response.data], { type: response.headers['content-type'] })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        const now = format(new Date(), 'yyyy-MM-dd hh:mm:ss')
        link.download = `Medicos_${now}.xlsx`
        link.click()
        URL.revokeObjectURL(link.href)
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolvePhysicianRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolvePhysicianRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolvePhysicianStatusVariant = status => {
    if (status) return 'success'
    return 'primary'
  }

  return {
    fetchPhysicians,
    tableColumns,
    perPage,
    currentPage,
    totalPhysicians,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPhysicianListTable,

    resolvePhysicianRoleVariant,
    resolvePhysicianRoleIcon,
    resolvePhysicianStatusVariant,
    refetchData,

    // Extra Filters
    statusOptions,
    classificationOptions,
    situationFilter,
    institutionFilter,
    statusFilter,
    classificationFilter,

    downloadReport,
  }
}
