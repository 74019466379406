<template>

  <div>

    <rate-list-edit
      :is-add-new-rate-sidebar-active.sync="isAddNewRateSidebarActive"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row align-h="end">

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibir</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entradas</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                v-if="$can('manage', 'Contract')"
                variant="primary"
                @click="isAddNewRateSidebarActive = true"
              >
                <span class="text-nowrap">
                  Editar Taxas
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refContractListTable"
        class="position-relative"
        :items="fetchContracts"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum registro correspondente encontrado"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Type -->
        <template #cell(type)="data">
          {{ resolveContractType(data.item.type) }}
        </template>

        <template #cell(description)="data">
          <b-link
            :to="{ name: 'contract-view', params: { id: data.item.id } }"
            class="font-weight-bold d-block text-truncate"
            style="max-width: 300px"
          >
            {{ data.item.description }}
          </b-link>
        </template>

        <!-- Column: Active -->
        <template #cell(active)="data">
          <b-badge
            pill
            :variant="`light-${resolveContractStatusVariant(data.item.active)}`"
            class="text-capitalize"
          >
            {{ data.item.active ? 'Em uso' : 'Inativo' }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{ name: 'contract-edit', params: { id: data.item.id } }"
              :disabled="!data.item.active || !$can('manage', 'Contract')"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalContracts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable,
  BBadge, BPagination, BButton, BLink, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useContractsList from './useContractList'
import contractStoreModule from '../contractStoreModule'
import RateListEdit from '../rate-list/RateListEdit.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BPagination,
    BButton,
    BLink,
    BDropdown,
    BDropdownItem,

    RateListEdit,
    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-contract'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, contractStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewRateSidebarActive = ref(false)

    const {
      fetchContracts,
      tableColumns,
      perPage,
      currentPage,
      totalContracts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refContractListTable,
      refetchData,

      // UI
      resolveContractRoleVariant,
      resolveContractRoleIcon,
      resolveContractStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      situationFilter,
      statusFilter,
      resolveContractType,
    } = useContractsList()

    return {

      isAddNewRateSidebarActive,

      fetchContracts,
      tableColumns,
      perPage,
      currentPage,
      totalContracts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refContractListTable,
      refetchData,
      resolveContractType,

      // Filter
      avatarText,

      // UI
      resolveContractRoleVariant,
      resolveContractRoleIcon,
      resolveContractStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      situationFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
