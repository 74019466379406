<template>
  <b-sidebar
    id="add-new-rate-sidebar"
    :visible="isAddNewRateSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-rate-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Atualizar Taxas
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <rate-form
            v-for="rate in rates"
            :key="rate.id"
            :rate="rate"
          />
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-primary"
              @click="hide"
            >
              Voltar
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              class="mr-2"
              type="submit"
              @click="onSubmit"
            >
              Salvar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BButton,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { onUnmounted, ref, watch } from '@vue/composition-api'
import {
  required,
  between,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import rateStoreModule from '@/views/contract/rateStoreModule'
import RateForm from './RateForm.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BButton,

    // Form Validation
    ValidationObserver,
    RateForm,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewRateSidebarActive',
    event: 'update:is-add-new-rate-sidebar-active',
  },
  props: {
    isAddNewRateSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      between,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'none',
          numeralDecimalScale: 2,
          numeralPositiveOnly: true,
        },
      },
    }
  },
  setup(props, { emit }) {
    const blankRateData = {
      id: null,
      interest: 0,
      fine: 0,
      service_fee: 0,
      type: 'loan',
    }

    const RATE_APP_STORE_MODULE_NAME = 'app-rate'

    // Register module
    if (!store.hasModule(RATE_APP_STORE_MODULE_NAME)) store.registerModule(RATE_APP_STORE_MODULE_NAME, rateStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RATE_APP_STORE_MODULE_NAME)) store.unregisterModule(RATE_APP_STORE_MODULE_NAME)
    })

    function makeRate(type) {
      const rate = JSON.parse(JSON.stringify(blankRateData))
      rate.type = type
      return rate
    }

    function addMask(value) {
      return `${value}`.replace('.', ',')
    }

    function removeMask(value) {
      return Number.parseFloat(value.replace(',', '.'))
    }

    const rates = ref([makeRate('loan'), makeRate('advance_payment')])

    const resetRateData = () => {
      rates.value = [makeRate('loan'), makeRate('advance_payment')]
    }

    function getRate(type) {
      return store.dispatch('app-rate/fetchRateByType', { type })
        .then(response => {
          const rate = response.data.data
          const { interest, service_fee, fine } = rate
          return {
            ...rate,
            interest: addMask(interest),
            service_fee: addMask(service_fee),
            fine: addMask(fine),
          }
        })
    }

    watch(() => props.isAddNewRateSidebarActive, show => {
      if (show) {
        Promise
          .all([getRate('loan'), getRate('advance_payment')])
          .then(values => { rates.value = values })
      }
    })

    const onSubmit = () => {
      const saveRate = rate => {
        const { interest, service_fee, fine } = rate
        return store.dispatch('app-rate/addRate', {
          ...rate,
          interest: removeMask(interest),
          service_fee: removeMask(service_fee),
          fine: removeMask(fine),
        })
      }
      Promise.all([saveRate(rates.value[0]), saveRate(rates.value[1])])
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-rate-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetRateData)

    return {
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      rates,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-rate-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
