<template>
  <feather-icon
    v-b-popover.hover.left="description"
    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
    icon="HelpCircleIcon"
    :title="title"
  />
</template>

<script>
import { VBPopover } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
    }
  },
}
</script>
