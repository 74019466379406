<template>
  <div>
    <physician-list :institution-id="institutionData.id" />
  </div>
</template>

<script>
import PhysicianList from './physician-list/PhysicianList.vue'

export default {
  components: {
    PhysicianList,
  },
  props: {
    institutionData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
